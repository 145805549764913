/* **************************************************** */
/* Declaracion de variables globales */
/* **************************************************** */

/* **************************************************** */
/* Colores Oficiales SIGMI */
/* **************************************************** */

:root{
   // var(--content-text-light-gray-color)
   --logo-sigmi-color: #E8A046; 
   /* Colores de titulos y textos */
   --primary-dark-blue-text-color: #0943B5;
   --secondary-blue-text-color: #0943B5;
   --content-text-color: #4b4b4b; 
   --content-text-light-gray-color: #bababa;
   --content-text-white-color: #ffffff;
   /* Colores de fondo */
   --background-dark-blue-color: #4672C8;
   --background-blue-color: #0943B5;
   --background-blue-light-color: #e6effd;
   --background-blue-clear-color: #80bdff;
   --background-nav-bar-sigmi-color: #F6F8F9;
   --background-nav-bar-active-color: #f2f2f2;
   --background-gray-color: #bababa;
   --background-white-color: #ffffff;
   --background-smoke-color: #000000; // opacidad 20%
   --background-tooltip-color: #f2f2f2;
   /* Colores de bordes */
   --selected-border-gray-color: #bababa;
   --selected-border-light-gray-color: #cccccc;
   --selected-border-blue-color: #0943B5;
   --selected-border-dark-blue-color: #4672C8;
   --selected-border-blue-light-color: #ced4da;
   --selected-etiqueta-border-blue-light-color: #f2f2f2;
   /* Color de hover y sombras */
   --selected-shadow-border-blue-light-color: #e6effd;
   --selected-hover-color: #e6effd;
   --selected-hover-dark-blue-color: #4672C8;
   /* Alertas y notificaciones */
   --success-alert-color: #158361;
   --error-alert-color: #a80521;
   --error-alert-color2: #d11f3e;
   --warning-alert-color: #FDAA29;
   /* Modulo Notificaciones */
   --notificaciones-estado-identificado-color: #656565;
   --notificaciones-estado-identificado-background-color: #f2f2f2;
   --notificaciones-estandarizado-nivel1-color: #976b23;
   --notificaciones-estandarizado-nivel1-background-color: #ffefca;
   --notificaciones-evaluado-nivel2-color: #635c9a;
   --notificaciones-evaluado-nivel2-background-color: #e0dcff;
   --notificaciones-publicado-nivel3-color: #1e7559;
   --notificaciones-publicado-nivel3-background-color: #c5e4dc;    
}


// No usar estan fuera de la paleta de colores
$more: #f6f8f9; // Background
$more2: #ebebeb; // Background 2
$more3: #2778c4; // Swal2 Cancel
$more4: #00000029; // Box Shadow
$more5: #d11f3e; // Notification Error background
$more6: #333; // Dropdown color
$more7: #cecece; // Input and Select placeholder
$more8: #80bdff; // .p Input text boder color
$more9: #15836133; // State background
$more10: #e8e8e8; // Tabs border
$more11: #ced4da; // border form items
$more11: #4B4B4B; // Text form items 
// #Search gray red

/* **************************************************** */
/* Declaracion de fuentes de texto */
/* **************************************************** */
@import "@fontsource/nunito-sans"; 
@import "@fontsource/nunito-sans/400.css"; // Specify weight
@import "@fontsource/nunito-sans/600.css"; // Specify weight
@font-face {
   font-family: NunitoSans-Regular;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf');
}
@font-face {
   font-family: NunitoSans-Medium;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Medium.ttf');
}
@font-face {
   font-family: NunitoSans-Bold;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf');
}
@font-face {
   font-family: Nunito-SemiBold;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf');
}
@font-face {
   font-family: WorkSans-Regular;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/work-sans/WorkSans-Regular.ttf');
}
@font-face {
   font-family: WorkSans-Medium;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/work-sans/WorkSans-Medium.ttf');
}
@font-face {
   font-family: WorkSans-Bold;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/work-sans/WorkSans-Bold.ttf');
}
@font-face {
   font-family: WorkSans-SemiBold;
   font-weight: 600;
   font-style: normal;
   src: url('../fonts/work-sans/WorkSans-SemiBold.ttf');
}
@font-face {
   font-family: 'Sigmi Icon';
   font-style: normal;
   src: url('../fonts/icons/sigmi-icons.eot');
   src: local('Sigmi Icon'), local('sigmi-icons'), url('../fonts/icons/sigmi-icons.eot?#iefix') format('embedded-opentype'),
      url('../fonts/icons/sigmi-icons.woff2') format('woff2'), url('../fonts/icons/sigmi-icons.woff') format('woff'),
      url('../fonts/icons/sigmi-icons.ttf') format('truetype'), url('../fonts/icons/sigmi-icons.svg') format('svg');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --font-montserrat-regular: NunitoSans-Regular, sans-serif;
    --font-montserrat-medium: NunitoSans-Medium, sans-serif;
    --font-montserrat-bold: NunitoSans-Bold, sans-serif;
    --font-montserrat-semibold: NunitoSans-SemiBold, sans-serif;
    --font-worksans-regular: Verdana, sans-serif;
    --font-worksans-medium: Verdana, sans-serif;
    --font-worksans-bold: Verdana, sans-serif;
    --font-worksans-semibold: Verdana, sans-serif;
}
