@import "@fontsource/nunito-sans"; 
@import "@fontsource/nunito-sans/400.css"; // Specify weight
@import "@fontsource/nunito-sans/600.css"; // Specify weight

.alert-govco {
   max-width: 600px !important;
}
.alert-govco .modal-content {
   padding: 0rem 1.563rem 1.25rem;
}
.alert-govco .modal-header {
   padding-top: 1.2rem;
   padding-right: 0rem;
}
.alert-govco .modal-header .close {
   color: #4672C8 !important;
}
.alert-govco .modal-body {
   padding: 0 9% 9%;
   margin-top: 1rem !important;
}
.alert-govco .modal-body.modal-content-maintenance .headline-xl-govco,
.alert-govco .modal-body.modal-content-maintenance .govco-icon,
.alert-govco .modal-body.modal-content-exit .headline-xl-govco,
.alert-govco .modal-body.modal-content-exit .govco-icon,
.alert-govco .modal-body.modal-content-warning .headline-xl-govco,
.alert-govco .modal-body.modal-content-warning .govco-icon {
   color: #FDAA29 !important;
}
.alert-govco .modal-body.modal-content-info .headline-xl-govco,
.alert-govco .modal-body.modal-content-info .govco-icon {
   color: #3772ff !important;
}
.alert-govco .modal-body.modal-content-success {
   padding: 9% !important;
}
.alert-govco .modal-body.modal-content-success .headline-xl-govco,
.alert-govco .modal-body.modal-content-success .govco-icon {
   color: #158361 !important;
}
.alert-govco .modal-body.modal-content-error {
   padding: 9% 9% 2% !important;
}
.alert-govco .modal-body.modal-content-error .headline-xl-govco,
.alert-govco .modal-body.modal-content-error .govco-icon {
   color: #a80521 !important;
}
.alert-govco .modal-body.modal-content-exit,
.alert-govco .modal-body.modal-content-warning {
   padding: 9% 9% 2% !important;
}
.alert-govco .modal-footer {
   display: block;
   margin: 0 auto;
   text-align: center;
}
.chk-govco,
.checkbox-govco {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   display: block;
   margin-bottom: 15px;
}
.chk-govco input,
.checkbox-govco input {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   height: initial;
   width: initial;
   margin-bottom: 0;
   display: none;
   cursor: pointer;
}
.chk-govco label,
.checkbox-govco label {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   position: relative;
   cursor: pointer;
   margin-bottom: 0px;
   display: initial;
}
.chk-govco label:before,
.checkbox-govco label:before {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   content: '';
   -webkit-appearance: none;
   background-color: transparent;
   border: 2px solid #0b457f;
   padding: 8px;
   display: inline-block;
   position: relative;
   vertical-align: middle;
   cursor: pointer;
   margin-right: 5px;
   border-radius: 5px;
}
.chk-govco input:checked + label:after,
.checkbox-govco input:checked + label:after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   content: '';
   display: block;
   position: absolute;
   top: 3px;
   left: 7px;
   width: 6px;
   height: 13px;
   border: solid #0b457f;
   border-width: 0 2px 2px 0;
   transform: rotate(45deg);
}
.checkbox-govco .disabled ::before,
.chk-govco .disabled ::before {
   color: #bababa !important;
}
.checklist-govco {
   position: relative;
   display: block;
   margin-bottom: 0px;
   border-bottom: 2px solid #f6f8f9;
   cursor: pointer;
}
.checklist-govco label {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   color: #4672C8;
}
.checklist-govco:hover,
.checklist-govco:focus {
   background-color: #f6f8f9 !important;
}
.checklist-govco:hover label,
.checklist-govco:focus label {
   font-weight: 600 !important;
}
.checklist-govco.error label {
   font-weight: 400 !important;
   color: #a80521 !important;
}
.checklist-govco.error:hover,
.checklist-govco.error:focus {
   background-color: #fff2fa !important;
}
.checklist-govco.error:hover label,
.checklist-govco.error:focus label {
   font-weight: 600 !important;
}
.checklist-govco.error label::before {
   border: 2px solid #a80521;
}
.checklist-govco.error input:checked + label:after {
   border: solid #a80521;
   border-width: 0 2px 2px 0;
}
.checklist-govco.disabled {
   background-color: transparent !important;
}
.checklist-govco.disabled label {
   color: #bababa !important;
}
.checklist-govco.disabled:hover label,
.checklist-govco.disabled:focus label {
   font-weight: 400 !important;
}
.checklist-govco.disabled label::before {
   border: 2px solid #bababa;
}
.checklist-govco.disabled input:checked + label:after {
   border: solid #bababa;
   border-width: 0 2px 2px 0;
}
.accordion-govco .card {
   border: none;
}
.accordion-govco .card .card-header {
   margin: 0;
   padding: 0;
   border: none;
   background: none;
}
.accordion-govco .card .card-header button {
   width: 100%;
   padding: 0.5rem 1rem;
   margin: 0;
   border: 2px solid #4672C8;
   background-color: #4672C8;
   display: flex;
   align-items: center;
   text-align: left;
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1rem;
   text-decoration: none;
   color: #ffffff;
}
.accordion-govco .card .card-header button:focus {
   outline: none;
}
.accordion-govco .card .card-header button:focus .btn-icon-close .govco-icon {
   text-decoration: none !important;
}
.accordion-govco .card .card-header button:hover {
   border-color: #4672C8;
}
.accordion-govco .card .card-header button:hover .title {
   text-decoration: underline;
}
.accordion-govco .card .card-header button:hover .btn-icon-close .govco-icon {
   text-decoration: none !important;
}
.accordion-govco .card .card-header button.collapsed {
   border-color: #ffffff;
   border-bottom-color: rgba(157, 190, 255, 0.3);
   color: #4672C8;
   background-color: #ffffff;
}
.accordion-govco .card .card-header button.collapsed .title {
   color: #4672C8;
   font-weight: 400;
}
.accordion-govco .card .card-header button.collapsed .btn-icon-close .label-icon {
   opacity: 0;
   transition: 0.5s all;
}
.accordion-govco .card .card-header button.collapsed .btn-icon-close .govco-icon-plus {
   display: block;
}
.accordion-govco .card .card-header button.collapsed .btn-icon-close .govco-icon-minus {
   display: none;
   transition: 0.5s all;
}
.accordion-govco .card .card-header button.collapsed:hover {
   border-color: #e5eefb;
}
.accordion-govco .card .card-header button .title {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1rem;
   color: #ffffff;
   font-weight: 600;
}
.accordion-govco .card .card-header button .btn-icon-close {
   width: 100%;
   padding: 0;
   margin: 0;
   text-align: right;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   text-decoration: none;
}
.accordion-govco .card .card-header button .btn-icon-close .label-icon {
   opacity: 1;
   color: #ffffff;
   font-size: 0.8rem;
   transition: 0.5s all;
}
.accordion-govco .card .card-header button .btn-icon-close .govco-icon-plus {
   display: none;
}
.accordion-govco .card .card-header button .btn-icon-close .govco-icon-minus {
   display: block;
   color: #ffffff;
   transition: 0.5s all;
}
.accordion-govco .card .card-header button .btn-icon-close .govco-icon {
   text-decoration: none !important;
}
.accordion-govco .card .card-header button .btn-icon-close .govco-icon::before {
   text-decoration: none !important;
}
.accordion-govco .card .card-body {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
}
.accordion-govco-simple {
   background: none;
}
.accordion-govco-simple .card {
   background: none;
   border: none;
}
.accordion-govco-simple .card .card-header {
   background: none;
   margin: 0;
   padding: 0;
   border: none;
}
.accordion-govco-simple .card .card-header button {
   width: 100%;
   text-align: left;
   display: flex;
   align-items: center;
   border: 1px solid rgba(0, 0, 0, 0);
   border-bottom-color: #4672C8;
   background: none;
   text-decoration: none;
   padding: 0 0.5rem;
}
.accordion-govco-simple .card .card-header button:focus {
   outline: none;
}
.accordion-govco-simple .card .card-header button:hover {
   text-decoration: none;
}
.accordion-govco-simple .card .card-header button.collapsed {
   border-color: transparent;
   border-bottom-color: #4672C8;
   background: none;
}
.accordion-govco-simple .card .card-header button.collapsed .title {
   color: #0943B5;
}
.accordion-govco-simple .card .card-header button.collapsed .btn-icon-close .label-icon {
   opacity: 0;
   transition: 0.5s all;
   color: #0943B5;
}
.accordion-govco-simple .card .card-header button.collapsed .btn-icon-close .govco-icon-simpler-arrow {
   display: block;
   color: #0943B5;
}
.accordion-govco-simple .card .card-header button.collapsed .btn-icon-close .govco-icon-simpled-arrow {
   display: none;
   transition: 0.5s all;
}
.accordion-govco-simple .card .card-header button .title {
   color: #4672C8;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 500;
}
.accordion-govco-simple .card .card-header button .btn-icon-close {
   width: 100%;
   padding: 0;
   margin: 0;
   text-align: right;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   text-decoration: none;
}
.accordion-govco-simple .card .card-header button .btn-icon-close .label-icon {
   font-size: 0.8rem;
   font-family: Verdana, Geneva, sans-serif;
}
.accordion-govco-simple .card .card-header button .btn-icon-close .govco-icon-simpler-arrow {
   display: none;
}
.accordion-govco-simple .card .card-header button .btn-icon-close .govco-icon-simpled-arrow {
   display: block;
   color: #4672C8;
   transition: 0.5s all;
}
.accordion-govco-simple .card .card-body {
   font-family: Verdana, Geneva, sans-serif;
}
.accordion-govco-simple .card .card-body p {
   font-family: Verdana, Geneva, sans-serif;
}
.accordion-govco .accordion-inside-govco .card {
   background: none;
}
.accordion-govco .accordion-inside-govco .card .card-header button {
   background: none;
   border-color: transparent;
   border-bottom-color: #4672C8;
}
.accordion-govco .accordion-inside-govco .card .card-header button.collapsed {
   border-color: none;
   background: none;
}
.accordion-govco .accordion-inside-govco .card .card-header button.collapsed .title {
   color: #0943B5;
}
.accordion-govco .accordion-inside-govco .card .card-header button.collapsed .btn-icon-close .label-icon {
   opacity: 0;
   transition: 0.5s all;
   color: #0943B5;
}
.accordion-govco .accordion-inside-govco .card .card-header button.collapsed .btn-icon-close .govco-icon-simpler-arrow {
   display: block;
   color: #0943B5;
}
.accordion-govco .accordion-inside-govco .card .card-header button.collapsed .btn-icon-close .govco-icon-simpled-arrow {
   display: none;
   transition: 0.5s all;
}
.accordion-govco .accordion-inside-govco .card .card-header button:hover {
   border-color: transparent;
   border-bottom-color: #4672C8;
}
.accordion-govco .accordion-inside-govco .card .card-header button .btn-icon-close .label-icon {
   font-size: 0.8rem;
   font-family: Verdana, Geneva, sans-serif;
   color: #4672C8;
}
.accordion-govco .accordion-inside-govco .card .card-header button .btn-icon-close .govco-icon-simpler-arrow {
   display: none;
}
.accordion-govco .accordion-inside-govco .card .card-header button .btn-icon-close .govco-icon-simpled-arrow {
   display: block;
   color: #4672C8;
   transition: 0.5s all;
}
.bg-color-white {
   background: #ffffff;
}
.bg-color-selago {
   background: #e5eefb;
}
.bg-color-dodger-blue {
   background: #3772ff;
}
.bg-color-marine {
   background: #0943B5;
}
.bg-color-dark-blue {
   background: #4672C8;
}
.bg-color-green {
   background: #158361;
}
.bg-color-gold {
   background: #FDAA29;
}
.bg-color-orange {
   background: #f3561f;
}
.bg-color-concrete {
   background: #f2f2f2;
}
.bg-color-silver {
   background: #bababa;
}
.bg-color-tundora {
   background: #4b4b4b;
}
.bg-color-tutu {
   background: #fff2fa;
}
.bg-color-radical-red {
   background: #f42e63;
}
.bg-color-shiraz {
   background: #a80521;
}
.bg-color-pale-sky {
   background: #6c757d;
}
.bg-color-hawkes-blue {
   background: #f6f8f9;
}
.bg-color-hawkes-blue-300 {
   background: rgba(157, 190, 255, 0.3);
}
.bg-color-catalina-blue {
   background: #0b457f;
}
.bg-color-catalina-blue-600 {
   background: rgba(11, 69, 127, 0.6);
}
.bg-color-black-160 {
   background: rgba(0, 0, 0, 0.16);
}
.bg-color-marine-400 {
   background: rgba(51, 102, 204, 0.4);
}
.bg-color-dark-blue-500 {
   background: rgba(0, 72, 132, 0.5);
}
.bg-color-shiraz-600 {
   background: rgba(168, 5, 33, 0.6);
}
.bg-color-white-contrast {
   background: white;
}
.bg-color-black-contrast {
   background: black;
}
.bg-color-yellow-contrast {
   background: #ddfb00;
}
.bg-color-orange-contrast {
   background: #fbc200;
}
.color-shiraz {
   color: #a80521 !important;
}
.color-green {
   color: #158361 !important;
}
.color-gold {
   color: #FDAA29 !important;
}
.color-marine {
   color: #0943B5 !important;
}
.color-dodger-blue {
   color: #3772ff !important;
}
.color-white {
   color: white !important;
}
.color-dark-blue {
   color: #4672C8 !important;
}
.tag-govco {
   border-radius: 1rem;
   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.85rem;
   display: inline-flex;
   align-items: center;
   padding: 0;
}
.tag-govco .label {
   font-family: Verdana, Geneva, sans-serif;
   padding: 0.3rem 0.8rem;
}
.tag-govco .govco-icon {
   line-height: 1 !important;
   padding-right: 0.1rem;
   cursor: pointer;
}
.tag-positive {
   border: 1px solid #e5eefb;
   color: #3772ff;
}
.tag-positive .label {
   color: #3772ff;
}
.tag-positive .govco-icon {
   color: #3772ff;
}
.tag-negative {
   background-color: #e5eefb;
   color: #4672C8;
}
.tag-negative .label {
   color: #4672C8;
}
.page__footer {
   padding-bottom: 70px;
   padding-top: 77px;
   margin-top: 1.8%;
   background-color: #0943B5 !important;
   color: white !important;
}
.page__footer .container ul li a:hover {
   text-decoration: none;
   background-color: transparent !important;
   box-shadow: none !important;
   color: white;
}
.page__footer .container .footer-container .footer-title {
   justify-content: center;
}
.page__footer .container .footer-container .footer-title .title-footer {
   justify-content: center;
   margin-top: 0px;
   text-align: center;
   font-size: 30px;
   font-family: 'Nunito Sans', sans-serif;
   letter-spacing: 0;
   color: #ffffff;
   font-weight: 300;
   margin-bottom: 30px;
}
.page__footer .container .footer-container .nav-footer {
   flex-wrap: wrap;
   flex-direction: row;
}
.page__footer .container .footer-container .nav-footer .item-footer {
   width: 14.25rem;
   display: flex;
   flex-direction: column;
}
.page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
   color: white !important;
   font-size: 2.8rem;
}
.page__footer .container .footer-container .nav-footer .item-footer p {
   color: #ffffff !important;
   margin-top: 0.5rem !important;
   margin-bottom: 0 !important;
   line-height: 1.25 !important;
   font-size: 1rem;
   font-weight: 400;
   text-rendering: geometricPrecision;
}
.page__footer .container .footer-container .nav-footer .item-footer .title-contact-link {
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #ffffff;
}
.page__footer .container .footer-container .nav-footer .item-footer .title-contact-link:first-letter {
   text-transform: capitalize !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .content-link {
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #ffffff;
   margin-top: 1px !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .content-link br {
   display: none;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link {
   text-align: center;
   border: 3px solid transparent !important;
   height: 100%;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
   margin-top: 0.5rem;
   margin-bottom: 0;
   line-height: 1.25;
   font-size: 1rem;
   text-transform: none;
   font-weight: 400;
   text-rendering: geometricPrecision;
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #fff;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:hover {
   border: 3px solid white !important;
   background-color: transparent;
   color: white !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:hover p {
   font-weight: 500;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:active {
   background-color: transparent;
   box-shadow: none;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:active p {
   font-weight: 500;
}
.page__footer .container .submenu-footer {
   padding: 0;
   display: flex;
}
.page__footer .container .submenu-footer ul {
   margin-top: 40px;
   margin-bottom: 0rem;
}
.page__footer .container .submenu-footer ul li {
   display: inline-block;
}
.page__footer .container .submenu-footer a {
   color: #ffffff;
   text-transform: none;
   padding: 0 1rem;
   border-radius: 3px;
   position: relative;
   display: block;
   text-align: center;
   text-decoration: underline;
   font-weight: bold;
   font-size: 18px;
   font-family: 'Nunito Sans', sans-serif;
   letter-spacing: 0;
}
.page__footer .container .submenu-footer a:focus,
.page__footer .container .submenu-footer a:active,
.page__footer .container .submenu-footer a:hover {
   color: white;
   text-decoration: underline;
}
.nav-pills .nav-item .nav-link.active {
   background-color: transparent !important;
   box-shadow: none !important;
}
@media (max-width: 575.98px) {
   .page__footer .container #footer-container {
      justify-content: left;
      padding: 0.5rem 1rem;
   }
   .page__footer .container #footer-container .title-footer {
      padding: 0.5rem 1rem;
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 0px;
      margin-top: 0px;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif, sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container #footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0.5rem !important;
      margin-left: 0px !important;
      display: contents;
   }
   .page__footer .container .submenu-footer {
      margin-top: 40px;
      justify-content: left;
   }
   .page__footer .container .submenu-footer ul {
      margin-top: 0rem;
      margin-bottom: 0rem;
      padding: 0.5rem 1rem;
   }
   .page__footer .container .submenu-footer ul li {
      width: 100%;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1rem 0.5rem;
   }
}
@media (max-width: 767.98px) {
   .page__footer {
      padding-bottom: 40px;
      padding-top: 40px;
   }
   .page__footer .container .footer-container .title-footer {
      margin-top: 0px;
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container .submenu-footer {
      margin-top: 0px;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1.5rem 1rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer {
      width: 100%;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
      margin-right: 15px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link {
      text-align: left;
      display: inline-flex;
      width: 100%;
      vertical-align: middle;
      align-items: center;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0px;
      margin-left: 10px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title {
      margin-top: 1rem;
      justify-content: left;
      padding-left: 20px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title h2 {
      font-size: 1.6rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p {
      text-align: left;
      font-size: 1rem !important;
      display: contents;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p::first-letter {
      text-transform: capitalize !important;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link {
      text-transform: none !important;
      white-space: nowrap;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link a {
      color: white;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p br {
      display: block;
   }
}
.all span.breadcrumb-text.item-link:hover {
   background: #ddfb00 !important;
}
footer {
   font-size: 16px;
}
.footer-links a {
   color: #fff !important;
   font-size: 0.85rem;
}
.footer-links a:hover {
   color: #fff;
   font-size: 0.85rem !important;
}
.content-link {
   color: #fff !important;
}
.text-bold {
   font-weight: 500 !important;
}
.image-footer {
   position: absolute;
   bottom: 0;
   right: 0;
}
.img-width {
   width: 36px;
}
.llamada-solicitud {
   margin: 10px 5px;
   font-size: 0.85rem !important;
   color: #fff !important;
}
.footer-icon-co {
   width: 30%;
   cursor: pointer;
   border-right: 1px solid #fff;
   padding-right: 1rem;
}
.footer-icon-govco {
   min-width: 70%;
   padding-left: 1rem;
   cursor: pointer;
}
.footer-border-icon-co {
   padding-right: 26px;
   border-right: 2px solid white;
}
.form-btn-sesion {
   position: relative;
}
.btn-footer-white {
   font-weight: 600;
   background-color: #0943B5 !important;
   border: 1px solid !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   display: flex !important;
   align-items: center !important;
}
.all #govco-footer {
   background: #000 !important;
   border: none !important;
   box-shadow: none !important;
}
.all #govco-footer .contacto button.btn-round {
   color: #ddfb00 !important;
   border-color: #ddfb00 !important;
   background-color: #000 !important;
}
#govco-footer {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 16px;
}
#govco-footer .footer-descripcion {
   font-size: 1rem !important;
   padding-right: 1rem;
   color: #ffffff !important;
}
@media screen and (max-width: 768px) {
   #govco-footer .footer-descripcion {
      font-size: 14px !important;
      padding: 0 !important;
   }
}
#govco-footer .footer-social {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#govco-footer .footer-social .redes-sociales {
   color: #ffffff;
}
#govco-footer .footer-social .redes-sociales span {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 0.875rem;
   color: #ffffff;
   padding-bottom: 0.5rem;
   display: block;
}
#govco-footer .footer-social .img-width {
   width: 2.5rem;
}
#govco-footer .footer-links a {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 0.875rem !important;
   font-weight: 400;
   text-decoration: underline;
   color: #ffffff;
}
#govco-footer .footer-links:last-of-type {
   margin: 0;
}
#govco-footer .contacto {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}
#govco-footer .contacto .footer-contacto-tel {
   font-size: 0.875rem;
   color: #ffffff;
}
#govco-footer .contacto button.btn-round {
   margin-top: 1rem !important;
   width: 100%;
   transition: 0.3s all ease-in-out;
}
#govco-footer .contacto button.btn-round .govco-icon {
   font-size: 1.5rem !important;
   margin: 0 !important;
   padding: 0 !important;
   display: inline-block;
}
#govco-footer .contacto button.btn-round .icono-btn {
   display: inline-block;
   width: 1.5rem;
}
#govco-footer .contacto button.btn-round .btn-govco-text {
   font-size: 0.875rem;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-weight: 400 !important;
   padding-top: 0.401rem;
   padding-bottom: 0.401rem;
   padding-left: 0.3rem;
   color: #ffffff;
   display: inline-block;
}
#govco-footer .contacto button.btn-round .govco-icon {
   font-size: 1rem;
   padding-right: 0.5rem;
}
#govco-footer .contacto button.btn-round:hover {
   background-color: #ffffff !important;
   color: #0943B5 !important;
   transition: 0.3s all ease-in-out;
}
#govco-footer .contacto button.btn-round:hover * {
   color: #0943B5 !important;
}
#govco-footer #icons-govco {
   width: 100%;
   display: flex;
}
#govco-footer .social-mobile {
   display: none;
}
@media only screen and (max-width: 768px) {
   .image-footer {
      position: inherit;
   }
   .footer-border-icon-co {
      padding-right: 10%;
      border-right: 2px solid white;
   }
   .footer-contacto-tel {
      font-size: 0.85rem;
      color: white;
   }
   #govco-footer .redes-sociales {
      margin-bottom: 1rem;
   }
   #govco-footer .texto-contacto p.text-bold.text-white {
      font-size: 1rem;
   }
   #govco-footer .contacto {
      width: 100%;
      margin: 1rem auto;
   }
   #govco-footer .contacto .btn-round {
      width: auto !important;
   }
   #govco-footer #icons-govco {
      margin: 1rem 0 0.5rem;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
   }
   #govco-footer #icons-govco .footer-icon-co {
      width: 22%;
      height: min-intrinsic;
   }
   #govco-footer #icons-govco .footer-icon-govco {
      width: 70%;
      height: min-intrinsic;
   }
   #govco-footer .social-mobile {
      display: block;
      padding: 1rem;
   }
   #govco-footer .social-mobile span {
      color: #ffffff;
   }
   #govco-footer .social-desktop {
      display: none;
   }
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 100;
   src: local('NunitoSans Thin'), local('NunitoSans-Thin'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Italic.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Italic.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 200;
   src: local('NunitoSans ExtraLight'), local('NunitoSans-ExtraLight'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Light.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Light.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 300;
   src: local('NunitoSans Light'), local('NunitoSans-Light'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Light.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Light.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 400;
   src: local('NunitoSans Regular'), local('NunitoSans-Regular'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 500;
   src: local('NunitoSans Medium'), local('NunitoSans-Medium'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Medium.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Medium.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 600;
   src: local('NunitoSans SemiBold'), local('NunitoSans-SemiBold'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 700;
   src: local('NunitoSans Bold'), local('NunitoSans-Bold'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 800;
   src: local('NunitoSans ExtraBold'), local('NunitoSans-ExtraBold'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-ExtraBold.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-ExtraBold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 900;
   src: local('NunitoSans Black'), local('NunitoSans-Black'), url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Black.ttf') format('ttf'),
      url('../fonts/Nunito_Sans/static/NunitoSans_10pt-Black.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 100;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Thin.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Thin.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 200;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-ExtraLight.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-ExtraLight.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 300;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Light.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Light.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 400;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Regular.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Regular.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 500;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Medium.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Medium.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 600;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-SemiBold.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-SemiBold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 700;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Bold.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Bold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 800;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-ExtraBold.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-ExtraBold.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: Verdana, Geneva;
   font-style: normal;
   font-weight: 900;
   font-display: swap;
   src: url('../fonts/work-sans/WorkSans-Black.ttf') format('ttf'), url('../fonts/work-sans/WorkSans-Black.ttf') format('truetype');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
@font-face {
   font-family: 'Govco Icon';
   font-style: normal;
   src: url('../fonts/icons/sigmi-icons.eot');
   src: local('Govco Icon'), local('govco-icons'), url('../fonts/icons/sigmi-icons.eot?#iefix') format('embedded-opentype'),
      url('../fonts/icons/sigmi-icons.ttf') format('ttf'), url('../fonts/icons/sigmi-icons.woff') format('woff'),
      url('../fonts/icons/sigmi-icons.ttf') format('truetype'), url('../fonts/icons/sigmi-icons.svg') format('svg');
}
//    @font-face {
//     font-family:"font password";
//     src:url("./assets/fonts/password/font-password.eot?e0ac");
//     src:url("./assets/fonts/password/font-password.eot?e0ac#iefix") format("embedded-opentype"),
//     url("./assets/fonts/password/font-password.ttf?e0ac") format("ttf"),
//     url("./assets/fonts/password/font-password.woff?e0ac") format("woff"),
//     url("./assets/fonts/password/font-password.ttf?e0ac") format("truetype"),
//     url("./assets/fonts/password/font-password.svg?e0ac#font-password") format("svg");
//     font-weight:normal;
//     font-style:normal
//    }
.fonts > label,
.gov-co-form-group > label,
.fonts > div > label,
.gov-co-form-group > div > label,
.fonts > div > small,
.gov-co-form-group > div > small {
   font-family: Verdana, Geneva, sans-serif;
   color: #4b4b4b;
}
.fonts > label:disabled,
.gov-co-form-group > label:disabled,
.fonts > div > label:disabled,
.gov-co-form-group > div > label:disabled,
.fonts > div > small:disabled,
.gov-co-form-group > div > small:disabled {
   color: #f2f2f2;
}
.fonts > small,
.gov-co-form-group > small,
.fonts > div > small,
.gov-co-form-group > div > small {
   font-size: 0.875rem;
}
.fonts > input::-webkit-input-placeholder,
.gov-co-form-group > input::-webkit-input-placeholder {
   color: rgba(75, 75, 75, 0.5);
}
.fonts > input::-moz-placeholder,
.gov-co-form-group > input::-moz-placeholder {
   color: rgba(75, 75, 75, 0.5);
}
.fonts > input::-ms-input-placeholder,
.gov-co-form-group > input::-ms-input-placeholder {
   color: rgba(75, 75, 75, 0.5);
}
.fonts > input::placeholder,
.gov-co-form-group > input::placeholder {
   color: rgba(75, 75, 75, 0.5);
}
.fonts > input:disabled,
.gov-co-form-group > input:disabled {
   border: 1px solid #f2f2f2;
   background-color: #bababa;
}
.gov-co-form-group {
   position: relative;
}
.gov-co-form-group .gov-co-label-counter {
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
}
.gov-co-form-group input,
.gov-co-form-group > div > select,
.gov-co-form-group > select {
   height: 40px;
   padding: 6px 16px;
   border: 1px solid #bababa;
}
.gov-co-form-group input:focus,
.gov-co-form-group > div > select:focus,
.gov-co-form-group > select:focus,
.gov-co-form-group textarea:focus {
   border-color: #bababa;
   box-shadow: 0 0 5px #0943B5;
}
.gov-co-form-group.gov-co-especificaciones {
   position: relative;
}
.gov-co-form-group.gov-co-especificaciones > .line-input {
   position: absolute;
   bottom: 0;
   left: 16px;
   right: 16px;
   height: 40px;
   border-left: 2px dashed #e6effd;
   border-right: 2px dashed #e6effd;
}
.gov-co-form-group.gov-co-especificaciones > .esp-lat {
   position: absolute;
   bottom: 0;
   right: -60px;
   height: 40px;
   padding: 8px 10px;
   color: #4672C8;
   border-left: 1px solid black;
}
.gov-co-form-group.gov-co-especificaciones > .esp-pad-left,
.gov-co-form-group.gov-co-especificaciones > .esp-pad-right {
   width: 16px;
   position: absolute;
   color: #4672C8;
   border-top: 1px solid black;
   bottom: -30px;
}
.gov-co-form-group.gov-co-especificaciones > .esp-pad-right {
   right: 0;
}
.gov-co-form-group.gov-co-uso > input,
.gov-co-form-group.gov-co-uso > div > select,
.gov-co-form-group.gov-co-uso textarea {
   box-shadow: 0 0 5px #0943B5;
}
.gov-co-form-group.inactive {
   pointer-events: none;
}
.gov-co-form-group.inactive > label,
.gov-co-form-group.inactive > div > label,
.gov-co-form-group.inactive > div > small,
.gov-co-form-group.inactive > div > select,
.gov-co-form-group.inactive textarea {
   color: #bababa;
}
.gov-co-form-group.inactive > input,
.gov-co-form-group.inactive > div > select,
.gov-co-form-group.inactive textarea {
   border: 1px solid #bababa;
   background-color: #f2f2f2;
}
.gov-co-form-group.gov-co-error > label,
.gov-co-form-group.gov-co-error > div > label,
.gov-co-form-group.gov-co-error > div > small,
.gov-co-form-group.gov-co-error > input,
.gov-co-form-group.gov-co-error > small,
.gov-co-form-group.gov-co-error > div > select,
.gov-co-form-group.gov-co-error textarea {
   color: #a80521;
}
.gov-co-form-group.gov-co-error > div > select ~ span {
   color: #a80521;
}
.gov-co-form-group.gov-co-error > input,
.gov-co-form-group.gov-co-error > div > select {
   border: 1px solid #a80521 !important;
}
.gov-co-form-group.gov-co-error > div > select.active {
   box-shadow: 0 0 5px #a80521;
}
.gov-co-form-group.gov-co-error > .gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder {
   border: 1px solid #a80521;
   color: #a80521;
}
.gov-co-form-group.gov-co-error > .gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
   box-shadow: 0 0 5px #a80521;
}
.gov-co-form-group.gov-co-valido > label,
.gov-co-form-group.gov-co-valido > div > label,
.gov-co-form-group.gov-co-valido > div > small,
.gov-co-form-group.gov-co-valido > input,
.gov-co-form-group.gov-co-valido > small,
.gov-co-form-group.gov-co-valido > div > select,
.gov-co-form-group.gov-co-valido textarea {
   color: #158361;
}
.gov-co-form-group.gov-co-valido > div > select ~ span {
   color: #158361;
}
.gov-co-form-group.gov-co-valido > input,
.gov-co-form-group.gov-co-valido > div > select {
   border: 1px solid #158361 !important;
}
.gov-co-form-group.gov-co-valido > div > select.active {
   box-shadow: 0 0 5px #158361;
}
.gov-co-form-group.gov-co-valido > .gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder {
   border: 1px solid #158361;
   color: #158361;
}
.gov-co-form-group.gov-co-valido > .gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
   box-shadow: 0 0 5px #158361;
}
.gov-co-form-group.gov-co-quemado > input {
   border: none;
   pointer-events: none;
}
.gov-co-form-group > .gov-co-dropdown-mu {
   position: relative;
   border-radius: 5px;
   height: 40px;
   border: 1px solid #bababa;
}
.gov-co-form-group > .gov-co-dropdown-mu > select {
   border: 0 !important;
   background-color: transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   z-index: 2;
   position: absolute;
   width: 100%;
   height: 100%;
}
.gov-co-form-group > .gov-co-dropdown-mu > select::-ms-expand {
   display: none;
}
.gov-co-form-group > .gov-co-dropdown-mu > select::-moz-focus-inner {
   border: 0;
   padding: 0;
}
.gov-co-form-group > .gov-co-dropdown-mu > select.active ~ span {
   transform: rotate(180deg);
}
.gov-co-form-group > .gov-co-dropdown-mu > span {
   display: block;
   position: absolute;
   top: 0;
   right: 0;
   z-index: 1;
   padding-top: 8px;
   height: 100%;
   width: 40px;
   text-align: center;
   font-size: 10px;
   border-radius: 100%;
   color: #0943B5;
   transition: all 500ms ease-in-out;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select {
   cursor: pointer;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle {
   border: 1px solid #bababa;
   padding-right: 5px;
   text-overflow: ellipsis;
   white-space: nowrap;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle:not(.bs-placeholder) .filter-option-inner-inner {
   color: #0b457f !important;
   padding-right: 5px;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle .bs-placeholder .filter-option-inner-inner {
   color: rgba(11, 69, 127, 0.6) !important;
   padding-right: 5px;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle span {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle .ripple-container {
   display: none;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-toggle::after {
   width: 40px;
   border: 1px solid transparent;
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   padding-top: 10px;
   text-align: center;
   font-family: 'Govco Icon';
   content: '\e826' !important;
   color: #4672C8 !important;
   font-size: 10px;
   border-radius: 100%;
   transition: all 500ms ease-in-out;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select.show .dropdown-toggle::after {
   transform: rotate(180deg);
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select.show.show > .btn-light.dropdown-toggle {
   background: initial;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-menu {
   padding: 0 !important;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-menu .dropdown-item.active,
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-menu .dropdown-item:active {
   color: #fff;
   text-decoration: none;
   background-color: #0943B5;
}
.gov-co-form-group > .gov-co-dropdown .bootstrap-select .dropdown-menu .dropdown-item:hover {
   background-color: #e6effd !important;
}
.gov-co-form-group > .gj-datepicker {
   border: 1px solid #bababa;
   background-color: #ffffff;
   border-radius: 5px;
   height: 40px;
}
.gov-co-form-group > .gj-datepicker input {
   border: none;
   background-color: transparent;
}
.gov-co-form-group > .gj-datepicker span {
   border: none;
}
.gov-co-form-group > .gj-datepicker span button {
   border: none;
}
.gov-co-form-group > .gj-datepicker > .gj-icon {
   color: #0943B5;
   height: 100%;
   padding: 7px;
   border: none !important;
}
.gov-co-form-group > .group-ctrl {
   position: relative;
}
.gov-co-form-group > .group-ctrl span {
   position: absolute;
   height: 100%;
   top: 0;
   right: 0;
   padding: 8px;
   font-size: 1rem;
   color: #0943B5;
}
.gov-co-form-group > .group-keyboard {
   min-width: 243px;
   display: none;
   background-color: #ffffff;
   width: 70%;
   top: 0;
   border: 1px solid #0943B5;
   box-shadow: 0 0 3px #0943B5;
   margin-top: 5px;
   padding: 20px 5px;
   position: absolute;
   top: 100%;
}
.gov-co-form-group > .group-keyboard table {
   margin: 0 auto;
}
.gov-co-form-group > .group-keyboard table tbody {
   padding: 10px;
}
.gov-co-form-group > .group-keyboard table tbody td {
   padding: 0;
   border: initial;
}
.gov-co-form-group > .group-keyboard table tbody td .btn {
   border: 1px solid #0943B5;
   box-shadow: 0 0 3px #0943B5;
   width: 55px;
   margin: 5px;
}
.gov-co-form-group > .group-keyboard table tbody td .btn span {
   color: #0943B5;
   font-size: 1rem;
}
.gov-co-form-group > .group-keyboard table tbody td .btn.hover-des,
.gov-co-form-group > .group-keyboard table tbody td .btn:hover {
   background-color: #e6effd;
   box-shadow: initial;
   border: initial;
}
.gov-co-form-group > .group-keyboard table tbody td .btn.active-des,
.gov-co-form-group > .group-keyboard table tbody td .btn:active {
   background-color: #4672C8;
   color: #ffffff;
}
.gov-co-form-group > .group-keyboard table tbody td .btn.active-des > span,
.gov-co-form-group > .group-keyboard table tbody td .btn:active > span {
   color: #ffffff;
}
.gov-co-form-group > .group-keyboard.show,
.gov-co-form-group > .group-keyboard.active {
   display: block;
   z-index: 11;
}
.gov-co-form-group > .group-keyboard.block-keyBoard {
   display: block;
   position: relative;
}
.gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder {
   color: #bababa;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
   box-shadow: none;
   background-color: transparent !important;
   color: #0943B5;
}
.gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.gov-co-dropdown > .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
   background-color: #ffffff;
   box-shadow: 0 0 5px #0943B5;
}
.bootstrap-select .dropdown-toggle:focus {
   outline: none !important;
}
.btn-outline-secondary {
   color: #0943B5;
}
.gj-datepicker-bootstrap input {
   box-shadow: none !important;
}
.gj-datepicker-bootstrap input:active,
.gj-datepicker-bootstrap input:focus {
   box-shadow: none !important;
   -moz-box-shadow: none !important;
   -webkit-box-shadow: none !important;
}
.gj-picker-bootstrap .gj-icon {
   color: #0943B5 !important;
}
.gj-picker-bootstrap div[role='period'],
.gj-picker-bootstrap table tr th div,
.gj-picker-bootstrap td.current-month > div {
   color: #4672C8;
}
.gj-picker-bootstrap div[role='navigator'] {
   display: flex;
   align-items: center;
   margin: 0.3em 0;
}
[type='decade'].gj-picker-bootstrap table tr td div,
[type='century'].gj-picker-bootstrap table tr td div,
[type='year'].gj-picker-bootstrap table tr td div {
   color: #4672C8;
}
[type='decade'].gj-picker-bootstrap table tr td div:hover,
[type='decade'].gj-picker-bootstrap table tr td div:focus,
[type='decade'].gj-picker-bootstrap table tr td div:active,
[type='century'].gj-picker-bootstrap table tr td div:hover,
[type='century'].gj-picker-bootstrap table tr td div:focus,
[type='century'].gj-picker-bootstrap table tr td div:active,
[type='year'].gj-picker-bootstrap table tr td div:hover,
[type='year'].gj-picker-bootstrap table tr td div:focus,
[type='year'].gj-picker-bootstrap table tr td div:active {
   background-color: #e6effd;
   border-radius: 4px;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
   background-color: #ffffff !important;
}
.gj-picker-bootstrap div[role='navigator'] .gj-icon {
   font-family: 'Govco Icon' !important;
}
.gj-picker-bootstrap div[role='navigator'] .gj-icon.chevron-right::before {
   content: '\e802' !important;
}
.gj-picker-bootstrap div[role='navigator'] .gj-icon.chevron-left::before {
   content: '\e830' !important;
}
.gj-picker-md table tr td.selected.gj-cursor-pointer div {
   border-radius: initial;
}
.gj-picker-bootstrap table tr td.gj-cursor-pointer,
.gj-picker-md table tr td.gj-cursor-pointer {
   width: 40px;
   height: 40px;
}
.gj-picker-bootstrap table tr td.gj-cursor-pointer div,
.gj-picker-md table tr td.gj-cursor-pointer div {
   margin: 0 auto;
}
.gj-picker-bootstrap table tr td.gj-cursor-pointer div:hover,
.gj-picker-md table tr td.gj-cursor-pointer div:hover {
   border-radius: initial;
   background-color: #e6effd;
   border-radius: 4px;
}
.gj-picker-bootstrap table tr td.gj-cursor-pointer:hover,
.gj-picker-md table tr td.gj-cursor-pointer:hover {
   border-radius: initial;
   background-color: #e6effd;
   border-radius: 4px;
}
.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer {
   color: #fff !important;
   background-color: #0943B5;
   border-color: #0943B5;
   border-radius: 4px;
}
.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
   color: #fff !important;
   background-color: #0943B5;
   border-color: #0943B5;
   border-radius: 4px;
}
.svg-block {
   position: absolute;
   height: 100%;
   top: 0;
   right: 0;
   padding: 10px;
   z-index: 3;
}
.svg-block .govco-icon {
   cursor: pointer;
   line-height: normal;
   font-size: 1rem;
}
.tb-teclado-item {
   position: absolute;
   padding: 0.7em;
   background-color: #ffffff;
   display: none;
   width: 100%;
   z-index: 1;
   box-shadow: 0 0 3px #0943B5;
   margin-top: 5px;
   padding: 20px 5px;
}
.tb-teclado-item.show {
   display: flex;
   justify-content: center;
   align-content: space-between;
}
.td-number-item {
   width: 55px;
   height: 2.3em;
   border: 1px solid #0943B5;
   box-shadow: 0 0 3px #0943B5;
   margin: 5px 3px;
}
.btn-delete-teclado {
   margin-top: 5px;
   line-height: 15px;
}
.td-number-item:hover {
   background-color: #e6effd;
   border: none;
}
.td-number-item:active {
   background-color: #4672C8;
   color: white;
   border: none;
}
.teclado-password {
   letter-spacing: 6px;
}
.pass-type {
   -webkit-text-security: disc;
}
.checkbox-govco {
   margin: 0;
   padding: 0;
   display: block;
   margin-bottom: 15px;
}
.checkbox-govco input {
   margin: 0;
   padding: 0;
   height: initial;
   width: initial;
   margin-bottom: 0;
   display: none;
   cursor: pointer;
}
.checkbox-govco label {
   margin: 0;
   padding: 0;
   position: relative;
   cursor: pointer;
   margin-bottom: 0px;
   display: initial;
   padding-left: 36px;
}
.checkbox-govco label:before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 20px;
   height: 20px;
   -webkit-appearance: none;
   border: 3px solid #0943B5;
   border-radius: 4px;
}
.checkbox-govco input:checked + label:after {
   content: '';
   margin: 0;
   padding: 0;
   display: block;
   position: absolute;
   top: 3px;
   left: 7px;
   width: 6px;
   height: 13px;
   border: solid #ffffff;
   border-width: 0 2px 2px 0;
   transform: rotate(45deg);
}
.checkbox-govco input:checked + label:before {
   background-color: #4672C8;
   border: 3px solid #4672C8;
}
.checkbox-govco .disabled ::before {
   color: #bababa !important;
}
.checklist-govco {
   position: relative;
   display: block;
   cursor: pointer;
   margin: 5px 0;
   padding: 10px 16px;
}
.checklist-govco:hover {
   background-color: #e6effd;
}
.checklist-govco label {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
}
.checklist-govco label:hover {
   background-color: #e6effd;
}
.radiobutton-govco {
   position: relative;
}
.radiobutton-govco input {
   display: none;
   cursor: pointer;
}
.radiobutton-govco label {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   color: #4672C8;
   cursor: pointer;
   position: relative;
   margin-bottom: 3px;
   padding: 2px 30px;
   height: 100%;
}
.radiobutton-govco label:before {
   content: '';
   -webkit-appearance: none;
   background-color: transparent;
   border: 2px solid #0943B5;
   width: 20px;
   height: 20px;
   top: 0;
   left: 0;
   bottom: 0;
   border-radius: 100%;
   position: absolute;
   cursor: pointer;
   margin: auto;
}
.radiobutton-govco input:checked + label:after {
   content: '';
   display: block;
   position: absolute;
   top: 0;
   left: 5px;
   bottom: 0;
   width: 10px;
   height: 10px;
   border-radius: 100%;
   margin: auto;
   background-color: #4672C8;
}
.radiobutton-govco input:checked + label::before {
   border: 2px solid #4672C8;
}
.radiobutton-govco input:disabled + label::before {
   border: 3px solid #e6effd;
   cursor: not-allowed;
}
.custom-file.file-govco {
   min-height: 7.188rem;
   margin-bottom: 10px;
}
.custom-file-label.label-file-govco {
   display: flex;
   align-items: center;
   flex-direction: row;
   border-style: dashed;
   vertical-align: middle;
   justify-content: center;
   text-align: left;
   z-index: 1;
   margin-top: 12px;
   padding: 2.375em;
   font-weight: 400;
   line-height: 1.5;
   color: #0943B5;
   background-color: #ffffff;
   border-color: #0943B5;
   border-width: 2px;
   border-radius: 0.25rem;
   height: 115px;
}
.custom-file-label.label-file-govco span {
   margin-right: 16px;
}
.custom-file-label.label-file-govco.status-load {
   pointer-events: none;
}
.custom-file-label.label-file-govco.status-active,
.custom-file-label.label-file-govco:active,
.custom-file-label.label-file-govco:hover {
   color: #ffffff;
   background-color: #0943B5;
   border-color: #0943B5;
}
.custom-file-label.label-file-govco:disabled {
   background-color: #ffffff;
}
.custom-file-label.label-file-govco .text-primary {
   color: #4672C8 !important;
   margin-right: 16px;
}
.custom-file-label.label-file-govco.loaded {
   background-color: #ffffff;
   pointer-events: none;
}
.custom-file-label.label-file-govco.hide {
   display: none;
}
.tag-govco.tag-negative {
   display: inline-flex;
   align-items: center;
   flex-direction: row;
   border-style: hidden;
   vertical-align: middle;
   justify-content: space-between;
   text-align: center;
   z-index: 1;
   margin-top: 12px;
   height: calc(2rem + 0.75rem + 2px);
   padding: 0.375rem 1.25rem;
   line-height: 1.5;
   color: #0943B5;
   background-color: #e5eefb;
   border-width: 2px;
   width: 100%;
   border-radius: 1.7rem;
   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
}
.custom-file-label.label-file-govco::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   z-index: 4;
   display: block;
   height: 0px;
   padding: 0 0.375rem;
   line-height: 1.5;
   color: #0943B5;
   content: '';
   background-color: #e9ecef;
   border-left: inherit;
   border-radius: 0 0.25rem 0.25rem 0;
   display: none;
}
.clear-files-govco {
   align-items: flex-end;
   font-size: 0.75rem;
}
.accordion-govco .card {
   border-radius: 0;
   background: none;
   border-left-width: 0;
   border-right-width: 0;
   border: 1px solid #e6effd;
}
.accordion-govco .card:first {
   border: 2px solid #e6effd;
}
.accordion-govco .card .card-header {
   background: none;
   padding-top: 7px;
   padding-bottom: 7px;
   border-radius: 0;
   border-bottom: 0;
}
.accordion-govco .card .card-header .bullet {
   border-radius: 20px;
   color: white;
   background: #4672C8;
   height: 33px;
   width: 33px;
   display: inline-block;
   text-align: center;
   line-height: 2;
   margin-right: 10px;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: bold;
}
.accordion-govco .card-header h2 {
   font-size: 1rem;
   font-family: 'Roboto', sans-serif;
}
.accordion-govco .card-header .btn {
   color: #4672C8;
   width: 100%;
   text-align: left;
   padding-left: 0;
   padding-right: 0;
   text-transform: unset;
}
.accordion-govco .card-header .btn.collapsed .govco-icon {
   transform: rotate(0);
}
.accordion-govco .card-header .btn span.govco-icon {
   color: #4672C8;
   font-size: 1.2rem;
   float: right;
   transform: rotate(180deg);
}
.accordion-govco .card-header i {
   font-size: 1.3rem;
   position: absolute;
   top: 15px;
   right: 1rem;
}
.accordion-govco .card-header .btn:hover {
   color: #0069d9;
}
.accordion-govco .card-body {
   color: #666;
}
.accordion-govco.highlight i {
   transform: rotate(180deg);
}
.nav-pills .nav-link {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 500;
   font-size: 1rem;
   color: #4672C8;
   border: 1px solid #e6effd;
   position: relative;
   border-radius: initial;
   min-width: 200px;
   max-width: 330px;
}
.nav-pills .nav-link::after {
   content: '\f104'; // \e830
   font-size: xx-large;
   display: flex;
   align-items: center;
   justify-content: space-around;
   font-family: 'Govco Icon';
   position: absolute;
   width: 40px;
   height: 40px;
   top: 0;
   bottom: 0;
   right: 0;
   padding: 6px 1.5rem;
   margin: auto;
   text-align: center;
   border: 1px solid transparent;
   border-radius: 100%;
   transform: rotate(180deg);
   transition: all 500ms ease-in-out;
}
.nav-pills .nav-link.hover,
.nav-pills .nav-link:hover {
   background-color: #e6effd;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link:active {
   background-color: #4672C8;
   color: #ffffff;
}
.nav-pills .nav-link.active::after,
.nav-pills .nav-link:active::after {
   transform: rotate(0);
}
.tabs-govco .nav-tabs > .nav-item.nav-link,
.tabs-govco .nav-tabs > .nav-item > .nav-link {
   text-align: center;
   width: 260px;
   height: 64px;
   padding: 18px 20px 25px;
   margin: 0 5px;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 500;
   font-size: 0.938rem;
   color: #4672C8;
   border: 1px solid #e6effd;
}
.tabs-govco .nav-tabs > .nav-item.nav-link.active,
.tabs-govco .nav-tabs > .nav-item > .nav-link.active {
   border-bottom: 3px solid #4672C8;
   background-color: #e6effd;
   font-weight: 700;
}
.tab-content {
   border: 1px solid #e6effd;
}
.btn-round {
   min-width: 162px;
   border-radius: 30px;
   padding: 0.535em 1rem;
   white-space: pre-line;
   border: 1.5px solid;
   text-transform: uppercase;
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.9375rem;
   font-weight: 500;
   color: #ffffff;
   line-height: 1.5 !important;
   margin: 1rem 0.5rem;
}
.btn {
   position: relative !important;
}
.icon-reverse-x {
   transform: rotate(-180deg) scaleX(1);
}
.btn-govco-text,
.btn-low {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.9375rem;
   font-weight: 500;
   color: #0943B5;
   line-height: 1.5 !important;
}
.btn-symbolic-govco {
   margin: auto;
   padding: 1px 7px 2px;
   background: none !important;
   border: none !important;
   display: inline-flex;
   text-decoration: none !important;
}
.btn-symbolic-govco .govco-icon {
   color: #0943B5 !important;
   font-size: 2.2rem;
   margin: auto;
}
.btn-symbolic-govco.inactive {
   color: #bababa !important;
   cursor: default !important;
}
.btn-symbolic-govco.inactive .govco-icon {
   color: #bababa !important;
}
.btn-symbolic-govco.inactive .btn-govco-text,
.btn-symbolic-govco.inactive .btn-low {
   color: #bababa !important;
}
.btn-symbolic-govco:not(.inactive):hover,
.btn-symbolic-govco:not(.inactive):active,
.btn-symbolic-govco:not(.inactive):focus {
   color: #4672C8 !important;
   text-decoration: none !important;
}
.btn-symbolic-govco:not(.inactive):hover .govco-icon,
.btn-symbolic-govco:not(.inactive):hover .btn-govco-text,
.btn-symbolic-govco:not(.inactive):hover .btn-low,
.btn-symbolic-govco:not(.inactive):active .govco-icon,
.btn-symbolic-govco:not(.inactive):active .btn-govco-text,
.btn-symbolic-govco:not(.inactive):active .btn-low,
.btn-symbolic-govco:not(.inactive):focus .govco-icon,
.btn-symbolic-govco:not(.inactive):focus .btn-govco-text,
.btn-symbolic-govco:not(.inactive):focus .btn-low {
   color: #4672C8 !important;
   text-decoration: none !important;
}
.textright-govco {
   padding-left: 0.5rem;
   margin: auto;
}
.align-column-govco {
   display: inline-grid !important;
   text-align: center !important;
}
.btn-high,
.btn-high-mix-govco {
   background-color: #0943B5 !important;
   border-color: #0943B5;
   color: #ffffff;
}
.btn-high.inactive,
.inactive.btn-high-mix-govco,
.btn-high.inactive:hover,
.inactive.btn-high-mix-govco:hover {
   background-color: #bababa !important;
   border-color: #bababa !important;
   cursor: default !important;
}
.btn-middle {
   color: #0943B5 !important;
   border-color: #0943B5;
   background-color: white;
}
.btn-middle.inactive,
.btn-middle.inactive:hover {
   color: #bababa !important;
   border-color: #bababa !important;
   background-color: #ffffff !important;
   cursor: default !important;
}
.btn-middle.hover,
.btn-high.hover,
.hover.btn-high-mix-govco,
.btn-high:active,
.btn-high-mix-govco:active,
.btn-high:hover,
.btn-high-mix-govco:hover,
.btn-middle:hover,
.btn-middle:active {
   background-color: #4672C8 !important;
   border-color: #4672C8 !important;
   color: white !important;
}
.btn-high:focus,
.btn-high-mix-govco:focus,
.btn-middle:focus {
   outline: 0;
   box-shadow: 0 0 0 0.2rem #ffffff00;
}
.btn-low {
   text-decoration: underline !important;
}
.btn-low.inactive,
.btn-low.inactive:hover {
   color: #bababa !important;
   cursor: default !important;
}
.btn-low.hover,
.btn-low:active,
.btn-low:hover,
.btn-low:focus {
   color: #4672C8 !important;
}
.btn-high-mix-govco {
   padding: 0.5em 1rem;
   display: flex;
   -moz-column-gap: 10px;
   column-gap: 10px;
   justify-content: space-around;
   align-items: center;
}
.btn-low-mix-govco {
   text-decoration: none !important;
   text-transform: inherit !important;
   display: flex;
   -moz-column-gap: 10px;
   column-gap: 10px;
   align-items: center;
}
.btn-low-mix-govco:hover {
   color: #4672C8 !important;
   text-decoration: none !important;
}
.btn-low-mix-govco:hover .govco-icon,
.btn-low-mix-govco:hover .btn-govco-text,
.btn-low-mix-govco:hover .btn-low {
   color: #4672C8 !important;
   text-decoration: none !important;
}
.btn-low-mix-govco.inactive .govco-icon,
.btn-low-mix-govco.inactive .btn-govco-text,
.btn-low-mix-govco.inactive .btn-low,
.btn-low-mix-govco.inactive:hover .govco-icon,
.btn-low-mix-govco.inactive:hover .btn-govco-text,
.btn-low-mix-govco.inactive:hover .btn-low {
   color: #bababa !important;
   cursor: default !important;
}
.btn-high-mix-govco .govco-icon,
.btn-low-mix-govco .govco-icon {
   font-size: 1.5rem !important;
   line-height: normal !important;
   color: #0943B5;
}
.btn-up-hover:hover,
.btn-up-hover:focus {
   transform: translateX(-10.2em);
   background-color: white;
   border-radius: 1em 0em 0em 1em;
}
.btn-up-hover:hover .a-start-up .btn-svg-up-hover,
.btn-up-hover:focus .a-start-up .btn-svg-up-hover {
   fill: #3772ff;
}
.btn-up-hover:focus {
   outline: none;
}
.btn-up-hover:active {
   background-color: #3772ff;
}
.btn-up-hover:active .a-start-up {
   visibility: visible;
   text-decoration: none !important;
   font-size: 1.4em;
   color: white;
   font-style: unset;
}
.btn-up-hover:active .a-start-up .btn-svg-up-hover {
   fill: white;
}
.btn-up-hover-clone {
   padding: 0.5em;
   justify-content: center;
   border-radius: 1em 1em 1em 1em;
   background-color: #f2f2f2;
   vertical-align: middle;
   transform: perspective(1em) translateZ(0);
   transition-duration: 0.8s;
   transition-property: transform;
}
.btn-svg-up-hover-clone {
   fill: #4b4b4b;
}
.a-start-up-clone {
   visibility: visible;
   text-decoration: none !important;
   font-size: 1.4em;
   color: #3772ff;
   font-style: unset;
}
.btn-up-hover-clone:hover,
.btn-up-hover-clone:focus {
   background-color: white;
   border-color: #3772ff;
   border-radius: 1em 0em 0em 1em;
   color: color-dodger-blue;
}
.btn-up-hover-clone:hover .a-start-up-clone .btn-svg-up-hover-clone,
.btn-up-hover-clone:focus .a-start-up-clone .btn-svg-up-hover-clone {
   fill: #3772ff;
}
.btn-up-hover-clone:active {
   background-color: #3772ff;
}
.btn-up-hover-clone:active .a-start-up-clone {
   visibility: visible;
   text-decoration: none !important;
   font-size: 1.4em;
   color: white;
   font-style: unset;
}
.btn-up-hover-clone:active .a-start-up-clone .btn-svg-up-hover-clone {
   fill: white;
}
.div-star-up {
   position: fixed;
   bottom: 3em;
   right: -10.5em;
   z-index: 4;
}
.btn-up-hover {
   cursor: pointer;
   padding: 0.5em;
   justify-content: center;
   border-radius: 1em 1em 1em 1em;
   background-color: #f2f2f2;
   vertical-align: middle;
   transform: perspective(1em) translateZ(0);
   transition-duration: 0.8s;
   transition-property: transform;
}
.btn-svg-up-hover {
   fill: #4b4b4b;
}
.a-start-up {
   visibility: visible;
   text-decoration: none !important;
   font-size: 1.4em;
   color: #3772ff;
   font-style: unset;
}
.a-start-up:hover,
.a-start-up:visited,
.a-start-up:active {
   color: #3772ff;
}
.a-start-up svg {
   vertical-align: middle;
}
.show {
   transition: opacity 400ms;
}
.hide {
   opacity: 0;
}
.btn-up-hover:hover,
.btn-up-hover:focus {
   transform: translateX(-10.2em);
   background-color: white;
   border-color: #3772ff;
   border-radius: 1em 0em 0em 1em;
}
.btn-up-hover:hover .a-start-up .btn-svg-up-hover,
.btn-up-hover:focus .a-start-up .btn-svg-up-hover {
   fill: #3772ff;
}
.btn-up-hover:focus {
   outline: none;
}
.btn-up-hover:active {
   background-color: #3772ff;
}
.btn-up-hover:active .a-start-up {
   visibility: visible;
   text-decoration: none !important;
   font-size: 1.4em;
   color: white;
   font-style: unset;
}
.btn-up-hover:active .a-start-up .btn-svg-up-hover {
   fill: white;
}
.alert {
   border-radius: 3px 3px 0 0;
   text-align: center;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1.125rem;
   color: #ffffff;
   height: 50px;
}
.alert span {
   margin-right: 15px;
   font-size: 1.25rem;
}
.gov-co-alert-sucess {
   background-color: #158361;
}
.gov-co-alert-process {
   background-color: #0943B5;
}
.gov-co-alert-error {
   background-color: #d11f3e;
}
.modal {
   background-color: #00000033;
}
.modal-dialog.gov-co-modal {
   max-width: initial;
   margin: initial;
   width: 100%;
   height: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
}
.modal-dialog.gov-co-modal .modal-content {
   max-width: 540px;
   padding: 60px;
}
.modal-dialog.gov-co-modal .modal-content .modal-body {
   padding: 0;
   text-align: center;
   justify-content: center;
}
.modal-dialog.gov-co-modal .modal-content .modal-body .gov-co-modal-type h2 {
   line-height: 1.125em !important;
   padding: 20px 0 30px;
}
.modal-dialog.gov-co-modal .modal-content .modal-body .gov-co-modal-type span {
   font-size: 3.5em;
}
.modal-dialog.gov-co-modal .modal-content .modal-body .gov-co-modal-message {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #4b4b4b;
   margin-bottom: 1.875em;
}
.modal-dialog.gov-co-modal .modal-content .modal-body .gov-co-modal-message p {
   margin-bottom: initial;
}
.modal-dialog.gov-co-modal .modal-content.modal-info {
   position: relative;
}
.modal-dialog.gov-co-modal .modal-content.modal-info .close {
   position: absolute;
   top: 22px;
   right: 22px;
   z-index: 3;
}
.modal-dialog.gov-co-modal .modal-content.modal-info .gov-co-modal-type {
   text-align: left;
   margin-bottom: 30px;
}
.modal-dialog.gov-co-modal .modal-content.modal-info .gov-co-modal-type h2 {
   padding: 20px 0 30px;
}
.modal-dialog.gov-co-modal .modal-content.modal-info .gov-co-modal-type h4 {
   line-height: 1.125em !important;
}
.modal-dialog.gov-co-modal .modal-content .modal-footer {
   padding: 0;
   border: initial;
   display: flex;
   justify-content: space-between;
}
.modal-dialog.gov-co-modal .modal-content .modal-footer .btn {
   margin: initial;
   margin-top: 10px;
}
.modal-dialog.gov-co-modal .modal-content .modal-footer.center {
   justify-content: space-evenly;
}
.modal-dialog.gov-co-modal.gov-co-modal-colors .modal-content {
   padding: 40px;
}
.modal-dialog.gov-co-modal.gov-co-modal-colors .modal-footer {
   margin-top: 30px;
   justify-content: center;
}
.modal-dialog.gov-co-modal.gov-co-modal-confirmation .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-confirmation .gov-co-modal-type > span.govco-icon {
   color: #0943B5;
}
.modal-dialog.gov-co-modal.gov-co-modal-sucess .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-sucess .gov-co-modal-type > span.govco-icon {
   color: #158361;
}
.modal-dialog.gov-co-modal.gov-co-modal-warning .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-warning .gov-co-modal-type > span.govco-icon,
.modal-dialog.gov-co-modal.gov-co-modal-maintenance .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-maintenance .gov-co-modal-type > span.govco-icon {
   color: #FDAA29;
}
.modal-dialog.gov-co-modal.gov-co-modal-warning .modal-content,
.modal-dialog.gov-co-modal.gov-co-modal-maintenance .modal-content {
   position: relative;
}
.modal-dialog.gov-co-modal.gov-co-modal-warning .modal-content .close,
.modal-dialog.gov-co-modal.gov-co-modal-maintenance .modal-content .close {
   position: absolute;
   top: 10px;
   right: 10px;
}
.modal-dialog.gov-co-modal.gov-co-modal-error .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-error .gov-co-modal-type > span.govco-icon {
   color: #a80521;
}
.modal-dialog.gov-co-modal.gov-co-modal-exit .gov-co-modal-type > h2,
.modal-dialog.gov-co-modal.gov-co-modal-exit .gov-co-modal-type > span.govco-icon {
   color: #FDAA29;
}
.modal-dialog.gov-co-modal.gov-co-modal-exit .modal-body {
   display: flex;
   margin-bottom: 30px;
}
.modal-dialog.gov-co-modal.gov-co-modal-exit .modal-body .gov-co-modal-message {
   margin: initial;
   display: flex;
   flex-wrap: wrap;
   align-content: center;
}
.modal-dialog.gov-co-modal.modal-table {
   z-index: 1001;
   align-content: flex-start;
}
.modal-dialog.gov-co-modal.modal-table .modal-content {
   position: relative;
   max-width: initial;
   padding: 0;
   width: 60%;
   margin-top: 15%;
   border-radius: 20px;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body {
   text-align: left;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-type {
   display: flex;
   flex-wrap: wrap;
   -moz-column-gap: 30px;
   column-gap: 30px;
   align-items: center;
   padding: 30px 40px;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-type h2 {
   padding: 0;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-message {
   border-radius: 0 0 20px 20px;
   margin: 0;
   padding: 30px 40px;
   background-color: #f2f2f2;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-message .title-cell-modal {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 600;
   color: #4b4b4b;
   line-height: 1.5 !important;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-message p {
   padding-left: 15px;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-message .state-success {
   color: #158361;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .modal-body .gov-co-modal-message .state-success span {
   width: 200px;
   background-color: #15836133;
   color: #158361;
   padding: 5px 20px;
   text-decoration: none;
}
.modal-dialog.gov-co-modal.modal-table .modal-content .close {
   position: absolute;
   right: 20px;
   top: 20px;
   z-index: 3;
}
.pager-govco .pagination .page-item .page-link {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem;
   font-weight: 400;
   color: #4672C8;
   border: none;
   border-radius: 0.3rem;
   padding: 0.66rem 0.933rem;
   margin: 0;
}
.pager-govco .pagination .page-item .page-link.hover,
.pager-govco .pagination .page-item .page-link:hover {
   background-color: #e6effd;
}
.pager-govco .pagination .page-item .page-link.active {
   color: #ffffff;
   background-color: #0943B5;
}
.pager-govco .pagination .page-item .page-link.disabled,
.pager-govco .pagination .page-item .page-link:disabled {
   pointer-events: none;
   color: #bababa;
}
.pager-govco .pagination .page-item .page-link.link-points {
   padding: 0.66rem;
}
.pager-govco .pagination .page-item .page-link.link-points:hover {
   background-color: initial;
}
.pager-govco .pagination .page-item:last-of-type > .page-link,
.pager-govco .pagination .page-item:first-of-type > .page-link {
   color: #0943B5;
   text-decoration: underline;
}
.pager-govco .pagination .page-item:last-of-type > .page-link:hover,
.pager-govco .pagination .page-item:first-of-type > .page-link:hover {
   background-color: transparent;
   color: #4672C8;
}
.pager-govco .pagination .page-item:last-of-type > .page-link.disabled,
.pager-govco .pagination .page-item:last-of-type > .page-link:disabled,
.pager-govco .pagination .page-item:first-of-type > .page-link.disabled,
.pager-govco .pagination .page-item:first-of-type > .page-link:disabled {
   pointer-events: none;
   color: #bababa;
}
.carrousel-gov-co-pagination {
   width: 100%;
   height: 100%;
}
.carousel .gov-co-indicators > li {
   width: 12px;
   height: 12px;
   border-radius: 100%;
   background-color: #bababa;
}
.carousel .gov-co-indicators > li.active {
   background-color: #3772ff;
}
.gov-co-tramite-card {
   border: 2px solid #e6effd;
   border-radius: 10px;
   width: 223px;
}
.gov-co-tramite-card .card-header {
   padding: 20px 0;
   border-radius: 10px 10px 0 0;
   background-color: #ffffff;
   border-bottom: 2px solid #4672C8;
   color: #4672C8;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 700;
}
.gov-co-tramite-card .card-body {
   padding: 20px 30px;
   border-radius: 0 0 10px 10px;
   background-color: #ffffff;
}
.gov-co-tramite-card .card-body .card-text {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #4b4b4b;
}
.gov-co-tramite-card.hover .card-header,
.gov-co-tramite-card:hover .card-header {
   background-color: #e6effd;
}
.gov-co-tramite-card.active .card-header,
.gov-co-tramite-card:active .card-header {
   background-color: #4672C8;
   color: #ffffff;
}
.gov-co-tramite-card.disabled,
.gov-co-tramite-card:disabled {
   pointer-events: none;
}
.gov-co-tramite-card.disabled .card-header,
.gov-co-tramite-card:disabled .card-header {
   background-color: #bababa;
   color: #ffffff;
}
.gov-co-selects {
   box-shadow: 0px 4px 0px #4672C824;
   border: 1px solid #0943B5;
   display: flex;
   flex-wrap: nowrap;
   align-items: center;
   padding: 12px 40px 12px 20px;
   width: 255px;
   color: #4b4b4b;
}
.gov-co-selects span {
   margin-right: 10px;
}
.gov-co-selects p {
   margin-bottom: 0;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 1rem;
}
.gov-co-breadcrumb {
   background-color: transparent;
   flex-wrap: nowrap;
}
.gov-co-breadcrumb .breadcrumb-item {
   max-width: 265px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}
.gov-co-breadcrumb .breadcrumb-item::before {
   content: '>';
   color: #bababa;
}
.gov-co-breadcrumb .breadcrumb-item:first-of-type::before {
   content: none;
}
.gov-co-breadcrumb .breadcrumb-item a {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #4672C8;
}
.gov-co-breadcrumb .breadcrumb-item.active {
   padding-top: 4px;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 500;
   font-size: 0.875rem;
   color: #4672C8;
}
.gov-co-label {
   display: flex;
   flex-flow: row nowrap;
   justify-content: space-between;
   align-items: center;
   padding: 1px 8px;
   border-radius: 30px;
   background-color: #ffffff;
   border: 2px solid #f2f2f2;
}
.gov-co-label label {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 500;
   font-size: 0.875rem;
   color: #4b4b4b;
   white-space: nowrap;
   margin: initial;
}
.gov-co-label span {
   font-size: 1.125rem;
   color: #0943B5;
   margin-left: 20px;
   cursor: pointer;
}
.gov-co-label.gov-co-negative {
   background-color: #e6effd;
   border: 2px solid #e6effd;
}
.gov-co-label.gov-co-negative span,
.gov-co-label.gov-co-negative label {
   color: #4672C8;
}
.gov-co-label.gov-co-active {
   background-color: #4672C8;
   border: 2px solid #4672C8;
}
.gov-co-label.gov-co-active span,
.gov-co-label.gov-co-active label {
   color: #ffffff;
}
.searchbar-sedes,
.searchbar {
   position: relative;
   margin-bottom: auto;
   margin-top: auto;
   height: 40px;
   border-radius: 30px;
   padding: 5px;
   padding-left: 20px;
   background: #ffffff;
   width: 100%;
   border: 1px solid #bababa;
}
.searchbar-sedes .search_input,
.searchbar .search_input {
   width: calc(100% - 40px);
   border: 0;
   outline: 0;
   background: none;
   line-height: 30px;
   transition: width 0.4s linear;
   padding: 0 10px;
   transition: width 0.4s linear;
}
.searchbar-sedes .search_icon,
.searchbar .search_icon {
   height: 30px;
   width: 40px;
   float: right;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   color: white;
   text-decoration: none;
   color: #0943B5;
}
.searchbar-sedes .govco-icon-close,
.searchbar .govco-icon-close {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 5px;
   transform: rotate(45deg);
   line-height: 1.375em !important;
   color: #0943B5;
   display: none;
}
.searchbar-sedes:focus-within .govco-icon-close,
.searchbar:focus-within .govco-icon-close {
   display: inline;
}
.searchbar-sedes {
   border-radius: 5px;
}
.searchbar-sedes.mu-search,
.searchbar-sedes:focus-within {
   border: 1px solid #4672C8;
   box-shadow: 0px 4px 0px #0943B524;
}
.searchbar-table {
   margin-bottom: auto;
   margin-top: auto;
   height: 40px;
   border-radius: 30px;
   padding: 5px;
   background: #ffffff;
   width: 100%;
   border: 1px solid #bababa;
}
.searchbar-table .search_input {
   width: calc(100% - 40px);
   border: 0;
   outline: 0;
   background: none;
   line-height: 30px;
   transition: width 0.4s linear;
   padding: 0 10px;
   transition: width 0.4s linear;
}
.searchbar-table .search_icon {
   height: 30px;
   width: 40px;
   float: right;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   color: white;
   text-decoration: none;
   color: #0943B5;
}
td a,
td button:not(.govco-icon) {
   font-family: Verdana, Geneva, sans-serif !important;
   font-size: 1rem;
}
.govco-table-basic table thead th {
   border-top: 0px solid transparent !important;
   border-bottom: 0px solid transparent !important;
   font-weight: 500 !important;
}
.govco-table-basic table thead th:not(:last-child) {
   border-top: 0px solid transparent !important;
   border-right: 2px solid #4672C8;
}
.govco-table-basic table tbody tr {
   background-color: transparent !important;
}
.govco-table-basic table tbody tr td {
   border-top: 2px solid #4672C8 !important;
}
.govco-table-basic table tbody tr td:not(:last-child) {
   border-right: 2px solid #4672C8;
}
.govco-table-simple table thead,
.govco-table-detail table thead,
.govco-table-filter table thead,
.govco-table-simple table tbody tr,
.govco-table-detail table tbody tr,
.govco-table-filter table tbody tr {
   display: table;
   width: 100%;
   table-layout: fixed;
   background-color: transparent !important;
}
.govco-table-simple table thead th,
.govco-table-detail table thead th,
.govco-table-filter table thead th {
   border: 0px !important;
   vertical-align: middle;
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.75rem !important;
   text-align: center;
}
.govco-table-simple table thead tr,
.govco-table-detail table thead tr,
.govco-table-filter table thead tr {
   display: table-header-group;
   width: 100%;
   table-layout: fixed;
}
.govco-table-simple table tbody,
.govco-table-detail table tbody,
.govco-table-filter table tbody {
   display: block;
   overflow: auto;
   max-height: 500px;
   width: 100%;
}
.govco-table-simple table tbody tr:not(:first-child) td,
.govco-table-detail table tbody tr:not(:first-child) td,
.govco-table-filter table tbody tr:not(:first-child) td {
   border-top: 1px solid #e5eefb !important;
}
.govco-table-simple table td,
.govco-table-detail table td,
.govco-table-filter table td {
   font-weight: 500;
   border-top: 0px;
   text-align: center;
}
.govco-table-simple table td div,
.govco-table-detail table td div,
.govco-table-filter table td div {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem !important;
}
.govco-table-simple table td .title,
.govco-table-detail table td .title,
.govco-table-filter table td .title {
   font-weight: 600 !important;
}
.table-detail {
   display: block !important;
}
.table-detail .title-result {
   font-size: 1.25rem !important;
   font-weight: 700 !important;
}
.table-detail .form-search {
   margin-left: 30px;
   border: 0.5px transparent solid !important;
   padding: 0 !important;
   border-radius: 20px;
}
.table-detail .form-search {
   background-color: #f6f8f9 !important;
}
.table-detail .form-search input {
   border: none !important;
   background-color: transparent !important;
   margin-left: 0px !important;
   padding: 0;
   padding-left: 15px !important;
   color: #4672C8 !important;
}
.table-detail .form-search input::-webkit-input-placeholder {
   color: rgba(0, 72, 132, 0.5) !important;
}
.table-detail .form-search input::-moz-placeholder {
   color: rgba(0, 72, 132, 0.5) !important;
}
.table-detail .form-search input::-ms-input-placeholder {
   color: rgba(0, 72, 132, 0.5) !important;
}
.table-detail .form-search input::placeholder {
   color: rgba(0, 72, 132, 0.5) !important;
}
.table-detail .form-search .input-group-append {
   line-height: 1.3 !important;
}
.table-detail .form-search .govco-icon {
   color: #4672C8;
   font-size: 25px;
   margin: auto;
   padding-right: 5px;
}
.govco-table-detail thead tr {
   background-color: #4672C8 !important;
}
.govco-table-detail thead th {
   color: white;
   font-size: 1rem !important;
}
.govco-table-detail td {
   font-weight: 400 !important;
}
.govco-table-detail td div,
.govco-table-detail td a {
   font-family: Verdana, Geneva, sans-serif !important;
   font-size: 1rem !important;
}
.govco-table-detail td a {
   font-weight: 600;
}
.govco-table-filter table,
.govco-table-filter tr td {
   border: 1px solid transparent !important;
}
.govco-table-filter thead {
   width: calc(100%) !important;
}
.govco-table-filter thead th div {
   font-size: 0.75rem !important;
}
.govco-table-filter thead,
.govco-table-filter tbody tr {
   display: table !important;
   table-layout: fixed !important;
}
.govco-table-filter tbody {
   display: block !important;
   overflow-y: scroll !important;
}
.govco-table-filter .bootstrap-select .form-control {
   width: auto !important;
}
.table-simple-headblue-govco .table {
   border-bottom: none;
}
.table-simple-headblue-govco .table thead {
   background-color: #e6effd;
   color: #4672C8;
   border: 0px;
}
.table-simple-headblue-govco .table thead th {
   border: 0px;
}
.table-simple-headblue-govco .table thead .table-head-overflow::after {
   content: '';
   top: 0px;
   left: 0px;
   position: absolute;
   display: block;
   background-color: #4672C8;
   width: 1rem;
   height: 48px;
}
.table-simple-headblue-govco .table thead .table-head-overflow::before {
   content: '';
   top: 0px;
   right: 0px;
   position: absolute;
   display: block;
   background-color: #4672C8;
   width: 1rem;
   height: 48px;
}
.table-simple-headblue-govco .table thead th {
   color: #4672C8;
   font-weight: 600;
}
.table-simple-headblue-govco .table thead th .checkbox-govco {
   text-align: center;
   margin: initial;
}
.table-simple-headblue-govco .table td.state-success {
   color: #158361;
}
.table-simple-headblue-govco .table td.state-success span {
   width: 200px;
   background-color: #15836133;
   color: #158361;
   padding: 5px 20px;
   text-decoration: none;
}
.table-simple-headblue-govco .table td.state-inactive {
   color: #bababa;
}
.table-simple-headblue-govco .table td.state-inactive span {
   width: 200px;
   background-color: #bababa;
   color: #4b4b4b;
   padding: 5px 20px;
   text-decoration: none;
}
.table-simple-headblue-govco .table td.state-success-wbullet {
   color: #158361;
}
.table-simple-headblue-govco .table td.state-success-wbullet::before {
   content: '☻ ';
   color: #158361;
}
.table-simple-headblue-govco .table td .checkbox-govco {
   text-align: center;
   margin: initial;
}
.table-simple-headblue-govco .table td.table-actions {
   text-transform: uppercase;
   display: flex;
   flex-wrap: wrap;
   max-width: 400px;
}
.table-simple-headblue-govco .table td.table-actions > a {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.938rem;
   font-weight: 500;
   color: #0943B5;
   line-height: 1.5 !important;
   text-decoration: underline;
}
.table-simple-headblue-govco .table td.table-actions > a::after {
   content: '/';
   margin: 0 10px;
   color: #4b4b4b;
   text-decoration: initial;
}
.table-simple-headblue-govco .table td.table-actions > a:last-of-type::after {
   content: '';
   margin: initial;
}
.table-simple-headblue-govco .table td.type-number {
   text-align: right;
}
.bd-content > table {
   width: 100%;
   max-width: 100%;
   margin-bottom: 1rem;
}
.bd-content > table > thead > tr > th,
.bd-content > table > thead > tr > td,
.bd-content > table > tbody > tr > th,
.bd-content > table > tbody > tr > td,
.bd-content > table > tfoot > tr > th,
.bd-content > table > tfoot > tr > td {
   padding: 0.75rem;
   vertical-align: top;
   border: 1px solid #dee2e6;
}
.bd-content > table > thead > tr > th > p:last-child,
.bd-content > table > thead > tr > td > p:last-child,
.bd-content > table > tbody > tr > th > p:last-child,
.bd-content > table > tbody > tr > td > p:last-child,
.bd-content > table > tfoot > tr > th > p:last-child,
.bd-content > table > tfoot > tr > td > p:last-child {
   margin-bottom: 0;
}
.bd-content > table td:first-child > code {
   white-space: nowrap;
}
.bd-content > h2:not(:first-child) {
   margin-top: 3rem;
}
.bd-content > h3 {
   margin-top: 1.5rem;
}
.bd-content > ul li,
.bd-content > ol li {
   margin-bottom: 0.25rem;
}
.list-unstyled::before {
   display: table;
}
.list-unstyled li {
   height: 120px;
   width: 140px;
   padding: 8px;
   padding-top: 20px !important;
   cursor: pointer;
   display: inline-block;
   text-align: center;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.list-unstyled .icon-item {
   text-align: center;
}
.list-unstyled li a {
   display: block;
   border-radius: 10px;
}
.list-unstyled li {
   border: 1px solid transparent;
   padding: 2px;
}
.list-unstyled li:hover {
   border: 1px solid #4672C8 !important;
   border-radius: 15px;
}
.list-unstyled li a:hover {
   text-decoration: none !important;
}
table.dataTable.display tbody tr {
   background-color: #fff !important;
}
table.dataTable.display tbody tr td {
   background: #fff !important;
}
.table-pagination-govco {
   border: 2px solid #e6effd;
}
.table-govco {
   float: left;
}
.table-govco label {
   position: relative;
   font-size: 1.125rem;
   font-weight: 700;
}
.dataTables_filter {
   float: initial !important;
}
.dataTables_filter label {
   width: 100%;
   margin: initial;
   height: 40px;
   border-radius: 20px;
   border: 1px solid #bababa;
   position: relative;
}
.dataTables_filter label:active,
.dataTables_filter label:focus-within {
   box-shadow: 0 0 5px #0943B5;
}
.dataTables_filter label::after {
   content: '\e95b';
   font-family: 'Govco Icon';
   font-size: 2rem;
   line-height: 1.125em;
   color: #0943B5;
   position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
   right: 0;
   width: 40px;
   height: 40px;
   border-radius: 100%;
   font-weight: lighter;
   text-align: center;
   padding-top: 2px;
}
.dataTables_wrapper .dataTables_filter input {
   width: 100%;
   margin-left: 0.5em;
   padding: 5px 45px 5px 15px;
   border: none;
   border-radius: 20px;
   outline: none;
}
.content-ctrls-table {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-end;
   -moz-column-gap: 30px;
   column-gap: 30px;
   row-gap: 10px;
   padding: 10px 32px;
   margin-bottom: 20px;
}
.content-ctrls-table .form-group {
   min-width: 130px;
   flex: 1;
   margin-bottom: 0;
}
.content-ctrls-table .input-search {
   height: 100%;
   width: 100%;
   background-color: transparent;
   padding-left: 10px;
   padding-right: 35px;
   border: none;
}
.content-ctrls-table .input-search:active,
.content-ctrls-table .input-search:focus {
   border: none;
   outline: none;
}
.content-ctrls-table .dataTables_filter {
   flex: 2;
   min-width: 320px;
}
.content-ctrls-table .content-result-page {
   flex: 1;
   display: flex;
   justify-content: flex-end;
}
.content-ctrls-table .content-result-page .head-table-dropdown {
   max-width: 130px;
}
.content-ctrls-table .content-result-page .dataTables_length {
   bottom: 120%;
   top: 30px;
   right: 32px;
   min-width: 175px;
   position: absolute;
}
.content-ctrls-table .content-result-page.result-inline {
   display: flex;
   flex-wrap: nowrap;
   align-items: flex-end;
   justify-content: flex-end;
   -moz-column-gap: 10px;
   column-gap: 10px;
}
.content-ctrls-table .content-result-page.result-inline .dataTables_length {
   height: 40px;
   padding: 5px 0;
   top: initial;
   right: initial;
   position: relative;
   text-align: end;
}
@media (min-width: 1200px) {
   .content-ctrls-table.subtable {
      width: 50%;
   }
}
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc {
   position: relative;
}
.title-table {
   padding: 24px 32px 0;
}
table.dataTable thead tr th {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 500;
   color: #4672C8;
   line-height: 1.5 !important;
   background-color: #e6effd;
}
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
   background-image: initial;
   position: relative;
   padding-left: 30px;
}
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting::before {
   content: '\e83B';
   font-family: 'Govco Icon';
   font-size: 1.5rem;
   font-weight: 500;
   color: #4672C8;
   line-height: 1.5 !important;
   opacity: 0.5;
   position: absolute;
   top: 0;
   left: -10px;
   bottom: 0;
   width: 45px;
   height: 45px;
   padding-top: 4px;
   margin: auto;
   text-align: center;
   border-radius: 100%;
   transform: rotate(90deg);
   transition: all 500ms ease-in-out;
   border: 1px solid transparent;
}
table.dataTable thead .sorting_asc:hover::before,
table.dataTable thead .sorting_desc:hover::before,
table.dataTable thead .sorting:hover::before {
   opacity: 1;
}
table.dataTable thead .sorting_asc::before {
   opacity: 1;
}
table.dataTable thead .sorting_desc::before {
   opacity: 1;
   transform: rotate(270deg);
}
table.dataTable thead .table-check {
   cursor: inherit;
   padding: 10px 0 10px 32px;
   text-align: center;
}
table.dataTable thead .table-check::before {
   content: none;
}
table.dataTable thead .table-check > .checkbox-govco {
   margin-bottom: 0;
}
table.dataTable thead .table-state {
   position: relative;
   padding: 10px 10px;
   text-align: center;
   cursor: pointer;
}
table.dataTable thead .table-state .dropdown .dropdown-toggle::after {
   transition: all 500ms ease-in-out;
   border-top: 0.5em solid;
   vertical-align: inherit;
   opacity: 0.5;
}
table.dataTable thead .table-state .dropdown.show .dropdown-toggle::after {
   transform: rotate(-180deg);
}
table.dataTable thead .table-state .dropdown:hover .dropdown-toggle::after {
   opacity: 1;
}
table.dataTable thead .table-state .dropdown-menu {
   background-color: #ffffff;
   width: 180px;
   padding: 3px 0;
   border-radius: 5px;
   box-shadow: 0px 3px 6px #00000029;
}
table.dataTable thead .table-state .dropdown-menu a {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 400;
   color: #4672C8;
   line-height: 1.5 !important;
   padding: 5px 10px;
   width: 100%;
   text-align: left;
}
table.dataTable thead .table-state .dropdown-menu a:hover {
   text-decoration: none;
   background-color: #e6effd;
}
table.dataTable thead .table-state .dropdown-menu a.active {
   background-color: #4672C8;
   color: #ffffff;
   font-weight: 600;
}
table.dataTable tbody tr td {
   max-width: 530px;
   vertical-align: top;
   border-bottom: 2px solid #e6effd;
}
table.dataTable tbody tr.shown + tr > td {
   background-color: #f6f8f9;
}
table.dataTable tbody tr.shown + tr > td .title-collapsed {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1rem;
   font-weight: 700;
   color: #4672C8;
   line-height: 1.5 !important;
}
table.dataTable tbody tr.shown + tr > td .description-collapsed {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 400;
   color: #4b4b4b;
   line-height: 1.5 !important;
}
table.dataTable tbody tr.shown + tr > td .card-header {
   background-color: transparent;
   border: none;
}
table.dataTable tbody tr.shown + tr > td .card-header h2 {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.875rem;
   font-weight: 700;
   color: #4672C8;
   line-height: 1.5 !important;
   display: flex;
}
table.dataTable tbody tr.shown + tr > td .card-header h2 .table-collapse-mod {
   flex: 1;
}
table.dataTable tbody tr.shown + tr > td .card-header h2 .table-collapse-desc {
   flex: 3;
}
table.dataTable tbody tr.shown + tr > td .card {
   border: none;
   border-top: 2px solid #e6effd;
}
table.dataTable tbody tr.shown + tr > td .card .card-header a {
   display: flex;
   position: relative;
}
table.dataTable tbody tr.shown + tr > td .card .card-header a .headline-m-govco {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 500;
   color: #4b4b4b;
   line-height: 1.5 !important;
}
table.dataTable tbody tr.shown + tr > td .card .card-header a span.govco-icon {
   min-width: initial;
   position: absolute;
   right: 0;
   text-align: end;
}
table.dataTable tbody tr.shown + tr > td .card .card-header a .table-collapse-mod {
   flex: 1;
}
table.dataTable tbody tr.shown + tr > td .card .card-header a .table-collapse-desc {
   flex: 3;
}
table.dataTable tbody tr.shown + tr > td .card-body {
   padding: 1.25rem 0;
   display: flex;
   flex-wrap: wrap;
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem;
   font-weight: 500;
   color: #4b4b4b;
   line-height: 1.5 !important;
}
table.dataTable tbody tr.shown + tr > td .card-body .table-collapse-mod {
   flex: 1;
   display: flex;
   flex-wrap: wrap;
}
table.dataTable tbody tr.shown + tr > td .card-body .table-collapse-desc {
   flex: 3;
   display: flex;
   flex-wrap: wrap;
}
table.dataTable tbody tr.shown + tr > td .card-body span {
   background-color: #ffffff;
   border-radius: 16px;
   border: 2px solid #f2f2f2;
   padding: 5px 15px;
   margin-right: 3px;
}
table.dataTable tbody .table-check {
   cursor: inherit;
   padding: 10px 0 10px 32px;
   text-align: center;
}
table.dataTable tbody .table-check::before {
   content: '';
}
table.dataTable tbody .table-check > .checkbox-govco {
   margin-bottom: 0;
}
table.dataTable tbody .state-inactive,
table.dataTable tbody .state-success {
   padding: 14px 0;
   text-align: center;
}
table.dataTable tbody .state-inactive span,
table.dataTable tbody .state-success span {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: center;
}
table.dataTable tbody .state-success span {
   background-color: #15836133;
   color: #158361;
}
table.dataTable tbody .state-inactive span {
   background-color: #bababa;
   color: #4b4b4b;
}
table.dataTable tbody .type-number {
   text-align: end;
}
table.dataTable tbody .table-actions {
   text-transform: uppercase;
}
table.dataTable tbody .table-actions .links-actions {
   display: flex;
   flex-wrap: wrap;
   max-width: 300px;
}
table.dataTable tbody .table-actions .links-actions .action-table {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.938rem;
   font-weight: 500;
   color: #0943B5;
   line-height: 1.5 !important;
   text-decoration: underline;
}
table.dataTable tbody .table-actions .links-actions .action-table::after {
   content: '/';
   margin: 0 10px;
   color: #4b4b4b;
   text-decoration: initial;
}
table.dataTable tbody .table-actions .links-actions .action-table:last-of-type::after {
   content: '';
   margin: initial;
}
table.dataTable tbody .table-actions .container-fluid {
   display: none;
}
table.dataTable tbody .table-actions.subtable {
   width: 5%;
}
table.dataTable tbody .table-actions.subtable span {
   font-size: 1rem;
   color: #4672C8;
}
.table-pagination-govco {
   position: relative;
}
.table-pagination-govco .content-footer-table {
   padding: 24px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
   row-gap: 36px;
}
.table-pagination-govco .content-footer-table .footer-note {
   border-radius: 10px;
   border: 2px solid #e6effd;
   padding: 10px 20px;
}
.table-pagination-govco .content-footer-table .footer-note p {
   margin-bottom: 0;
}
.table-pagination-govco .content-footer-table.subtable {
   align-items: flex-start;
   align-content: flex-start;
}
.table-pagination-govco.subtable > .content-subtable {
   max-height: 590px;
   overflow: auto;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled svg {
   fill: #b6b6b6;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
   text-decoration: none;
   border: initial !important;
   font-size: 0.875rem !important;
   font-weight: 400;
   color: #4672C8 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
   background: #e6effd;
   color: #4672C8 !important;
   border-radius: 5px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
   background: #0943B5 !important;
   font-weight: 600;
   color: #ffffff !important;
   border-radius: 5px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
   color: #ffffff !important;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
   font-size: 1.4375rem !important;
}
.dataTables_wrapper .dataTables_paginate {
   text-align: center;
}
.dataTables_wrapper .dataTables_length {
   float: initial !important;
}
.dataTables_wrapper .dataTables_length label {
   padding: 0px;
   margin: 0px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #0943B5 !important;
   text-decoration: underline !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
   color: #bababa !important;
   pointer-events: none;
}
table.dataTable thead th,
table.dataTable.no-footer {
   border: initial;
}
.scroll-dark-govco {
   overflow-y: scroll;
   scrollbar-face-color: #0943B5 !important;
   scrollbar-track-color: #ffffff !important;
   scrollbar-arrow-color: #ffffff !important;
   scrollbar-highlight-color: #0943B5 !important;
   scrollbar-3dlight-color: #0943B5 !important;
   scrollbar-darkshadow-color: #0943B5 !important;
   scrollbar-color: #0943B5 #ffffff !important;
   scrollbar-width: thin !important;
}
.scroll-dark-govco::-webkit-scrollbar {
   width: 0.5em;
   height: 0.5em;
}
.scroll-dark-govco::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #0943B5;
}
.scroll-dark-govco::-webkit-scrollbar-track {
   border-radius: 10px;
   background: #ffffff;
}
.scroll-dark-govco::-webkit-scrollbar-thumb:hover {
   background: #0943B5;
}
.scroll-light-govco {
   scrollbar-face-color: #4672C8 !important;
   scrollbar-track-color: #e6effd !important;
   scrollbar-arrow-color: #e6effd !important;
   scrollbar-highlight-color: #e6effd !important;
   scrollbar-3dlight-color: #e6effd !important;
   scrollbar-darkshadow-color: #e6effd !important;
   scrollbar-color: #4672C8 #e6effd !important;
   scrollbar-width: thin !important;
   overflow-y: scroll;
}
.scroll-light-govco::-webkit-scrollbar {
   width: 0.5em;
   height: 0.5em;
}
.scroll-light-govco::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #4672C8;
}
.scroll-light-govco::-webkit-scrollbar-track {
   border-radius: 10px;
   background: #e6effd;
}
.scroll-light-govco::-webkit-scrollbar-thumb:hover {
   background: #4672C8;
}
.tooltip-inner {
   background-color: #f2f2f2 !important;
   opacity: 1 !important;
   font-size: 0.875rem;
   font-weight: 600;
   color: #4b4b4b !important;
   font-family: Verdana, Geneva, sans-serif !important;
}
.tooltip.bs-tooltip-right .arrow:before {
   border-right-color: #d6d6d6 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
   border-left-color: #d6d6d6 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
   border-bottom-color: #d6d6d6 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
   border-top-color: #d6d6d6 !important;
}
.navbar-govco {
   z-index: 99999 !important;
}
.navbar-govco .navbar-brand {
   padding: 10px !important;
}
.navbar-govco .navbar-brand:focus {
   border: 2px #fff dotted;
}
.image-icon div {
   margin: 0 auto;
   position: relative;
   height: 26px;
   width: 26px;
   background-image: url('/assets/images/spr-govco.png') !important;
   background-repeat: no-repeat;
}
.image-icon #contrast {
   background-position: 0px -180px;
}
.image-icon #less-size {
   background-position: 0px -206px;
}
.image-icon #more-size {
   background-position: 0px -232px;
}
.image-icon #relief {
   background-position: 0px -258px;
}
.goog-te-banner-frame,
#google_translate_element,
.goog-tooltip {
   display: none !important;
}
.goog-tooltip:hover {
   display: none !important;
}
.goog-text-highlight {
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}
.goog-te-banner-frame,
#google_translate_element,
.goog-tooltip {
   display: none !important;
}
.goog-tooltip:hover {
   display: none !important;
}
.goog-text-highlight {
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}
font {
   vertical-align: inherit !important;
   font-size: inherit !important;
   color: inherit !important;
   text-transform: inherit !important;
   display: inherit !important;
   text-align: inherit !important;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1.36rem;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a {
   color: #0b457f;
   font-weight: 600 !important;
}
.navbar-notifications {
   width: 100%;
   background-color: #FDAA29;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.5rem;
   padding: 0;
   flex-flow: column;
   z-index: 9 !important;
}
.form-search-bar {
   border-radius: 2rem;
   border: 1px solid #ffffff;
   box-shadow: 0px 0px 1px #0943B5;
   overflow: hidden;
   font-size: 0.5rem;
   margin-left: 1rem;
   line-height: 21px;
}
.form-search-bar.none-visibility {
   border: solid 0.5px transparent;
   transition: 1.5s;
}
.form-search-bar input.form-control {
   font-size: 0.75rem;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
   height: 25px;
}
.form-search-bar input.form-control:focus {
   color: black !important;
   box-shadow: none !important;
}
.form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.form-search-bar .govco-icon {
   color: white;
   font-size: 1.7rem;
   line-height: 1.1;
}
.form-search-bar .material-icons {
   color: white;
   font-size: 1.7rem;
   height: auto;
   width: auto;
   text-align: end;
}
.form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px;
   margin: 0px;
}
.navbar-container .container {
   padding: 0px;
   margin: 0px;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-left: auto;
}
.navbar-container .navbar-first-menu .navbar-nav a:hover,
.navbar-container .navbar-first-menu .navbar-first-menu a:hover,
.navbar-container .navbar-first-menu .navbar-nav a:focus,
.navbar-container .navbar-first-menu .navbar-first-menu a:focus {
   color: white;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white;
   color: black;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #3772ff !important;
}
.form-search-bar-active ~ .search-btn > .icon-search,
.form-search-bar input.form-control:focus ~ .search-btn > .icon-search {
   color: #3772ff !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: auto;
   background-color: white;
   background-image: none;
}
.page-content-hidden {
   height: 6rem;
   background-color: white;
   background-image: none;
}
.none-visibility {
   color: transparent;
}
@media (min-width: 992px) {
   .navbar-collapsed {
      display: none !important;
   }
}
@media (max-width: 992px) {
   .navbar-expanded {
      display: none !important;
   }
   .navbar-collapsed {
      display: initial !important;
   }
   .navbar-collapsed .navbar-collapse .nav-item {
      list-style-type: none;
   }
   .navbar-collapsed .navbar-collapse .nav-items {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in;
      height: auto;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.5s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching .none-visibility {
      display: none !important;
   }
   .navbar-collapsed .navbar-collapse .nav-items .nav-item-menu {
      display: block;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility {
      transition: height 0.5s, opacity 0.5s ease-in !important;
      position: relative;
      margin-top: 8vh;
      width: 100%;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility.none-visibility {
      opacity: 0;
      transition: height 0.5s, opacity 0.5s ease-out !important;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility {
      color: white;
      display: flex;
      align-items: center;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility label.item {
      font-size: 0.75rem;
      font-weight: 500;
      color: white;
      text-decoration: none;
      margin-bottom: 0;
      padding-left: 0.75rem;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.3s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching .nav-item-most-used {
      display: block !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .none-visibility {
      display: none;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .separator {
      margin: 1rem 2rem !important;
   }
   .navbar-collapsed .navbar-translate {
      background-color: #0943B5 !important;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      height: auto;
      max-height: 300px;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler {
      display: initial;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      padding: 0.75rem;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler .navbar-toggler-icon {
      color: white;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler:hover,
   .navbar-collapsed .navbar-translate .navbar-toggler:active,
   .navbar-collapsed .navbar-translate .navbar-toggler:focus {
      background-color: transparent;
      box-shadow: none;
   }
   .navbar-collapsed .navbar-translate .navbar-brand {
      margin: 0 !important;
      background-color: transparent !important;
   }
   .navbar-collapsed .navbar-translate .item-text {
      line-height: 1.5;
   }
   .navbar-collapsed .navbar-translate .navbar-nav-mobile {
      margin-bottom: 18px;
   }
   .navbar-collapsed .navbar-translate.focus {
      max-height: 0;
   }
   .navbar-collapsed .input-group {
      background-color: rgba(255, 255, 255, 0.3);
      transition: width 0.5s;
   }
   .navbar-collapsed .input-group .form-control {
      height: auto !important;
   }
   .navbar-collapsed .search-items .container-search {
      margin: 1rem 2rem;
      width: 100%;
   }
   .navbar-collapsed .search-items .btn-close-search a {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      font-size: 0.75rem;
      margin-top: 15px;
      transition: 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container {
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-radius: 2rem;
      border: 2px solid transparent;
      overflow: hidden;
      font-size: 0.5rem;
      line-height: 21px;
      transition: width 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container.focus {
      width: 70% !important;
      display: inline-flex;
      margin-right: 0;
      transition: 1s ease-out;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus {
      background-color: #fff !important;
      color: black !important;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn {
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn .govco-icon,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon {
      color: #3772ff;
      font-size: 2rem !important;
   }
   .navbar-collapsed .search-mobile-container.focus ~ .btn-close-search {
      display: inline-flex;
      margin: 0 auto;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile {
      border: none;
      background-color: rgba(255, 255, 255, 0.3) !important;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-webkit-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-moz-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-ms-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn {
      background-color: rgba(255, 255, 255, 0.3) !important;
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .btn-search {
      background: transparent;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .govco-icon {
      height: auto;
      color: white;
      font-size: 2rem !important;
   }
   .navbar-collapsed s .title {
      color: white;
      font-size: 1rem;
      text-transform: initial;
   }
   .navbar-collapse {
      position: fixed;
      display: block;
      top: 0px;
      height: 100vh;
      width: 100%;
      right: 0;
      margin-right: 0 !important;
      z-index: 1032;
      visibility: visible;
      background-color: #0943B5;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      max-height: none !important;
   }
   .navbar-collapse.close-menu-govco {
      transform: translate3d(100%, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .navbar-collapse.open-menu-govco {
      transform: translate3d(0, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .nav-item-mobile {
      transition: max-height 0.5s ease-out;
   }
   .nav-item-mobile:not(:first-of-type) {
      width: 100% !important;
   }
   .nav-item-mobile:not(:first-of-type) .nav-link {
      height: auto;
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1.5rem auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile.active,
   .nav-item-mobile:hover,
   .nav-item-mobile:active,
   .nav-item-mobile:focus {
      background-color: #FDAA29 !important;
      box-shadow: none !important;
      border-radius: 0px !important;
   }
   .nav-item-mobile.active + .nav-item-mobile .nav-link,
   .nav-item-mobile:hover + .nav-item-mobile .nav-link,
   .nav-item-mobile:active + .nav-item-mobile .nav-link,
   .nav-item-mobile:focus + .nav-item-mobile .nav-link {
      border-top: 1.5px solid transparent !important;
   }
   .nav-item-mobile .nav-link {
      margin: auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile .nav-link.search {
      margin: auto 2rem !important;
   }
   .nav-item-mobile .nav-link span {
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
   }
   .separator {
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1rem;
   }
   .separator .search {
      margin: auto 2rem !important;
   }
}
@media (max-height: 620px) {
   .navbar-collapse.flex-column.ms-0.collapse.show.navbar-mobile.open-menu-govco {
      overflow-y: auto;
      overflow-x: auto;
   }
}
@media (max-width: 767.98px) {
   .page__footer {
      padding-bottom: 40px;
      padding-top: 40px;
   }
   .page__footer .container .footer-container .title-footer {
      margin-top: 0px;
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif, sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container .submenu-footer {
      margin-top: 0px;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1.5rem 1rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer {
      width: 100%;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
      margin-right: 15px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link {
      text-align: left;
      display: inline-flex;
      width: 100%;
      vertical-align: middle;
      align-items: center;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0px;
      margin-left: 10px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title {
      margin-top: 1rem;
      justify-content: left;
      padding-left: 20px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title h2 {
      font-size: 1.6rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p {
      text-align: left;
      font-size: 1rem !important;
      display: contents;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p::first-letter {
      text-transform: capitalize !important;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link {
      text-transform: none !important;
      white-space: nowrap;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p br {
      display: block;
   }
}
.all #header-component .nav-secondary {
   background-color: #000 !important;
   box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.5);
}
.all #header-component .navbar-notifications {
   color: #4672C8 !important;
   background-color: #ddfb00;
   box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.5);
}
.all #header-component .navbar-notifications div {
   color: #4672C8 !important;
   background-color: #ddfb00;
}
.all #header-component .navbar-notifications div a {
   color: #4672C8 !important;
}
.all #header-component .navbar-notifications div a strong {
   color: #4672C8 !important;
}
.all #header-component .navbar-govco {
   background: black;
}
.all #header-component .navbar-govco .nav-secondary.show-transition {
   background-color: black;
}
.all #header-component .container ul.navbar-nav.nav-items.nav-items-desktop li.active,
.all #header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li.active,
.navbar-container .all #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li.active {
   background-color: #ddfb00 !important;
   color: #4672C8 !important;
}
@media (max-width: 992px) {
   .all #header-component .navbar-collapsed .navbar-translate {
      background-color: #000 !important;
   }
   .all #header-component .nav-item-mobile:hover,
   .all #header-component .nav-item-mobile.active {
      background-color: #ddfb00 !important;
   }
   .all #header-component i {
      color: #ddfb00 !important;
      background-color: transparent !important;
   }
   .all #header-component .nav-item.nav-accessibility .option-accessibility {
      background-color: transparent;
   }
}
#header-component .alert.alert-warning,
#header-component .navbar-container,
#header-component .container {
   padding: 0 5px;
   max-width: 1130px !important;
}
@media (min-width: 1200px) {
   #header-component .alert.alert-warning .container,
   #header-component .navbar-container .container,
   #header-component .container .container {
      max-width: 1130px !important;
   }
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu {
   width: 100%;
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu li,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li {
   text-align: center;
   font-size: 14px;
   font-family: NunitoSans, sans-serif;
   letter-spacing: 0.42px;
   color: #4672C8;
   text-transform: uppercase;
   flex-basis: 100%;
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu li a,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li a {
   padding: 10px;
   text-align: center;
   font-size: 14px;
   font-family: NunitoSans, sans-serif;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
#header-component .alert.alert-warning {
   padding: 0px;
}
#header-component .nav-secondary {
   background: #e6effd;
}
#header-component .nav-secondary .nav-item .nav-link {
   line-height: 20px;
   font-weight: 500 !important;
}
#header-component .navbar-govco .form-search-bar {
   border: 1px solid white;
}
#header-component .navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .container-notifications-header a {
   margin: 15px auto;
   width: 100%;
   display: inline-block;
   text-align: center;
   text-transform: inherit;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif;
   letter-spacing: 0;
}
#header-component .container-notifications-header a strong {
   font-weight: bold;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif;
   text-decoration: underline;
   display: inline-block;
   margin-left: 3px;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1.36rem;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a {
   color: #0b457f;
   font-weight: 600 !important;
}
.navbar-notifications {
   width: 100%;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.75rem;
   padding: 0;
   flex-flow: column;
}
.navbar-govco .form-search-bar {
   border-radius: 2rem;
   border: 1px solid #ffffff;
   box-shadow: 0px 0px 1px white;
   overflow: hidden;
   font-size: 0.75rem;
   margin-left: 1rem;
   line-height: 21px;
}
.navbar-govco .form-search-bar input.form-control {
   font-size: 1rem !important;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   width: 118px;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
}
.navbar-govco .form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 1s;
   -webkit-transition: all 1s;
}
.navbar-govco .form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 1s;
   -webkit-transition: all 1s;
}
.navbar-govco .form-search-bar .govco-icon {
   color: white;
   font-size: 1.55rem;
}
.navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px 6%;
   background-color: #0943B5;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0 0.5rem;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-top: 0.63rem;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white !important;
   color: black !important;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: 200px;
   background-color: white;
   background-image: none;
}
@media (max-width: 768px) {
   .navbar-notifications {
      display: none !important;
   }
}
.rotate-icon {
   transform: rotateZ(45deg);
   display: block;
}
span.govco-icon.govco-icon-plus.rotate-icon {
   font-size: 1.2rem;
}
span.govco-icon.govco-icon-plus.rotate-icon::before {
   font-size: 1.2rem;
   font-weight: bold;
   line-height: 1;
}
nav.navbar-govco {
   z-index: 99 !important;
}
.btn-round {
   border-radius: 30px !important;
   margin: 0 !important;
}
.btn-sesion {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   font-weight: bold;
   background-color: #0943B5;
   border: 1px solid white !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
}
.btn-cerrar-sesion {
   font-size: 0.75rem !important;
   font-weight: 400 !important;
   cursor: pointer;
   padding: 8px;
   color: #4b4b4b;
}
button:focus {
   outline: 0px dotted;
   outline: 0px auto -webkit-focus-ring-color !important;
}
.btn-cerrar-sesion:hover {
   background: #0943B5;
   color: white;
   border-radius: 1px;
}
.btn-personalizar:hover {
   background: #0943B5;
   color: white;
   border-radius: 1px;
}
.btn-personalizar {
   font-size: 0.75rem !important;
   font-weight: 400 !important;
   cursor: pointer;
   padding: 8px;
   color: #4b4b4b;
   margin-top: 14px;
}
.mr-icon {
   padding-right: 2px;
}
.btn-sesion:hover {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   background: #4672C8;
   padding: 0.1em 0.7em #4672C8 !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
   border: 1px solid #4672C8 !important;
}
.btn-sesion-activo {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   background: #4672C8;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
   border: 1px solid #4672C8 !important;
}
.navbar-container .container {
   padding: 0 5px;
   max-width: 1356px !important;
}
.icon-text {
   font-size: 1.2rem !important;
}
.btn-borde {
   font-weight: 400 !important;
   text-align: center !important;
   white-space: nowrap !important;
   vertical-align: middle !important;
   -webkit-user-select: none !important;
   -moz-user-select: none !important;
   -ms-user-select: none !important;
   user-select: none !important;
   border: 1px solid white !important;
   font-size: 1rem !important;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.btn-boder-white:hover {
   background-color: #ffffff !important;
   color: #0943B5 !important;
}
.cerrar-sesion {
   text-align: left;
   position: absolute;
   justify-content: end;
   margin-left: auto !important;
   margin-right: auto !important;
   width: 100%;
   background: #f9f7f7;
   color: #4b4b4b;
   margin-top: -6px !important;
   border-radius: 5px;
}
.index-sesion {
   position: relative;
   z-index: 1;
}
.btn-boder-white {
   font-weight: 600;
   background-color: #0943B5 !important;
   border: 1px solid !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   display: flex !important;
   align-items: center !important;
   border-radius: 20px;
}
.icon-text {
   color: #ffffff;
   font-size: 1.2rem;
}
.all #govco-header .header-text-opciones {
   color: #ddfb00 !important;
   border-color: #ddfb00 !important;
   background-color: #000 !important;
}
#govco-header {
   font-size: 16px;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
}
#govco-header .header-text-opciones {
   font-size: 0.875rem !important;
   padding: 0.521rem 1rem 0.48rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400 !important;
   line-height: 0.875rem !important;
}
#govco-header .nav-item-primary {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin: 0 !important;
}
#govco-header #nav-secondary .navbar-nav .nav-item a,
#govco-header #nav-secondary .navbar-container .navbar-first-menu .nav-item a,
.navbar-container #govco-header #nav-secondary .navbar-first-menu .nav-item a {
   font-size: 0.875rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
}
#govco-header .collapse.navbar-collapse.navbar-first-menu.float-right {
   float: initial;
   align-items: flex-end;
   justify-content: flex-end;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   height: auto !important;
   padding: 0.401rem 0 0.35rem !important;
   font-size: 0.875rem !important;
   margin-left: 1rem !important;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-webkit-input-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-moz-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-ms-input-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container .form-search-bar-active ~ .icon-search,
#govco-header .search-desktop-container .form-search-bar input.form-control:focus ~ .icon-search,
.form-search-bar #govco-header .search-desktop-container input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
#govco-header .search-desktop-container input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
#govco-header .search-desktop-container .icon-search {
   margin-right: 0px !important;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: transparent !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
   font-weight: 600 !important;
}
.navbar-nav .container-notifications-header,
.navbar-container .navbar-first-menu .container-notifications-header {
   padding: 0px;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1rem;
}
.navbar-notifications {
   width: 100%;
   background-color: #FDAA29;
}
.alert-warning {
   width: 100%;
   padding: 0px;
   margin: 0px;
   background-color: #FDAA29;
   border: 0px;
   border-radius: 0px;
}
.alert-warning a {
   padding: 0;
}
.alert-warning .page-icon-close {
   padding: 0;
   background-color: transparent;
   border: 0;
   -webkit-appearance: none;
   float: right;
   font-weight: 700;
   line-height: 1;
   transform: rotate(45deg);
}
.alert-warning .page-icon-close .govco-icon {
   color: #0b457f !important;
   font-weight: 600 !important;
   font-weight: 600;
   margin-top: 3px;
   font-size: 1.5rem;
}
.alert-warning .page-alert-text {
   font-size: 0.7rem !important;
   color: #4672C8 !important;
   font-family: Verdana, Geneva, sans-serif !important;
   text-transform: initial !important;
   font-weight: 500 !important;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.5rem;
   padding: 0;
   flex-flow: column;
   z-index: 9 !important;
}
.navbar-govco .form-search-bar {
   border-radius: 2rem;
   border: 2px solid white;
   box-shadow: 0px 0px 1px #0943B5;
   overflow: hidden;
   font-size: 0.5rem;
   margin-left: 1rem;
   line-height: 21px;
}
.navbar-govco .form-search-bar.none-visibility {
   border: solid 0.5px transparent;
   transition: 1.5s;
}
.navbar-govco .form-search-bar input.form-control {
   font-size: 0.75rem;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
   height: 25px;
}
.navbar-govco .form-search-bar input.form-control:focus {
   color: black !important;
   box-shadow: none !important;
}
.navbar-govco .form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.navbar-govco .form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.navbar-govco .form-search-bar .govco-icon {
   color: white;
   font-size: 1.7rem;
}
.navbar-govco .form-search-bar .material-icons {
   color: white;
   font-size: 1.7rem;
   height: auto;
   width: auto;
   text-align: end;
}
.navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px;
   margin: 0px;
}
.navbar-container .container {
   padding: 0px;
   margin: 0px;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-left: auto;
}
.navbar-container .navbar-first-menu .navbar-nav a:hover,
.navbar-container .navbar-first-menu .navbar-first-menu a:hover,
.navbar-container .navbar-first-menu .navbar-nav a:focus,
.navbar-container .navbar-first-menu .navbar-first-menu a:focus {
   color: white;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white;
   color: black;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #3772ff !important;
}
.form-search-bar-active ~ .search-btn > .icon-search,
.form-search-bar input.form-control:focus ~ .search-btn > .icon-search {
   color: #3772ff !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: 200px;
   background-color: white;
   background-image: none;
}
.page-content-hidden {
   height: 3rem;
   background-color: white;
   background-image: none;
}
.none-visibility {
   color: transparent;
}
@media (min-width: 992px) {
   .navbar-collapsed {
      display: none !important;
   }
}
@media (max-width: 992px) {
   .navbar-expanded {
      display: none !important;
   }
   .navbar-collapsed {
      display: initial !important;
   }
   .navbar-collapsed .navbar-collapse .nav-item {
      list-style-type: none;
   }
   .navbar-collapsed .navbar-collapse .nav-items {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in;
      height: auto;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.5s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching .none-visibility {
      display: none !important;
   }
   .navbar-collapsed .navbar-collapse .nav-items .nav-item-menu {
      display: block;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility {
      transition: height 0.5s, opacity 0.5s ease-in !important;
      position: relative;
      margin-top: 8vh;
      width: 100%;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility.none-visibility {
      opacity: 0;
      transition: height 0.5s, opacity 0.5s ease-out !important;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility {
      color: white;
      display: flex;
      align-items: center;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility label.item {
      font-size: 0.75rem;
      font-weight: 500;
      color: white;
      text-decoration: none;
      margin-bottom: 0;
      padding-left: 0.75rem;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.3s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching .nav-item-most-used {
      display: block !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .none-visibility {
      display: none;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .separator {
      margin: 1rem 2rem !important;
   }
   .navbar-collapsed .navbar-translate {
      background-color: #0943B5 !important;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      height: auto;
      max-height: 300px;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler {
      display: initial;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      padding: 0.75rem;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler .navbar-toggler-icon {
      color: white;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler:hover,
   .navbar-collapsed .navbar-translate .navbar-toggler:active,
   .navbar-collapsed .navbar-translate .navbar-toggler:focus {
      background-color: transparent;
      box-shadow: none;
   }
   .navbar-collapsed .navbar-translate .navbar-brand {
      margin: 0 !important;
      background-color: transparent !important;
   }
   .navbar-collapsed .navbar-translate .item-text {
      line-height: 1.5;
   }
   .navbar-collapsed .navbar-translate .navbar-nav-mobile {
      margin-bottom: 18px;
   }
   .navbar-collapsed .navbar-translate.focus {
      max-height: 0;
   }
   .navbar-collapsed .input-group {
      background-color: rgba(255, 255, 255, 0.3);
      transition: width 0.5s;
   }
   .navbar-collapsed .input-group .form-control {
      height: auto !important;
   }
   .navbar-collapsed .search-items .container-search {
      margin: 1rem 2rem;
      width: 100%;
   }
   .navbar-collapsed .search-items .btn-close-search a {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      font-size: 0.75rem;
      margin-top: 15px;
      transition: 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container {
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-radius: 2rem;
      border: 2px solid transparent;
      overflow: hidden;
      font-size: 0.5rem;
      line-height: 21px;
      transition: width 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container.focus {
      width: 70% !important;
      display: inline-flex;
      margin-right: 0;
      transition: 1s ease-out;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus {
      background-color: #fff !important;
      color: black !important;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn {
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn .govco-icon,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon {
      color: #3772ff;
      font-size: 2rem !important;
   }
   .navbar-collapsed .search-mobile-container.focus ~ .btn-close-search {
      display: inline-flex;
      margin: 0 auto;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile {
      border: none;
      background-color: rgba(255, 255, 255, 0.3) !important;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-webkit-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-moz-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-ms-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn {
      background-color: rgba(255, 255, 255, 0.3) !important;
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .btn-search {
      background: transparent;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .govco-icon {
      height: auto;
      color: white;
      font-size: 2rem !important;
   }
   .navbar-collapsed .title {
      color: white;
      font-size: 1rem;
      text-transform: initial;
   }
   .navbar-collapse {
      position: fixed;
      display: block;
      top: 0px;
      height: 100vh;
      width: 100%;
      right: 0;
      margin-right: 0 !important;
      z-index: 1032;
      visibility: visible;
      background-color: #0943B5;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      max-height: none !important;
   }
   .navbar-collapse.close-menu-govco {
      transform: translate3d(100%, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .navbar-collapse.open-menu-govco {
      transform: translate3d(0, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .nav-item-mobile {
      transition: max-height 0.5s ease-out;
   }
   .nav-item-mobile:not(:first-of-type) {
      width: 100% !important;
   }
   .nav-item-mobile:not(:first-of-type) .nav-link {
      height: auto;
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1.5rem auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile.active,
   .nav-item-mobile:hover,
   .nav-item-mobile:active,
   .nav-item-mobile:focus {
      background-color: #FDAA29 !important;
      box-shadow: none !important;
      border-radius: 0px !important;
   }
   .nav-item-mobile.active + .nav-item-mobile .nav-link,
   .nav-item-mobile:hover + .nav-item-mobile .nav-link,
   .nav-item-mobile:active + .nav-item-mobile .nav-link,
   .nav-item-mobile:focus + .nav-item-mobile .nav-link {
      border-top: 1.5px solid transparent !important;
   }
   .nav-item-mobile .nav-link {
      margin: auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile .nav-link.search {
      margin: auto 2rem !important;
   }
   .nav-item-mobile .nav-link span {
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
   }
   .separator {
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1rem;
   }
   .separator .search {
      margin: auto 2rem !important;
   }
}
.content-table-detalle {
   position: relative;
}
.content-table-detalle .content-detalle {
   position: absolute;
   bottom: 110px;
   right: 20%;
   width: 288px;
}
.content-table-detalle .content-detalle p {
   margin: 0;
}
.content-table-detalle .content-detalle ul li {
   list-style: none;
}
.content-render {
   position: relative;
   margin-bottom: 50px;
   padding: 10px 0;
}
.content-render > div > div > .content-component {
   box-shadow: 0px 15px 10px -15px black;
   padding: 10px;
}
.content-footer {
   display: flex;
   flex-wrap: wrap;
   -moz-column-gap: 50px;
   column-gap: 50px;
}
.content-table-venta {
   max-width: 635px;
   min-width: 635px;
}
.content-movile-auto,
.content-movile-venta {
   min-width: 320px;
   max-width: 380px;
}
.contenfooters {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}
.contenfooters div {
   flex: 1;
}
.hidden {
   display: none !important;
}
.content-inputs {
   overflow: auto;
   height: calc(100vh - 8vh);
}
.input-govco {
   color: #0b457f !important;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
   border-top-right-radius: 0.3rem !important;
   border-top-left-radius: 0.3rem !important;
   border-bottom: 1px solid #4672C8 !important;
   background-color: #e5eefb;
   border: unset;
   line-height: 1.4 !important;
   padding: 0.375rem 0.5rem;
}
.input-govco ~ .input-group-append > .govco-icon {
   color: #4672C8;
   padding-top: 1px;
}
.input-govco::-webkit-input-placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco::-ms-input-placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco::-moz-placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco::placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco:-ms-input-placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco::-ms-input-placeholder {
   color: rgba(11, 69, 127, 0.6);
}
.input-govco:disabled {
   background-color: #e9ecef !important;
   opacity: 1;
   border-color: #bfbfbf !important;
   color: #bfbfbf !important;
}
.input-govco:disabled::-webkit-input-placeholder {
   color: #bfbfbf !important;
   opacity: 1;
}
.input-govco:disabled::-ms-input-placeholder {
   color: #bfbfbf !important;
   opacity: 1;
}
.input-govco:disabled::-moz-placeholder {
   color: #bfbfbf !important;
   opacity: 1;
}
.input-govco:disabled::placeholder {
   color: #bfbfbf !important;
   opacity: 1;
}
.input-govco:disabled:-ms-input-placeholder {
   color: #0b457f;
}
.input-govco:disabled::-ms-input-placeholder {
   color: #0b457f;
}
.input-govco.is-invalid,
.invalid-form .input-govco.ng-invalid {
   border-color: #a80521 !important;
   color: #a80521 !important;
   background-color: #fff2fa !important;
}
.input-govco.is-invalid ~ .input-group-append > .govco-icon,
.invalid-form .input-govco.ng-invalid ~ .input-group-append > .govco-icon {
   color: #a80521;
}
.input-govco.is-invalid::-webkit-input-placeholder,
.invalid-form .input-govco.ng-invalid::-webkit-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-ms-input-placeholder,
.invalid-form .input-govco.ng-invalid::-ms-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-webkit-input-placeholder,
.invalid-form .input-govco.ng-invalid::-webkit-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-moz-placeholder,
.invalid-form .input-govco.ng-invalid::-moz-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-ms-input-placeholder,
.invalid-form .input-govco.ng-invalid::-ms-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::placeholder,
.invalid-form .input-govco.ng-invalid::placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-ms-input-placeholder::-ms-input-placeholder,
.invalid-form .input-govco.ng-invalid::-ms-input-placeholder::-ms-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-ms-input-placeholder::-ms-input-placeholder,
.invalid-form .input-govco.ng-invalid::-ms-input-placeholder::-ms-input-placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-invalid::-ms-input-placeholder::placeholder,
.invalid-form .input-govco.ng-invalid::-ms-input-placeholder::placeholder {
   color: rgba(168, 5, 33, 0.6);
}
.input-govco.is-valid {
   border-color: #28a745 !important;
   background-color: rgba(40, 167, 69, 0.1);
   color: #28a745 !important;
}
.input-govco.is-valid::-webkit-input-placeholder {
   color: #28a745 !important;
   opacity: 1;
}
.input-govco.is-valid::-ms-input-placeholder {
   color: #28a745 !important;
   opacity: 1;
}
.input-govco.is-valid::-moz-placeholder {
   color: #28a745 !important;
   opacity: 1;
}
.input-govco.is-valid::placeholder {
   color: #28a745 !important;
   opacity: 1;
}
.input-govco.is-valid:-ms-input-placeholder {
   color: #28a745 !important;
}
.input-govco.is-valid::-ms-input-placeholder {
   color: #28a745 !important;
}
.form-control:focus {
   box-shadow: none !important;
}
.input-govco:focus {
   background-color: #e5eefb !important;
}
.span-message-character {
   color: red;
}
.input-govco .div-count-character {
   position: absolute;
   bottom: 8px;
   width: 100%;
   border-top: 1px solid #0943B5;
}
.input-govco .div-count-character .span-message-character {
   color: red;
   font-size: 11px;
   font-weight: 600;
   padding: 0 20px;
}
.input-govco .div-count-character .span-count-character {
   font-weight: 600;
}
.div-page {
   background-color: white;
   height: 300px;
   border: 1px solid #4672C8;
}
.div-page > div {
   width: 90%;
   height: 100%;
   margin: 0 auto;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
}
.gov-co-margin-page {
   margin-top: 70px;
   position: relative;
}
.gov-co-margin-page > .med-pag {
   background-color: #e6effd;
}
.gov-co-margin-page > .med-pag::after {
   content: '74px';
   position: absolute;
   left: -30px;
   bottom: -30px;
}
.gov-co-margin-page > .med-pag::before {
   content: '74px';
   position: absolute;
   right: -30px;
   bottom: -30px;
}
.gov-co-margin-page::after {
   content: '1140px';
   position: absolute;
   margin: 0 5%;
   width: 90%;
   top: -40px;
   left: 0;
   border-bottom: 1px solid black;
   text-align: center;
}
.gov-co-margin-page::before {
   content: '1280px';
   position: absolute;
   width: 100%;
   top: -70px;
   left: 0;
   border-bottom: 1px solid black;
   text-align: center;
}
.div-columns {
   display: flex;
}
.div-columns > div {
   flex: 1 1 auto;
   height: 100%;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
   background-color: #e6effd;
}
.div-fd-page {
   position: relative;
   display: flex;
}
.div-fd-page > div {
   flex: 1 1 auto;
   height: 100%;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
}
.div-fd-page > .div-des-page {
   position: absolute;
   width: 100%;
   z-index: 3;
}
.class-pages {
   display: grid;
   grid-gap: 5px;
   grid-template-columns: 160px 160px auto;
}
.model-one {
   grid-template-rows: 120px auto;
   grid-template-areas:
      'titulo titulo area'
      'formder formizq area';
}
.model-one > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.model-one > .titulo {
   grid-area: titulo;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-left-width: 0px;
}
.model-one > .area {
   grid-area: area;
   margin-left: 40px;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
   border-right-width: 0px;
}
.model-one > .form-der {
   grid-area: formder;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-left-width: 0px;
}
.model-one > .form-izq {
   grid-area: formizq;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
}
.model-three {
   grid-template-rows: repeat(3, 1fr);
   grid-template-areas:
      'titulo titulo area'
      'formder formizq area'
      'tabla tabla area';
}
.model-three > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.model-three > .titulo {
   grid-area: titulo;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-left-width: 0px;
}
.model-three > .area {
   grid-area: area;
   margin-left: 40px;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
   border-right-width: 0px;
}
.model-three > .form-der {
   grid-area: formder;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-left-width: 0px;
}
.model-three > .form-izq {
   grid-area: formizq;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
}
.model-three > .tabla {
   grid-area: tabla;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-left-width: 0px;
}
.model-four {
   grid-template-rows: repeat(3, 1fr);
   grid-template-areas:
      'titulo titulo area'
      'formder formizq area'
      'tabla tabla tabla';
}
.model-four > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.model-four > .titulo {
   grid-area: titulo;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-left-width: 0px;
}
.model-four > .area {
   grid-area: area;
   margin-left: 40px;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-bottom-width: 0px;
   border-right-width: 0px;
}
.model-four > .form-der {
   grid-area: formder;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-left-width: 0px;
}
.model-four > .form-izq {
   grid-area: formizq;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
}
.model-four > .tabla {
   grid-area: tabla;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-left-width: 0px;
}
.model-five {
   grid-template-rows: repeat(3, 1fr);
   grid-template-areas:
      'titulo titulo area'
      'tabs formder formizq'
      'tabs tabla tabla';
}
.model-five > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.model-five > .titulo {
   grid-area: titulo;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-left-width: 0px;
}
.model-five > .tabs {
   grid-area: tabs;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-left-width: 0px;
}
.model-five > .form-der {
   grid-area: formder;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
}
.model-five > .form-izq {
   grid-area: formizq;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-left-width: 0px;
   border-right-width: 0px;
}
.model-five > .tabla {
   grid-area: tabla;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-right-width: 0px;
}
.model-six {
   grid-template-rows: 100px auto;
   grid-template-areas:
      'titulo titulo area'
      'contenttabs contenttabs contenttabs';
}
.model-six > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.model-six > .titulo {
   grid-area: titulo;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-top-width: 0px;
   border-left-width: 0px;
}
.model-six > .content-tabs {
   grid-area: contenttabs;
   position: relative;
   background-color: #f2f2f2ce;
   border: 1px solid #4672C8;
   border-bottom-width: 0px;
   border-right-width: 0px;
   border-left-width: 0px;
   margin-top: 40px;
}
.model-six > .content-tabs > div {
   width: 100%;
   height: 32px;
   position: absolute;
   top: -32px;
   left: 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
}
.model-six > .content-tabs > div > div {
   flex: 1 1 auto;
   margin: 0 2px;
   border: 1px solid black;
   border-bottom-width: 0px;
   background-color: #f2f2f2ce;
}
.div-marg {
   margin-top: 30px;
}
.alert-success-govco {
   background: #ffffff;
   border-color: #158361;
   border-radius: 0.5rem;
}
.alert-success-govco .alert-heading {
   display: flex;
   align-items: center;
}
.alert-success-govco .alert-heading .govco-icon {
   margin-right: 1rem;
}
.alert-success-govco .alert-heading .govco-icon,
.alert-success-govco .alert-heading .headline-l-govco {
   color: #158361 !important;
}
.alert-success-govco p,
.alert-success-govco li {
   color: #4b4b4b;
}
.alert-primary-govco {
   background: #ffffff;
   border-color: #0943B5;
   border-radius: 0.5rem;
}
.alert-primary-govco .alert-heading {
   display: flex;
   align-items: center;
}
.alert-primary-govco .alert-heading .govco-icon {
   margin-right: 1rem;
}
.alert-primary-govco .alert-heading .govco-icon,
.alert-primary-govco .alert-heading .headline-l-govco {
   color: #0943B5 !important;
}
.alert-primary-govco p,
.alert-primary-govco li {
   color: #4b4b4b;
}
.alert-warning-govco {
   background: #ffffff;
   border-color: #FDAA29;
   border-radius: 0.5rem;
}
.alert-warning-govco .alert-heading {
   display: flex;
   align-items: center;
}
.alert-warning-govco .alert-heading .govco-icon {
   margin-right: 1rem;
}
.alert-warning-govco .alert-heading .govco-icon,
.alert-warning-govco .alert-heading .headline-l-govco {
   color: #FDAA29 !important;
}
.alert-warning-govco p,
.alert-warning-govco li {
   color: #4b4b4b;
}
.alert-wrong-govco {
   background: #ffffff;
   border-color: #a80521;
   border-radius: 0.5rem;
}
.alert-wrong-govco .alert-heading {
   display: flex;
   align-items: center;
}
.alert-wrong-govco .alert-heading .govco-icon {
   margin-right: 1rem;
}
.alert-wrong-govco .alert-heading .govco-icon,
.alert-wrong-govco .alert-heading .headline-l-govco {
   color: #a80521 !important;
}
.alert-wrong-govco p,
.alert-wrong-govco li {
   color: #a80521;
}
.teclado-item {
   width: 12em;
}
.container-keyboard-password {
   max-width: 243px;
}
.teclado-item input[type='password'] {
   position: relative;
   border: none;
   border-bottom: 0.02em solid #4672C8;
   background: #e6effd;
   color: #0943B5;
   font-size: 1.2em;
}
.lb-name {
   position: absolute;
   top: -1.5em;
   color: #4672C8;
   font-size: 0.9em;
}
.textarea-govco {
   position: relative;
}
.textarea-govco textarea {
   background-color: rgba(157, 190, 255, 0.3) !important;
   border-radius: 0.8rem !important;
   font-weight: 600 !important;
   padding: 5% 5% 10% 5%;
   height: 12.063rem;
}
.textarea-govco textarea::-webkit-input-placeholder {
   color: rgba(11, 69, 127, 0.6) !important;
}
.textarea-govco textarea::-moz-placeholder {
   color: rgba(11, 69, 127, 0.6) !important;
}
.textarea-govco textarea::-ms-input-placeholder {
   color: rgba(11, 69, 127, 0.6) !important;
}
.textarea-govco textarea::placeholder {
   color: rgba(11, 69, 127, 0.6) !important;
}
.textarea-govco textarea:focus,
.textarea-govco textarea:hover {
   background-image: none !important;
}
.textarea-govco .div-count-character {
   position: absolute;
   bottom: 8px;
   width: 100%;
   border-top: 1px solid #0943B5;
}
.textarea-govco .div-count-character .span-message-character {
   color: red;
   font-size: 11px;
   font-weight: 600;
   padding: 0 20px;
}
.textarea-govco .div-count-character .span-count-character {
   font-weight: 600;
}
.bg-color-magenta {
   background-color: #c20a3b;
}
.bg-color-magenta-500 {
   background-color: #E8A046;
}
.bg-color-magenta-200 {
   background-color: #fdd8e2;
}
.bg-color-blue-capri {
   background-color: #81abff;
}
.bg-color-blue-denim {
   background-color: #4573d0;
}
.bg-color-blue-lake {
   background-color: #5b8bff;
}
.bg-color-blue-quilt {
   background-color: #9dbeff;
}
.bg-color-blue-ocean {
   background-color: #5881d5;
}
.bg-color-blue-marlin {
   background-color: #4a7eff;
}
.bg-color-blue-light {
   background-color: #e6effd;
}
.bg-color-radical-red {
   background-color: #f42e63;
}
.bg-color-blue-dark {
   background-color: #4672C8;
}
.bg-color-orange-v2 {
   background-color: #ff6c00;
}
.bg-color-green {
   background-color: #158361;
}
.bg-color-white {
   background-color: #ffffff;
}
.bg-color-red-flag {
   background-color: #d31f3f;
}
.bg-color-yellow {
   background-color: #fad118;
}
.bg-color-blue-ligth-v2 {
   background-color: #c9e2ff;
}
.bg-color-tundora-v2 {
   background-color: #606060;
}
.bg-color-orange-v2 {
   background-color: #ff6c00;
}
.bg-color-hawkes-blue-v2 {
   background-color: #f6f8f9;
}
.bg-color-blue-dark {
   background-color: #4672C8;
}
.bg-color-dark-blue {
   background-color: #4672C8;
}
.bg-color-blue-light {
   background-color: #e6effd;
}
.bg-color-marine {
   background-color: #0943B5;
}
.bg-color-dodger-blue {
   background-color: #3772ff;
}
.bg-color-green {
   background-color: #158361;
}
.bg-color-gold {
   background-color: #FDAA29;
}
.bg-color-orange {
   background-color: #f3561f;
}
.bg-color-concrete {
   background-color: #f2f2f2;
}
.bg-color-silver {
   background-color: #bababa;
}
.bg-color-tundora {
   background-color: #4b4b4b;
}
.bg-color-tutu {
   background-color: #fff2fa;
}
.bg-color-radical-red {
   background-color: #f42e63;
}
.bg-color-shiraz {
   background-color: #a80521;
}
.bg-color-white {
   background-color: #ffffff;
}
.bg-color-hawkes-blue {
   background-color: #f6f8f9;
}
.bg-color-catalina-blue {
   background-color: #0b457f;
}
.bg-color-pale-sky {
   background-color: #6c757d;
}
.bg-color-selago {
   background-color: #e5eefb;
}
.bg-color-hawkes-blue-300 {
   background-color: rgba(157, 190, 255, 0.3);
}
.bg-color-catalina-blue-600 {
   background-color: rgba(11, 69, 127, 0.6);
}
.bg-color-dark-blue-200 {
   background-color: rgba(0, 72, 132, 0.2);
}
.bg-color-dark-blue-500 {
   background-color: rgba(0, 72, 132, 0.5);
}
.bg-color-marine-300 {
   background-color: rgba(51, 102, 204, 0.3);
}
.bg-color-marine-400 {
   background-color: rgba(51, 102, 204, 0.4);
}
.bg-color-shiraz-600 {
   background-color: rgba(168, 5, 33, 0.6);
}
.bg-color-black-160 {
   background-color: rgba(0, 0, 0, 0.16);
}
.bg-color-white-contrast {
   background-color: white;
}
.bg-color-yellow-contrast {
   background-color: #ddfb00;
}
.bg-color-orange-contrast {
   background-color: #fbc200;
}
.bg-color-black-contrast {
   background-color: black;
}
.scroll-to-top {
   opacity: 0;
   position: fixed;
   bottom: 1rem;
   right: -144px;
   border-top-left-radius: 1rem;
   border-bottom-left-radius: 1rem;
   overflow: hidden;
   -webkit-box-shadow: 0px 3px 10px -3px #4b4b4b;
   z-index: 110;
   transition: 0.6s all;
   box-shadow: 0px 4px 0px #0943B524;
   border: 2px solid #e6effd;
}
.scroll-to-top button {
   border: unset;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0.5rem;
   background: #ffffff;
   cursor: pointer;
}
.scroll-to-top button .btn-svg-up-hover {
   font-size: 2rem !important;
   line-height: 2rem;
   color: #4672C8;
}
.scroll-to-top button .back-to-top-button {
   padding: 0.4rem !important;
}
.scroll-to-top button .label-button-star-up {
   font-size: 1rem !important;
   padding: 0 0.6rem;
   width: 144px;
}
.scroll-to-top button:focus {
   outline: none;
}
.scroll-to-top:hover {
   right: -0.1rem !important;
}
.show-scrollTop {
   opacity: 1;
   transition: 0.6s all;
}
body.all {
   background-color: black !important;
}
body.all .nav-secondary.govco-content {
   background-color: black !important;
}
body.all .scroll-to-top button {
   background: black;
}
body.all .scroll-to-top button .btn-svg-up-hover,
body.all .scroll-to-top button span {
   color: #4672C8 !important;
}
body.all .breadcrumb .breadcrumb-item .govco-icon::before {
   color: #ffffff !important;
}
body.all .breadcrumb li:not(.active) a {
   color: #ffffff !important;
}
.page__footer {
   padding-bottom: 70px;
   padding-top: 77px;
   margin-top: 1.8%;
   background-color: #0943B5 !important;
   color: white !important;
}
.page__footer .container ul li a:hover {
   text-decoration: none;
   background-color: transparent !important;
   box-shadow: none !important;
   color: white;
}
.page__footer .container .footer-container .footer-title {
   justify-content: center;
}
.page__footer .container .footer-container .footer-title .title-footer {
   justify-content: center;
   margin-top: 0px;
   text-align: center;
   font-size: 30px;
   font-family: 'Nunito Sans', sans-serif, sans-serif;
   letter-spacing: 0;
   color: #ffffff;
   font-weight: 300;
   margin-bottom: 30px;
}
.page__footer .container .footer-container .nav-footer {
   flex-wrap: wrap;
   flex-direction: row;
}
.page__footer .container .footer-container .nav-footer .item-footer {
   width: 14.25rem;
   display: flex;
   flex-direction: column;
}
.page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
   color: white !important;
   font-size: 2.8rem;
}
.page__footer .container .footer-container .nav-footer .item-footer p {
   color: #ffffff !important;
   margin-top: 0.5rem !important;
   margin-bottom: 0 !important;
   line-height: 1.25 !important;
   font-size: 1rem;
   font-weight: 400;
   text-rendering: geometricPrecision;
}
.page__footer .container .footer-container .nav-footer .item-footer .title-contact-link {
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #ffffff;
}
.page__footer .container .footer-container .nav-footer .item-footer .title-contact-link:first-letter {
   text-transform: capitalize !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .content-link {
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #ffffff;
   margin-top: 1px !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .content-link br {
   display: none;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link {
   text-align: center;
   border: 3px solid transparent !important;
   height: 100%;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
   margin-top: 0.5rem;
   margin-bottom: 0;
   line-height: 1.25;
   font-size: 1rem;
   text-transform: none;
   font-weight: 400;
   text-rendering: geometricPrecision;
   text-align: center;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif, sans-serif;
   letter-spacing: 0;
   color: #fff;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:hover {
   border: 3px solid white !important;
   background-color: transparent;
   color: white !important;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:hover p {
   font-weight: 500;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:active {
   background-color: transparent;
   box-shadow: none;
}
.page__footer .container .footer-container .nav-footer .item-footer .nav-link:active p {
   font-weight: 500;
}
.page__footer .container .submenu-footer {
   padding: 0;
   display: flex;
}
.page__footer .container .submenu-footer ul {
   margin-top: 40px;
   margin-bottom: 0rem;
}
.page__footer .container .submenu-footer ul li {
   display: inline-block;
}
.page__footer .container .submenu-footer a {
   color: #ffffff;
   text-transform: none;
   padding: 0 1rem;
   border-radius: 3px;
   position: relative;
   display: block;
   text-align: center;
   text-decoration: underline;
   font-weight: bold;
   font-size: 18px;
   font-family: 'Nunito Sans', sans-serif;
   letter-spacing: 0;
}
.page__footer .container .submenu-footer a:focus,
.page__footer .container .submenu-footer a:active,
.page__footer .container .submenu-footer a:hover {
   color: white;
   text-decoration: underline;
}
.nav-pills .nav-item .nav-link.active {
   background-color: transparent !important;
   box-shadow: none !important;
}
@media (max-width: 575.98px) {
   .page__footer .container #footer-container {
      justify-content: left;
      padding: 0.5rem 1rem;
   }
   .page__footer .container #footer-container .title-footer {
      padding: 0.5rem 1rem;
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 0px;
      margin-top: 0px;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif, sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container #footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0.5rem !important;
      margin-left: 0px !important;
      display: contents;
   }
   .page__footer .container .submenu-footer {
      margin-top: 40px;
      justify-content: left;
   }
   .page__footer .container .submenu-footer ul {
      margin-top: 0rem;
      margin-bottom: 0rem;
      padding: 0.5rem 1rem;
   }
   .page__footer .container .submenu-footer ul li {
      width: 100%;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1rem 0.5rem;
   }
}
@media (max-width: 767.98px) {
   .page__footer {
      padding-bottom: 40px;
      padding-top: 40px;
   }
   .page__footer .container .footer-container .title-footer {
      margin-top: 0px;
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif, sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container .submenu-footer {
      margin-top: 0px;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1.5rem 1rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer {
      width: 100%;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
      margin-right: 15px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link {
      text-align: left;
      display: inline-flex;
      width: 100%;
      vertical-align: middle;
      align-items: center;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0px;
      margin-left: 10px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title {
      margin-top: 1rem;
      justify-content: left;
      padding-left: 20px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title h2 {
      font-size: 1.6rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p {
      text-align: left;
      font-size: 1rem !important;
      display: contents;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p::first-letter {
      text-transform: capitalize !important;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link {
      text-transform: none !important;
      white-space: nowrap;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link a {
      color: white;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p br {
      display: block;
   }
}
.all span.breadcrumb-text.item-link:hover {
   background: #ddfb00 !important;
}
footer {
   font-size: 16px;
}
.footer-links a {
   color: #fff !important;
   font-size: 0.85rem;
}
.footer-links a:hover {
   color: #fff;
   font-size: 0.85rem !important;
}
.content-link {
   color: #fff !important;
}
.text-bold {
   font-weight: 500 !important;
}
.image-footer {
   position: absolute;
   bottom: 0;
   right: 0;
}
.img-width {
   width: 36px;
}
.llamada-solicitud {
   margin: 10px 5px;
   font-size: 0.85rem !important;
   color: #fff !important;
}
.footer-icon-co {
   width: 30%;
   cursor: pointer;
   border-right: 1px solid #fff;
   padding-right: 1rem;
}
.footer-icon-govco {
   min-width: 70%;
   padding-left: 1rem;
   cursor: pointer;
}
.footer-border-icon-co {
   padding-right: 26px;
   border-right: 2px solid white;
}
.form-btn-sesion {
   position: relative;
}
.btn-footer-white {
   font-weight: 600;
   background-color: #0943B5 !important;
   border: 1px solid !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   display: flex !important;
   align-items: center !important;
}
.all #govco-footer {
   background: #000 !important;
   border: none !important;
   box-shadow: none !important;
}
.all #govco-footer .contacto button.btn-round {
   color: #ddfb00 !important;
   border-color: #ddfb00 !important;
   background-color: #000 !important;
}
#govco-footer {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 16px;
}
#govco-footer .footer-descripcion {
   font-size: 1rem !important;
   padding-right: 1rem;
   color: #ffffff !important;
}
@media screen and (max-width: 768px) {
   #govco-footer .footer-descripcion {
      font-size: 14px !important;
      padding: 0 !important;
   }
}
#govco-footer .footer-social {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#govco-footer .footer-social .redes-sociales {
   color: #ffffff;
}
#govco-footer .footer-social .redes-sociales span {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 0.875rem;
   color: #ffffff;
   padding-bottom: 0.5rem;
   display: block;
}
#govco-footer .footer-social .img-width {
   width: 2.5rem;
}
#govco-footer .footer-links a {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-size: 0.875rem !important;
   font-weight: 400;
   text-decoration: underline;
   color: #ffffff;
}
#govco-footer .footer-links:last-of-type {
   margin: 0;
}
#govco-footer .contacto {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}
#govco-footer .contacto .footer-contacto-tel {
   font-size: 0.875rem;
   color: #ffffff;
}
#govco-footer .contacto button.btn-round {
   margin-top: 1rem !important;
   width: 100%;
   transition: 0.3s all ease-in-out;
}
#govco-footer .contacto button.btn-round .govco-icon {
   font-size: 1.5rem !important;
   margin: 0 !important;
   padding: 0 !important;
   display: inline-block;
}
#govco-footer .contacto button.btn-round .icono-btn {
   display: inline-block;
   width: 1.5rem;
}
#govco-footer .contacto button.btn-round .btn-govco-text {
   font-size: 0.875rem;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   font-weight: 400 !important;
   padding-top: 0.401rem;
   padding-bottom: 0.401rem;
   padding-left: 0.3rem;
   color: #ffffff;
   display: inline-block;
}
#govco-footer .contacto button.btn-round .govco-icon {
   font-size: 1rem;
   padding-right: 0.5rem;
}
#govco-footer .contacto button.btn-round:hover {
   background-color: #ffffff !important;
   color: #0943B5 !important;
   transition: 0.3s all ease-in-out;
}
#govco-footer .contacto button.btn-round:hover * {
   color: #0943B5 !important;
}
#govco-footer #icons-govco {
   width: 100%;
   display: flex;
}
#govco-footer .social-mobile {
   display: none;
}
@media only screen and (max-width: 768px) {
   .image-footer {
      position: inherit;
   }
   .footer-border-icon-co {
      padding-right: 10%;
      border-right: 2px solid white;
   }
   .footer-contacto-tel {
      font-size: 0.85rem;
      color: white;
   }
   #govco-footer .redes-sociales {
      margin-bottom: 1rem;
   }
   #govco-footer .texto-contacto p.text-bold.text-white {
      font-size: 1rem;
   }
   #govco-footer .contacto {
      width: 100%;
      margin: 1rem auto;
   }
   #govco-footer .contacto .btn-round {
      width: auto !important;
   }
   #govco-footer #icons-govco {
      margin: 1rem 0 0.5rem;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
   }
   #govco-footer #icons-govco .footer-icon-co {
      width: 22%;
      height: min-intrinsic;
   }
   #govco-footer #icons-govco .footer-icon-govco {
      width: 70%;
      height: min-intrinsic;
   }
   #govco-footer .social-mobile {
      display: block;
      padding: 1rem;
   }
   #govco-footer .social-mobile span {
      color: #ffffff;
   }
   #govco-footer .social-desktop {
      display: none;
   }
}
.goog-te-banner-frame,
#google_translate_element,
.goog-tooltip {
   display: none !important;
}
.goog-tooltip:hover {
   display: none !important;
}
.goog-text-highlight {
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}
font {
   vertical-align: inherit !important;
   font-size: inherit !important;
   color: inherit !important;
   text-transform: inherit !important;
   display: inherit !important;
}
.navbar-govco {
   z-index: 99999 !important;
}
.navbar-govco .navbar-brand {
   padding: 10px !important;
}
.navbar-govco .navbar-brand:focus {
   border: 2px #fff dotted;
}
.image-icon div {
   margin: 0 auto;
   position: relative;
   height: 26px;
   width: 26px;
   background-image: url('/assets/images/spr-govco.png') !important;
   background-repeat: no-repeat;
}
.image-icon #contrast {
   background-position: 0px -180px;
}
.image-icon #less-size {
   background-position: 0px -206px;
}
.image-icon #more-size {
   background-position: 0px -232px;
}
.image-icon #relief {
   background-position: 0px -258px;
}
.goog-te-banner-frame,
#google_translate_element,
.goog-tooltip {
   display: none !important;
}
.goog-tooltip:hover {
   display: none !important;
}
.goog-text-highlight {
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}
.goog-te-banner-frame,
#google_translate_element,
.goog-tooltip {
   display: none !important;
}
.goog-tooltip:hover {
   display: none !important;
}
.goog-text-highlight {
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}
font {
   vertical-align: inherit !important;
   font-size: inherit !important;
   color: inherit !important;
   text-transform: inherit !important;
   display: inherit !important;
   text-align: inherit !important;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1.36rem;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a {
   color: #0b457f;
   font-weight: 600 !important;
}
.navbar-notifications {
   width: 100%;
   background-color: #FDAA29;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.5rem;
   padding: 0;
   flex-flow: column;
   z-index: 9 !important;
}
.form-search-bar {
   border-radius: 2rem;
   border: 1px solid #ffffff;
   box-shadow: 0px 0px 1px #0943B5;
   overflow: hidden;
   font-size: 0.5rem;
   margin-left: 1rem;
   line-height: 21px;
}
.form-search-bar.none-visibility {
   border: solid 0.5px transparent;
   transition: 1.5s;
}
.form-search-bar input.form-control {
   font-size: 0.75rem;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
   height: 25px;
}
.form-search-bar input.form-control:focus {
   color: black !important;
   box-shadow: none !important;
}
.form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.form-search-bar .govco-icon {
   color: white;
   font-size: 1.7rem;
   line-height: 1.1;
}
.form-search-bar .material-icons {
   color: white;
   font-size: 1.7rem;
   height: auto;
   width: auto;
   text-align: end;
}
.form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px;
   margin: 0px;
}
.navbar-container .container {
   padding: 0px;
   margin: 0px;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-left: auto;
}
.navbar-container .navbar-first-menu .navbar-nav a:hover,
.navbar-container .navbar-first-menu .navbar-first-menu a:hover,
.navbar-container .navbar-first-menu .navbar-nav a:focus,
.navbar-container .navbar-first-menu .navbar-first-menu a:focus {
   color: white;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white;
   color: black;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #3772ff !important;
}
.form-search-bar-active ~ .search-btn > .icon-search,
.form-search-bar input.form-control:focus ~ .search-btn > .icon-search {
   color: #3772ff !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: auto;
   background-color: white;
   background-image: none;
}
.page-content-hidden {
   height: 6rem;
   background-color: white;
   background-image: none;
}
.none-visibility {
   color: transparent;
}
@media (min-width: 992px) {
   .navbar-collapsed {
      display: none !important;
   }
}
@media (max-width: 992px) {
   .navbar-expanded {
      display: none !important;
   }
   .navbar-collapsed {
      display: initial !important;
   }
   .navbar-collapsed .navbar-collapse .nav-item {
      list-style-type: none;
   }
   .navbar-collapsed .navbar-collapse .nav-items {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in;
      height: auto;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.5s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching .none-visibility {
      display: none !important;
   }
   .navbar-collapsed .navbar-collapse .nav-items .nav-item-menu {
      display: block;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility {
      transition: height 0.5s, opacity 0.5s ease-in !important;
      position: relative;
      margin-top: 8vh;
      width: 100%;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility.none-visibility {
      opacity: 0;
      transition: height 0.5s, opacity 0.5s ease-out !important;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility {
      color: white;
      display: flex;
      align-items: center;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility label.item {
      font-size: 0.75rem;
      font-weight: 500;
      color: white;
      text-decoration: none;
      margin-bottom: 0;
      padding-left: 0.75rem;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.3s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching .nav-item-most-used {
      display: block !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .none-visibility {
      display: none;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .separator {
      margin: 1rem 2rem !important;
   }
   .navbar-collapsed .navbar-translate {
      background-color: #0943B5 !important;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      height: auto;
      max-height: 300px;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler {
      display: initial;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      padding: 0.75rem;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler .navbar-toggler-icon {
      color: white;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler:hover,
   .navbar-collapsed .navbar-translate .navbar-toggler:active,
   .navbar-collapsed .navbar-translate .navbar-toggler:focus {
      background-color: transparent;
      box-shadow: none;
   }
   .navbar-collapsed .navbar-translate .navbar-brand {
      margin: 0 !important;
      background-color: transparent !important;
   }
   .navbar-collapsed .navbar-translate .item-text {
      line-height: 1.5;
   }
   .navbar-collapsed .navbar-translate .navbar-nav-mobile {
      margin-bottom: 18px;
   }
   .navbar-collapsed .navbar-translate.focus {
      max-height: 0;
   }
   .navbar-collapsed .input-group {
      background-color: rgba(255, 255, 255, 0.3);
      transition: width 0.5s;
   }
   .navbar-collapsed .input-group .form-control {
      height: auto !important;
   }
   .navbar-collapsed .search-items .container-search {
      margin: 1rem 2rem;
      width: 100%;
   }
   .navbar-collapsed .search-items .btn-close-search a {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      font-size: 0.75rem;
      margin-top: 15px;
      transition: 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container {
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-radius: 2rem;
      border: 2px solid transparent;
      overflow: hidden;
      font-size: 0.5rem;
      line-height: 21px;
      transition: width 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container.focus {
      width: 70% !important;
      display: inline-flex;
      margin-right: 0;
      transition: 1s ease-out;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus {
      background-color: #fff !important;
      color: black !important;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn {
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn .govco-icon,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon {
      color: #3772ff;
      font-size: 2rem !important;
   }
   .navbar-collapsed .search-mobile-container.focus ~ .btn-close-search {
      display: inline-flex;
      margin: 0 auto;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile {
      border: none;
      background-color: rgba(255, 255, 255, 0.3) !important;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-webkit-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-moz-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-ms-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn {
      background-color: rgba(255, 255, 255, 0.3) !important;
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .btn-search {
      background: transparent;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .govco-icon {
      height: auto;
      color: white;
      font-size: 2rem !important;
   }
   .navbar-collapsed s .title {
      color: white;
      font-size: 1rem;
      text-transform: initial;
   }
   .navbar-collapse {
      position: fixed;
      display: block;
      top: 0px;
      height: 100vh;
      width: 100%;
      right: 0;
      margin-right: 0 !important;
      z-index: 1032;
      visibility: visible;
      background-color: #0943B5;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      max-height: none !important;
   }
   .navbar-collapse.close-menu-govco {
      transform: translate3d(100%, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .navbar-collapse.open-menu-govco {
      transform: translate3d(0, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .nav-item-mobile {
      transition: max-height 0.5s ease-out;
   }
   .nav-item-mobile:not(:first-of-type) {
      width: 100% !important;
   }
   .nav-item-mobile:not(:first-of-type) .nav-link {
      height: auto;
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1.5rem auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile.active,
   .nav-item-mobile:hover,
   .nav-item-mobile:active,
   .nav-item-mobile:focus {
      background-color: #FDAA29 !important;
      box-shadow: none !important;
      border-radius: 0px !important;
   }
   .nav-item-mobile.active + .nav-item-mobile .nav-link,
   .nav-item-mobile:hover + .nav-item-mobile .nav-link,
   .nav-item-mobile:active + .nav-item-mobile .nav-link,
   .nav-item-mobile:focus + .nav-item-mobile .nav-link {
      border-top: 1.5px solid transparent !important;
   }
   .nav-item-mobile .nav-link {
      margin: auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile .nav-link.search {
      margin: auto 2rem !important;
   }
   .nav-item-mobile .nav-link span {
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
   }
   .separator {
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1rem;
   }
   .separator .search {
      margin: auto 2rem !important;
   }
}
@media (max-height: 620px) {
   .navbar-collapse.flex-column.ms-0.collapse.show.navbar-mobile.open-menu-govco {
      overflow-y: auto;
      overflow-x: auto;
   }
}
@media (max-width: 767.98px) {
   .page__footer {
      padding-bottom: 40px;
      padding-top: 40px;
   }
   .page__footer .container .footer-container .title-footer {
      margin-top: 0px;
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      font-family: 'Nunito Sans', sans-serif, sans-serif;
      letter-spacing: 0;
      color: #ffffff;
   }
   .page__footer .container .submenu-footer {
      margin-top: 0px;
   }
   .page__footer .container .submenu-footer ul li a {
      text-align: left;
      padding: 1.5rem 1rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer {
      width: 100%;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .govco-icon {
      margin-right: 15px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link {
      text-align: left;
      display: inline-flex;
      width: 100%;
      vertical-align: middle;
      align-items: center;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link a {
      margin-top: 0px;
      margin-left: 10px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title {
      margin-top: 1rem;
      justify-content: left;
      padding-left: 20px;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link .footer-title h2 {
      font-size: 1.6rem;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p {
      text-align: left;
      font-size: 1rem !important;
      display: contents;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p::first-letter {
      text-transform: capitalize !important;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p.content-link {
      text-transform: none !important;
      white-space: nowrap;
   }
   .page__footer .container .footer-container .nav-footer .item-footer .nav-link p br {
      display: block;
   }
}
.all #header-component .nav-secondary {
   background-color: #000 !important;
   box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.5);
}
.all #header-component .navbar-notifications {
   color: #4672C8 !important;
   background-color: #ddfb00;
   box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.5);
}
.all #header-component .navbar-notifications div {
   color: #4672C8 !important;
   background-color: #ddfb00;
}
.all #header-component .navbar-notifications div a {
   color: #4672C8 !important;
}
.all #header-component .navbar-notifications div a strong {
   color: #4672C8 !important;
}
.all #header-component .navbar-govco {
   background: black;
}
.all #header-component .navbar-govco .nav-secondary.show-transition {
   background-color: black;
}
.all #header-component .container ul.navbar-nav.nav-items.nav-items-desktop li.active,
.all #header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li.active,
.navbar-container .all #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li.active {
   background-color: #ddfb00 !important;
   color: #4672C8 !important;
}
@media (max-width: 992px) {
   .all #header-component .navbar-collapsed .navbar-translate {
      background-color: #000 !important;
   }
   .all #header-component .nav-item-mobile:hover,
   .all #header-component .nav-item-mobile.active {
      background-color: #ddfb00 !important;
   }
   .all #header-component i {
      color: #ddfb00 !important;
      background-color: transparent !important;
   }
   .all #header-component .nav-item.nav-accessibility .option-accessibility {
      background-color: transparent;
   }
}
#header-component .alert.alert-warning,
#header-component .navbar-container,
#header-component .container {
   padding: 0 5px;
   max-width: 1130px !important;
}
@media (min-width: 1200px) {
   #header-component .alert.alert-warning .container,
   #header-component .navbar-container .container,
   #header-component .container .container {
      max-width: 1130px !important;
   }
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu {
   width: 100%;
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu li,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop li,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li {
   text-align: center;
   font-size: 14px;
   font-family: NunitoSans, sans-serif;
   letter-spacing: 0.42px;
   color: #4672C8;
   text-transform: uppercase;
   flex-basis: 100%;
}
#header-component .alert.alert-warning ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .alert.alert-warning .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
.navbar-container #header-component .alert.alert-warning ul.nav-items.nav-items-desktop.navbar-first-menu li a,
#header-component .navbar-container ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
#header-component .container ul.navbar-nav.nav-items.nav-items-desktop li a,
#header-component .container .navbar-container ul.nav-items.nav-items-desktop.navbar-first-menu li a,
.navbar-container #header-component .container ul.nav-items.nav-items-desktop.navbar-first-menu li a {
   padding: 10px;
   text-align: center;
   font-size: 14px;
   font-family: NunitoSans, sans-serif;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
#header-component .alert.alert-warning {
   padding: 0px;
}
#header-component .nav-secondary {
   background: #e6effd;
}
#header-component .nav-secondary .nav-item .nav-link {
   line-height: 20px;
   font-weight: 500 !important;
}
#header-component .navbar-govco .form-search-bar {
   border: 1px solid white;
}
#header-component .navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .navbar-govco .form-search-bar input.form-control::placeholder {
   font-size: 1rem;
   font-weight: normal;
   font-family: NunitoSans, sans-serif;
}
#header-component .container-notifications-header a {
   margin: 15px auto;
   width: 100%;
   display: inline-block;
   text-align: center;
   text-transform: inherit;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif;
   letter-spacing: 0;
}
#header-component .container-notifications-header a strong {
   font-weight: bold;
   font-size: 14px;
   font-family: Verdana, Geneva, sans-serif;
   text-decoration: underline;
   display: inline-block;
   margin-left: 3px;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1.36rem;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a {
   color: #0b457f;
   font-weight: 600 !important;
}
.navbar-notifications {
   width: 100%;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.75rem;
   padding: 0;
   flex-flow: column;
}
.navbar-govco .form-search-bar {
   border-radius: 2rem;
   border: 1px solid #ffffff;
   box-shadow: 0px 0px 1px white;
   overflow: hidden;
   font-size: 0.75rem;
   margin-left: 1rem;
   line-height: 21px;
}
.navbar-govco .form-search-bar input.form-control {
   font-size: 1rem !important;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   width: 118px;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
}
.navbar-govco .form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 1s;
   -webkit-transition: all 1s;
}
.navbar-govco .form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 1s;
   -webkit-transition: all 1s;
}
.navbar-govco .form-search-bar .govco-icon {
   color: white;
   font-size: 1.55rem;
}
.navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 1rem;
   opacity: 0.7;
   line-height: 1.1;
}
.navbar-govco .navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px 6%;
   background-color: #0943B5;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0 0.5rem;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-top: 0.63rem;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white !important;
   color: black !important;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: 200px;
   background-color: white;
   background-image: none;
}
@media (max-width: 768px) {
   .navbar-notifications {
      display: none !important;
   }
}
.rotate-icon {
   transform: rotateZ(45deg);
   display: block;
}
span.govco-icon.govco-icon-plus.rotate-icon {
   font-size: 1.2rem;
}
span.govco-icon.govco-icon-plus.rotate-icon::before {
   font-size: 1.2rem;
   font-weight: bold;
   line-height: 1;
}
nav.navbar-govco {
   z-index: 99 !important;
}
.btn-round {
   border-radius: 30px !important;
   margin: 0 !important;
}
.btn-sesion {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   font-weight: bold;
   background-color: #0943B5;
   border: 1px solid white !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
}
.btn-cerrar-sesion {
   font-size: 0.75rem !important;
   font-weight: 400 !important;
   cursor: pointer;
   padding: 8px;
   color: #4b4b4b;
}
button:focus {
   outline: 0px dotted;
   outline: 0px auto -webkit-focus-ring-color !important;
}
.btn-cerrar-sesion:hover {
   background: #0943B5;
   color: white;
   border-radius: 1px;
}
.btn-personalizar:hover {
   background: #0943B5;
   color: white;
   border-radius: 1px;
}
.btn-personalizar {
   font-size: 0.75rem !important;
   font-weight: 400 !important;
   cursor: pointer;
   padding: 8px;
   color: #4b4b4b;
   margin-top: 14px;
}
.mr-icon {
   padding-right: 2px;
}
.btn-sesion:hover {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   background: #4672C8;
   padding: 0.1em 0.7em #4672C8 !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
   border: 1px solid #4672C8 !important;
}
.btn-sesion-activo {
   display: inline-flex;
   vertical-align: middle;
   justify-content: center;
   padding: 0 5px;
   background: #4672C8;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   align-items: center !important;
   border-radius: 20px;
   border: 1px solid #4672C8 !important;
}
.navbar-container .container {
   padding: 0 5px;
   max-width: 1356px !important;
}
.icon-text {
   font-size: 1.2rem !important;
}
.btn-borde {
   font-weight: 400 !important;
   text-align: center !important;
   white-space: nowrap !important;
   vertical-align: middle !important;
   -webkit-user-select: none !important;
   -moz-user-select: none !important;
   -ms-user-select: none !important;
   user-select: none !important;
   border: 1px solid white !important;
   font-size: 1rem !important;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.btn-boder-white:hover {
   background-color: #ffffff !important;
   color: #0943B5 !important;
}
.cerrar-sesion {
   text-align: left;
   position: absolute;
   justify-content: end;
   margin-left: auto !important;
   margin-right: auto !important;
   width: 100%;
   background: #f9f7f7;
   color: #4b4b4b;
   margin-top: -6px !important;
   border-radius: 5px;
}
.index-sesion {
   position: relative;
   z-index: 1;
}
.btn-boder-white {
   font-weight: 600;
   background-color: #0943B5 !important;
   border: 1px solid !important;
   margin: 0px !important;
   color: white !important;
   box-shadow: none !important;
   padding: 0.1em 0.7em !important;
   cursor: pointer !important;
   line-height: 1.5rem !important;
   display: flex !important;
   align-items: center !important;
   border-radius: 20px;
}
.icon-text {
   color: #ffffff;
   font-size: 1.2rem;
}
.all #govco-header .header-text-opciones {
   color: #ddfb00 !important;
   border-color: #ddfb00 !important;
   background-color: #000 !important;
}
#govco-header {
   font-size: 16px;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
}
#govco-header .header-text-opciones {
   font-size: 0.875rem !important;
   padding: 0.521rem 1rem 0.48rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400 !important;
   line-height: 0.875rem !important;
}
#govco-header .nav-item-primary {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin: 0 !important;
}
#govco-header #nav-secondary .navbar-nav .nav-item a,
#govco-header #nav-secondary .navbar-container .navbar-first-menu .nav-item a,
.navbar-container #govco-header #nav-secondary .navbar-first-menu .nav-item a {
   font-size: 0.875rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
}
#govco-header .collapse.navbar-collapse.navbar-first-menu.float-right {
   float: initial;
   align-items: flex-end;
   justify-content: flex-end;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm {
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   height: auto !important;
   padding: 0.401rem 0 0.35rem !important;
   font-size: 0.875rem !important;
   margin-left: 1rem !important;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-webkit-input-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-moz-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::-ms-input-placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container input.search-navbar.search-desktop-searchTerm::placeholder {
   color: #ffffff;
   font-size: 1rem !important;
   font-family: Verdana, Geneva, 'Nunito Sans', sans-serif !important;
   opacity: 1;
}
#govco-header .search-desktop-container .form-search-bar-active ~ .icon-search,
#govco-header .search-desktop-container .form-search-bar input.form-control:focus ~ .icon-search,
.form-search-bar #govco-header .search-desktop-container input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
#govco-header .search-desktop-container input.form-control:focus ~ .icon-search {
   color: #0943B5 !important;
}
#govco-header .search-desktop-container .icon-search {
   margin-right: 0px !important;
}
.navbar {
   width: 100%;
   z-index: 3;
}
.navbar-nav a,
.navbar-container .navbar-first-menu a {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.75rem;
   font-weight: 400;
   color: #0b457f !important;
   text-transform: uppercase;
   padding: 15px 5px;
}
.navbar-nav a:hover,
.navbar-container .navbar-first-menu a:hover,
.navbar-nav a:focus,
.navbar-container .navbar-first-menu a:focus {
   background: transparent !important;
}
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item.active,
.navbar-container .navbar-first-menu .nav-item.active,
.navbar-nav .nav-item:hover,
.navbar-container .navbar-first-menu .nav-item:hover,
.navbar-nav .nav-item:focus,
.navbar-container .navbar-first-menu .nav-item:focus {
   background: #FDAA29 !important;
}
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item.active a,
.navbar-container .navbar-first-menu .nav-item.active a,
.navbar-nav .nav-item:hover a,
.navbar-container .navbar-first-menu .nav-item:hover a,
.navbar-nav .nav-item:focus a,
.navbar-container .navbar-first-menu .nav-item:focus a {
   color: #0b457f !important;
   font-weight: 600 !important;
}
.navbar-nav .container-notifications-header,
.navbar-container .navbar-first-menu .container-notifications-header {
   padding: 0px;
}
.navbar-nav .nav-item,
.navbar-container .navbar-first-menu .nav-item {
   padding: 1px 1rem;
}
.navbar-notifications {
   width: 100%;
   background-color: #FDAA29;
}
.alert-warning {
   width: 100%;
   padding: 0px;
   margin: 0px;
   background-color: #FDAA29;
   border: 0px;
   border-radius: 0px;
}
.alert-warning a {
   padding: 0;
}
.alert-warning .page-icon-close {
   padding: 0;
   background-color: transparent;
   border: 0;
   -webkit-appearance: none;
   float: right;
   font-weight: 700;
   line-height: 1;
   transform: rotate(45deg);
}
.alert-warning .page-icon-close .govco-icon {
   color: #0b457f !important;
   font-weight: 600 !important;
   font-weight: 600;
   margin-top: 3px;
   font-size: 1.5rem;
}
.alert-warning .page-alert-text {
   font-size: 0.7rem !important;
   color: #4672C8 !important;
   font-family: Verdana, Geneva, sans-serif !important;
   text-transform: initial !important;
   font-weight: 500 !important;
}
.navbar-govco {
   background-color: #0943B5;
   font-size: 0.5rem;
   padding: 0;
   flex-flow: column;
   z-index: 9 !important;
}
.navbar-govco .form-search-bar {
   border-radius: 2rem;
   border: 2px solid white;
   box-shadow: 0px 0px 1px #0943B5;
   overflow: hidden;
   font-size: 0.5rem;
   margin-left: 1rem;
   line-height: 21px;
}
.navbar-govco .form-search-bar.none-visibility {
   border: solid 0.5px transparent;
   transition: 1.5s;
}
.navbar-govco .form-search-bar input.form-control {
   font-size: 0.75rem;
   margin-right: 1px !important;
   margin-left: 10px;
   color: white !important;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0;
   height: 25px;
}
.navbar-govco .form-search-bar input.form-control:focus {
   color: black !important;
   box-shadow: none !important;
}
.navbar-govco .form-search-bar input.form-control.translation {
   width: 0;
   margin: 0 !important;
   color: white !important;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.navbar-govco .form-search-bar input.form-control.non-translation {
   width: 118px;
   margin-right: 1px !important;
   margin-left: 10px;
   transition: all 0.5s;
   -webkit-transition: all 0.5s;
}
.navbar-govco .form-search-bar .govco-icon {
   color: white;
   font-size: 1.7rem;
}
.navbar-govco .form-search-bar .material-icons {
   color: white;
   font-size: 1.7rem;
   height: auto;
   width: auto;
   text-align: end;
}
.navbar-govco .form-search-bar input.form-control::-webkit-input-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::-moz-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::-ms-input-placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .form-search-bar input.form-control::placeholder {
   color: white;
   font-size: 0.75rem;
   opacity: 0.7;
}
.navbar-govco .navbar-brand {
   padding: 0;
}
.navbar-container {
   width: 100%;
   padding: 0px;
   margin: 0px;
}
.navbar-container .container {
   padding: 0px;
   margin: 0px;
}
.navbar-container .navbar-logo img {
   margin: 10px 0px !important;
}
.navbar-container .nav-primary a {
   color: white !important;
}
.navbar-container .nav-item-primary {
   display: inline-flex;
}
.navbar-container .nav-item-primary .lang-govco {
   color: white;
   font-size: 1.7rem;
   margin: 0;
   line-height: 30px;
   cursor: pointer;
}
.navbar-container .nav-item-primary.is-scroll {
   margin-left: auto;
}
.navbar-container .navbar-first-menu .navbar-nav a:hover,
.navbar-container .navbar-first-menu .navbar-first-menu a:hover,
.navbar-container .navbar-first-menu .navbar-nav a:focus,
.navbar-container .navbar-first-menu .navbar-first-menu a:focus {
   color: white;
}
.form-search-bar-active,
.form-search-bar input.form-control:focus,
.navbar-govco .form-search-bar input.form-control:focus {
   background-color: white;
   color: black;
}
.form-search-bar-active ~ .icon-search,
.form-search-bar input.form-control:focus ~ .icon-search {
   color: #3772ff !important;
}
.form-search-bar-active ~ .search-btn > .icon-search,
.form-search-bar input.form-control:focus ~ .search-btn > .icon-search {
   color: #3772ff !important;
}
.nav-secondary {
   width: 100%;
   background-color: white;
}
.nav-secondary.govco-content {
   background-color: #f6f8f9 !important;
}
.nav-secondary .nav-item {
   cursor: pointer;
   padding: 0;
}
.nav-secondary .nav-item .nav-link {
   padding: 8.21px 30px;
   line-height: 20px;
   font-weight: 500 !important;
}
.hidden-transition {
   opacity: 0;
   display: none;
   transition: opacity 0.3s;
}
.show-transition {
   display: inline;
   opacity: 1;
   transition: opacity 0.3s;
}
.page-header-hidden {
   height: 200px;
   background-color: white;
   background-image: none;
}
.page-content-hidden {
   height: 3rem;
   background-color: white;
   background-image: none;
}
.none-visibility {
   color: transparent;
}
@media (min-width: 992px) {
   .navbar-collapsed {
      display: none !important;
   }
}
@media (max-width: 992px) {
   .navbar-expanded {
      display: none !important;
   }
   .navbar-collapsed {
      display: initial !important;
   }
   .navbar-collapsed .navbar-collapse .nav-item {
      list-style-type: none;
   }
   .navbar-collapsed .navbar-collapse .nav-items {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in;
      height: auto;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.5s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-items.searching .none-visibility {
      display: none !important;
   }
   .navbar-collapsed .navbar-collapse .nav-items .nav-item-menu {
      display: block;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility {
      transition: height 0.5s, opacity 0.5s ease-in !important;
      position: relative;
      margin-top: 8vh;
      width: 100%;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility.none-visibility {
      opacity: 0;
      transition: height 0.5s, opacity 0.5s ease-out !important;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility {
      color: white;
      display: flex;
      align-items: center;
   }
   .navbar-collapsed .navbar-collapse .nav-accessibility .item-accessibility label.item {
      font-size: 0.75rem;
      font-weight: 500;
      color: white;
      text-decoration: none;
      margin-bottom: 0;
      padding-left: 0.75rem;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used {
      opacity: 0;
      height: 0;
      transition: height 0.5s, opacity 0.3s ease-out;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching {
      opacity: 1;
      transition: height 0.5s, opacity 0.5s ease-in !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used.searching .nav-item-most-used {
      display: block !important;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .none-visibility {
      display: none;
   }
   .navbar-collapsed .navbar-collapse .nav-most-used .separator {
      margin: 1rem 2rem !important;
   }
   .navbar-collapsed .navbar-translate {
      background-color: #0943B5 !important;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      height: auto;
      max-height: 300px;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler {
      display: initial;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      padding: 0.75rem;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler .navbar-toggler-icon {
      color: white;
   }
   .navbar-collapsed .navbar-translate .navbar-toggler:hover,
   .navbar-collapsed .navbar-translate .navbar-toggler:active,
   .navbar-collapsed .navbar-translate .navbar-toggler:focus {
      background-color: transparent;
      box-shadow: none;
   }
   .navbar-collapsed .navbar-translate .navbar-brand {
      margin: 0 !important;
      background-color: transparent !important;
   }
   .navbar-collapsed .navbar-translate .item-text {
      line-height: 1.5;
   }
   .navbar-collapsed .navbar-translate .navbar-nav-mobile {
      margin-bottom: 18px;
   }
   .navbar-collapsed .navbar-translate.focus {
      max-height: 0;
   }
   .navbar-collapsed .input-group {
      background-color: rgba(255, 255, 255, 0.3);
      transition: width 0.5s;
   }
   .navbar-collapsed .input-group .form-control {
      height: auto !important;
   }
   .navbar-collapsed .search-items .container-search {
      margin: 1rem 2rem;
      width: 100%;
   }
   .navbar-collapsed .search-items .btn-close-search a {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      font-size: 0.75rem;
      margin-top: 15px;
      transition: 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container {
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-radius: 2rem;
      border: 2px solid transparent;
      overflow: hidden;
      font-size: 0.5rem;
      line-height: 21px;
      transition: width 0.5s ease-in;
   }
   .navbar-collapsed .search-mobile-container.focus {
      width: 70% !important;
      display: inline-flex;
      margin-right: 0;
      transition: 1s ease-out;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus {
      background-color: #fff !important;
      color: black !important;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn {
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container.focus.form-search-bar-active .input-group-btn .govco-icon,
   .navbar-collapsed .form-search-bar input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon,
   .form-search-bar .navbar-collapsed input.search-mobile-container.focus.form-control:focus .input-group-btn .govco-icon {
      color: #3772ff;
      font-size: 2rem !important;
   }
   .navbar-collapsed .search-mobile-container.focus ~ .btn-close-search {
      display: inline-flex;
      margin: 0 auto;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile {
      border: none;
      background-color: rgba(255, 255, 255, 0.3) !important;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-webkit-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-moz-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::-ms-input-placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .item-search-mobile::placeholder {
      color: white;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn {
      background-color: rgba(255, 255, 255, 0.3) !important;
      padding-top: 7px;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .btn-search {
      background: transparent;
   }
   .navbar-collapsed .search-mobile-container .input-group-btn .govco-icon {
      height: auto;
      color: white;
      font-size: 2rem !important;
   }
   .navbar-collapsed .title {
      color: white;
      font-size: 1rem;
      text-transform: initial;
   }
   .navbar-collapse {
      position: fixed;
      display: block;
      top: 0px;
      height: 100vh;
      width: 100%;
      right: 0;
      margin-right: 0 !important;
      z-index: 1032;
      visibility: visible;
      background-color: #0943B5;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      max-height: none !important;
   }
   .navbar-collapse.close-menu-govco {
      transform: translate3d(100%, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .navbar-collapse.open-menu-govco {
      transform: translate3d(0, 0, 0);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 0);
   }
   .nav-item-mobile {
      transition: max-height 0.5s ease-out;
   }
   .nav-item-mobile:not(:first-of-type) {
      width: 100% !important;
   }
   .nav-item-mobile:not(:first-of-type) .nav-link {
      height: auto;
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1.5rem auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile.active,
   .nav-item-mobile:hover,
   .nav-item-mobile:active,
   .nav-item-mobile:focus {
      background-color: #FDAA29 !important;
      box-shadow: none !important;
      border-radius: 0px !important;
   }
   .nav-item-mobile.active + .nav-item-mobile .nav-link,
   .nav-item-mobile:hover + .nav-item-mobile .nav-link,
   .nav-item-mobile:active + .nav-item-mobile .nav-link,
   .nav-item-mobile:focus + .nav-item-mobile .nav-link {
      border-top: 1.5px solid transparent !important;
   }
   .nav-item-mobile .nav-link {
      margin: auto 1rem;
      padding-left: 0;
   }
   .nav-item-mobile .nav-link.search {
      margin: auto 2rem !important;
   }
   .nav-item-mobile .nav-link span {
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
   }
   .separator {
      border-top: 1.5px solid rgba(255, 255, 255, 0.3) !important;
      margin: auto 1rem;
   }
   .separator .search {
      margin: auto 2rem !important;
   }
}
.content-table-detalle {
   position: relative;
}
.content-table-detalle .content-detalle {
   position: absolute;
   bottom: 110px;
   right: 20%;
   width: 288px;
}
.content-table-detalle .content-detalle p {
   margin: 0;
}
.content-table-detalle .content-detalle ul li {
   list-style: none;
}
.content-render {
   position: relative;
   margin-bottom: 50px;
   padding: 10px 0;
}
.content-render > div > div > .content-component {
   box-shadow: 0px 15px 10px -15px black;
   padding: 10px;
}
.content-footer {
   display: flex;
   flex-wrap: wrap;
   -moz-column-gap: 50px;
   column-gap: 50px;
}
.content-table-venta {
   max-width: 635px;
   min-width: 635px;
}
.content-movile-auto,
.content-movile-venta {
   min-width: 320px;
   max-width: 380px;
}
.contenfooters {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}
.contenfooters div {
   flex: 1;
}
.font-montserrat {
   font-family: 'Nunito Sans', sans-serif !important;
}
.font-montserrat.bold {
   font-weight: 700;
}
.font-montserrat.semibold {
   font-weight: 600;
}
.font-montserrat.medium {
   font-weight: 500;
}
.font-montserrat.regular {
   font-weight: 400;
}
.font-montserrat.light {
   font-weight: 300;
}
.font-worksans {
   font-family: Verdana, Geneva, sans-serif !important;
}
.font-worksans.bold {
   font-weight: 700;
}
.font-worksans.semibold {
   font-weight: 600;
}
.font-worksans.medium {
   font-weight: 500;
}
.font-worksans.regular {
   font-weight: 400;
}
.font-worksans.light {
   font-weight: 300;
}
h1,
.headline-xxl-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 2.5rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 3em !important;
}
h2,
.headline-xl-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 2rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 2.75em !important;
}
h3,
.headline-l-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1.5rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 2.25em !important;
}
h4,
.headline-m-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1.25rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 1.875em !important;
}
h5,
.headline-s-govco,
.subtitle-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1.125rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 1.6875em !important;
}
h6,
.headline-xs-govco {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1rem;
   font-weight: 700;
   color: #4672C8;
   line-height: 1.5em !important;
}
.table-head-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 500;
   color: #4672C8;
   line-height: 1.3125em !important;
}
.table-head-s-gov-co {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 0.875rem;
   font-weight: 600;
   color: #4672C8;
   line-height: 1.3125em !important;
}
.description-gov-co {
   font-family: 'Nunito Sans', sans-serif;
   font-size: 1.25rem;
   font-weight: 500;
   color: #4672C8;
   line-height: 1.875em !important;
}
.body-1-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 400;
   color: #4b4b4b;
   line-height: 1.375em !important;
}
.body-2-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem;
   font-weight: 400;
   color: #4b4b4b;
   line-height: 1.3125em !important;
}
.btn-font-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.938rem;
   font-weight: 500;
   color: #4672C8;
   line-height: 1.375em !important;
   text-transform: uppercase;
   text-decoration: underline;
}
.field-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 500;
   color: #4b4b4b;
   line-height: 1.375em !important;
}
.caption-gov-co {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem;
   font-weight: 500;
   color: #4b4b4b;
   line-height: 1.125em !important;
}
.navbar-gov-co {
   background-color: #ffffff;
   padding: 0;
   display: flex;
   flex-flow: row wrap;
}
.navbar-gov-co-pri {
   padding: 0 38px;
   background-color: #0943B5;
}
.navbar-gov-co-pri .govco-icon {
   color: #ffffff;
   font-size: 25px;
}
.navbar-gov-co-sub {
   padding: 0 38px;
   background-color: #e6effd;
}
.navbar-gov-co-sub > ul {
   width: 90%;
   margin: 0 auto;
}
.nav-item-gov-co {
   flex: 1;
   text-align: center;
   margin: 0 3px;
   cursor: pointer;
}
.nav-item-gov-co:hover {
   background-color: #FDAA29;
}
.nav-item-gov-co a {
   font-size: 12px;
   font-weight: 600;
   color: #4672C8;
}
.nav-item-gov-co.active {
   background-color: #FDAA29;
}
.titulo-sede-gov-co {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1.25rem;
   color: #4672C8;
}
.titulo-venta {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1.25rem;
   color: #ffffff;
   line-height: 1.5rem !important;
}
.sub-titulo-venta {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1rem;
   color: #ffffff;
}
.sub-titulo-sede-gov-co {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 700;
   font-size: 1rem;
   color: #4672C8;
}
.gov-detalles {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #4b4b4b;
}
.gov-detalles-venta {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 1rem;
   color: #ffffff;
   margin-bottom: 3px;
}
.gov-detalles-venta-conta {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #ffffff;
   margin-bottom: 3px;
}
.gov-co-link-presentacion {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.875rem;
   font-weight: 500;
   color: #4672C8;
}
.gov-co-link-presentacion span {
   text-decoration: none;
   font-size: 1.125rem !important;
}
.gov-co-link-venta {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.75rem;
   color: #ffffff;
}
.goc-co-link-poli {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 700;
   font-size: 0.75rem;
}
.goc-co-link-poli-venta {
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 700;
   font-size: 0.75rem;
   color: #ffffff;
   text-decoration: underline;
}
.gov-co-logo-pie-mesa {
   width: 48px;
   height: 48px;
}
.gov-co-logo-pie-blanco {
   width: 134px;
   height: 48px;
}
.gov-co-footer .gov-co-footer-presetacion,
.gov-co-footer-xs .gov-co-footer-presetacion,
.gov-co-footer-sm .gov-co-footer-presetacion,
.gov-co-footer-lg .gov-co-footer-presetacion,
.gov-co-footer-xl .gov-co-footer-presetacion {
   position: relative;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: space-between;
   background-image: linear-gradient(to bottom, #fff, #fff 15%, #e6effd 15%, #e6effd 85%, #e6effd 85%);
}
.gov-co-footer .gov-co-footer-presetacion.gov-co-footer-tramites,
.gov-co-footer-xs .gov-co-footer-presetacion.gov-co-footer-tramites,
.gov-co-footer-sm .gov-co-footer-presetacion.gov-co-footer-tramites,
.gov-co-footer-lg .gov-co-footer-presetacion.gov-co-footer-tramites,
.gov-co-footer-xl .gov-co-footer-presetacion.gov-co-footer-tramites {
   background-image: linear-gradient(to bottom, #fff, #fff 15%, #f42e63 15%, #f42e63 85%, #f42e63 85%);
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad {
   position: relative;
   width: 90%;
   max-width: 1100px;
   min-width: 280px;
   overflow: hidden;
   margin: 0 auto 15px;
   padding: 10px 0;
   background-color: #ffffff;
   box-shadow: 0 0 3px gray;
   display: flex;
   flex-flow: row wrap;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > div {
   flex: 1 1 auto;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo {
   vertical-align: middle;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo > div > div > div,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo > div > div > div,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo > div > div > div,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo > div > div > div,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo > div > div > div {
   width: 95%;
   overflow: hidden;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div {
   position: relative;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co {
   width: 95%;
   max-width: 291px;
   height: 71px;
   margin: 0 auto;
   background-color: #f2f2f2;
   box-shadow: 0 0 3px gray;
   display: flex;
   justify-content: center;
   align-items: center;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div {
   padding-top: 10px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .contenido-sede-gov-co > p,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .contenido-sede-gov-co > p,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .contenido-sede-gov-co > p,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .contenido-sede-gov-co > p,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .contenido-sede-gov-co > p {
   margin-bottom: 3px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales {
   display: flex;
   justify-content: space-between;
   padding: 10px 0;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a {
   text-decoration: underline;
   text-align: center;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a > span,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a > span,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a > span,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a > span,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div > div > div > .gov-co-redes-sociales > a > span {
   font-size: 0.75rem;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co {
   display: none;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > div > div > p,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > div > div > p,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > div > div > p,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > div > div > p,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > div > div > p {
   margin-bottom: 3px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co {
   display: none;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > div > div > p,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > div > div > p,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > div > div > p,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > div > div > p,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > div > div > p {
   margin-bottom: 3px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas {
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: center;
   height: 50px;
   padding: 15px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a {
   font-size: 11px;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites {
   position: absolute;
   bottom: 10px;
   right: 0;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas {
   -moz-column-gap: 10px;
   column-gap: 10px;
   justify-content: flex-end;
}
.gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas a,
.gov-co-footer-xs .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas a,
.gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas a,
.gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas a,
.gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas.poli-tramites .gov-co-politicas a {
   color: #4672C8;
}
.gov-co-footer > .gov-co-footer-pie,
.gov-co-footer-xs > .gov-co-footer-pie,
.gov-co-footer-sm > .gov-co-footer-pie,
.gov-co-footer-lg > .gov-co-footer-pie,
.gov-co-footer-xl > .gov-co-footer-pie {
   width: 100%;
   background-color: #0943B5;
}
.gov-co-footer > .gov-co-footer-pie > .gov-co-footer-auto,
.gov-co-footer-xs > .gov-co-footer-pie > .gov-co-footer-auto,
.gov-co-footer-sm > .gov-co-footer-pie > .gov-co-footer-auto,
.gov-co-footer-lg > .gov-co-footer-pie > .gov-co-footer-auto,
.gov-co-footer-xl > .gov-co-footer-pie > .gov-co-footer-auto {
   max-width: 1100px;
   width: 100%;
   height: 96px;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   margin: 0 auto;
}
.gov-co-footer > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-mesa,
.gov-co-footer-xs > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-mesa,
.gov-co-footer-sm > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-mesa,
.gov-co-footer-lg > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-mesa,
.gov-co-footer-xl > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-mesa {
   margin-right: 10px;
}
.gov-co-footer > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-blanco,
.gov-co-footer-xs > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-blanco,
.gov-co-footer-sm > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-blanco,
.gov-co-footer-lg > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-blanco,
.gov-co-footer-xl > .gov-co-footer-pie > .gov-co-footer-auto > .gov-co-logo-pie-blanco {
   border-left: 1px solid #ffffff;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta {
   padding: 40px 0px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   row-gap: 30px;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta {
   order: 3;
   flex: 1 1 auto;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa {
   order: 2;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-blanco,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-blanco,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-blanco,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-blanco,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-blanco {
   order: 1;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta {
   order: 1;
   flex: 1 1 auto;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta {
   margin: 10px 0;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta > p,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta > p,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta > p,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta > p,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-detalles-venta > p {
   margin-bottom: 3px;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta {
   display: flex;
   flex-flow: column wrap;
   row-gap: 20px;
   justify-content: flex-start;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a {
   flex: 1 1 auto;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a:hover,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a:hover,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a:hover,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a:hover,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta > a:hover {
   color: #ffffff;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta {
   order: 2;
   flex: 1 1 auto;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-detalles-venta-conta > p,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-detalles-venta-conta > p,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-detalles-venta-conta > p,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-detalles-venta-conta > p,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-detalles-venta-conta > p {
   margin-bottom: 3px;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta {
   display: flex;
   flex-flow: column wrap;
   justify-content: flex-start;
   margin-top: 15px;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a {
   flex: 1 1 auto;
}
.gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a:hover,
.gov-co-footer-xs > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a:hover,
.gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a:hover,
.gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a:hover,
.gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta > div > div > div > .gov-co-pilo-venta > a:hover {
   color: #ffffff;
}
@media (min-width: 576px) {
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > div,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > div {
      flex: initial;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo {
      width: 100%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad {
      width: 100%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div {
      display: flex;
      justify-content: flex-end;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co {
      margin: 0 10px;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion {
      width: 70%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes {
      width: 50%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-sedes > div > .h-gov-co {
      display: inline-block;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos {
      width: 50%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-contactos > div > .h-gov-co {
      display: inline-block;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas {
      width: 100%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
   .gov-co-footer-sm .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas {
      width: 70%;
      margin: 0 auto;
      padding: 10px;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta,
   .gov-co-footer-sm > .gov-co-footer-pie.footer-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta {
      flex-flow: column nowrap;
      row-gap: initial;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
   .gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta {
      flex-flow: row wrap;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
   .gov-co-footer-sm > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta {
      padding: 15px 0;
      margin: 15px 0;
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
   }
}
@media (min-width: 992px) {
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad {
      padding: 50px 50px 10px;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-titulo {
      width: 60%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad {
      width: 40%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-logo-autoridad > div > div > div > .logo-gov-co {
      position: absolute;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion {
      width: 60%;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-presentacion > div {
      width: 85%;
      margin: 0;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas {
      width: 50%;
      padding: 0 10px;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas {
      width: 100%;
      padding: 5px;
   }
   .gov-co-footer .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
   .gov-co-footer-lg .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a,
   .gov-co-footer-xl .gov-co-footer-presetacion > .gov-co-footer-autoridad > .footer-politicas > div > div > div > .gov-co-politicas > a {
      font-size: 0.75rem;
   }
   .gov-co-footer > .gov-co-footer-pie > .gov-co-footer-auto,
   .gov-co-footer-lg > .gov-co-footer-pie > .gov-co-footer-auto,
   .gov-co-footer-xl > .gov-co-footer-pie > .gov-co-footer-auto {
      justify-content: flex-start;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta {
      flex-flow: row nowrap;
      //padding:40px 85px
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta {
      order: 1;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-content: flex-end;
      row-gap: 20px;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-footer-venta > .gov-co-logo-pie-mesa {
      margin: 0 auto;
      align-content: flex-end;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta {
      margin: 0 15px;
      padding: 0 15px;
      border-left: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-presen-venta > div > div > div > .gov-co-social-media-venta {
      margin-top: 15px;
   }
   .gov-co-footer > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
   .gov-co-footer-lg > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta,
   .gov-co-footer-xl > .gov-co-footer-pie.footer-venta > .gov-co-conta-venta {
      padding: 0;
      margin: 0;
      border: initial;
   }
}
.navbar-gov-co-search {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 34px 85px;
}
.navbar-gov-co-search .content-search {
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-end;
   align-items: flex-end;
   width: 350px;
}
.content-logo {
   width: 305px;
   height: 71px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   background-color: #f6f8f9;
}
.content-logo span {
   margin-left: 30px;
}
div.form-group-search {
   margin-top: 10px;
   width: 100%;
   position: relative;
}
div.form-group-search > input {
   padding-right: 30px;
}
div.form-group-search > span {
   position: absolute;
   color: #0943B5;
   top: 0;
   right: 0;
   padding-top: 2px;
   line-height: 1.125em;
}
.nav-terri div.form-group-search > span {
   color: #a80521;
}
.navbar-gov-co-sedes {
   position: relative;
   background-color: #f6f8f9;
   border-bottom: 4px solid #158361;
   padding: 0 5.208vw 0 4.167vw;
   flex-wrap: nowrap;
}
.navbar-gov-co-sedes li {
   cursor: pointer;
   list-style: none;
}
.navbar-gov-co-sedes li:hover {
   background-color: #f2f2f2;
}
.navbar-gov-co-sedes a {
   padding: 1.5vh 0.729vw;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.875rem;
   color: #4b4b4b;
}
.navbar-gov-co-sedes a.nav-link {
   font-weight: 600;
}
.navbar-gov-co-sedes h5 {
   font-family: Verdana, Geneva, sans-serif;
   font-size: 15px;
   font-weight: 500;
   color: #4b4b4b;
}
.navbar-gov-co-sedes .navbar-navs ul {
   padding: 0;
   display: flex;
   flex-flow: row nowrap;
   margin: initial;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single {
   position: relative;
   flex: inherit;
   max-width: 250px;
   text-align: center;
   list-style: none;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single.show {
   background-color: #f2f2f2;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single.show > a {
   white-space: pre-wrap;
   font-size: 0.875rem;
   text-decoration: underline;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single.show::after {
   content: '';
   position: absolute;
   width: 100%;
   height: 20px;
   bottom: -15px;
   left: 0;
   background-color: #f2f2f2;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single > .single-menu {
   position: absolute;
   z-index: 1001;
   top: 102%;
   left: 0;
   background-color: #f2f2f2;
   border: initial;
   border-bottom: 4px solid #158361;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single > .single-menu a {
   padding: 10px 14px;
   display: block;
   white-space: nowrap;
   text-align: left;
}
.navbar-gov-co-sedes .navbar-navs ul .item-menu-single > .single-menu a:hover {
   background-color: #e8e8e8;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu.show {
   background-color: #f2f2f2;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu.show > a.nav-link {
   position: relative;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu.show > a.nav-link::after {
   content: '';
   position: absolute;
   background-color: #f2f2f2;
   width: 100%;
   height: 30px;
   top: 100%;
   left: 0;
   right: 0;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general {
   position: absolute;
   z-index: 1001;
   width: 95%;
   top: 102%;
   left: 0;
   right: 0;
   padding: 0;
   background-color: transparent;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general.show {
   border: 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general > .mega-menu {
   background-color: #f2f2f2;
   display: flex;
   display: wrap;
   justify-content: space-evenly;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general > .mega-menu > .mega-menu-items {
   background-color: #f2f2f2;
   border-bottom: 4px solid #158361;
   padding: 10px 14px;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general > .mega-menu > .mega-menu-items h5 {
   white-space: nowrap;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general > .mega-menu > .mega-menu-items a {
   padding: 10px 14px;
   white-space: nowrap;
   display: block;
}
.navbar-gov-co-sedes .navbar-navs ul .item-mega-menu > .mega-menu-general > .mega-menu > .mega-menu-items a:hover {
   background-color: #e8e8e8;
}
.navbar-gov-co-sedes .navbar-button {
   cursor: pointer;
}
.navbar-gov-co-sedes .navbar-button.show {
   background-color: #f2f2f2;
}
.navbar-gov-co-sedes .navbar-button a {
   text-decoration: none;
}
.navbar-gov-co-sedes .navbar-button span {
   font-size: 1.5rem;
}
.navbar-gov-co-sedes .navbar-button .dropdown-menu {
   left: initial;
   padding: 0;
   margin: 0;
   border: initial;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu {
   position: absolute;
   right: 0;
   min-width: 520px;
   background-color: #f2f2f2;
   box-shadow: 0px 3px 0px #158361;
   border-radius: 0px 0px 5px 5px;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu > ul {
   position: relative;
   margin: initial;
   padding: 15px;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu > ul > li {
   position: initial;
   padding: 15px 13px;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu > ul > li.show > a {
   position: relative;
   background-color: #e8e8e8;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu > ul > li.show > a::after {
   content: '\e830';
   font-family: 'Govco Icon';
   font-size: 30px;
   position: absolute;
   height: 100%;
   top: 0;
   right: -40px;
   transform: rotate(180deg);
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu > ul > li a:hover {
   background-color: #e8e8e8;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic {
   position: absolute;
   background-color: #e8e8e8;
   top: 0;
   right: 0;
   left: initial;
   min-width: 260px;
   height: 100%;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic > ul {
   padding: 0;
   margin: 0;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic > ul > li {
   padding: 10px 13px;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic > ul > li:hover {
   background-color: #d6d6d6;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic > ul > li:hover a {
   background-color: #d6d6d6;
}
.navbar-gov-co-sedes .navbar-button .dinamic-menu .navs-menu-dinamic > ul > li > a {
   padding: 10px 13px;
}
.navbar-gov-co-sedes.nav-terri {
   border-bottom: 4px solid #a80521;
}
.navbar-gov-co-sedes.nav-terri .single-menu {
   border-bottom: 4px solid #a80521 !important;
}
.navbar-gov-co-sedes.nav-terri .mega-menu > .mega-menu-items {
   border-bottom: 4px solid #a80521 !important;
}
.navbar-gov-co-sedes.nav-terri .dinamic-menu {
   box-shadow: 0px 3px 0px #a80521 !important;
}
.gov-co-advance .progress {
   height: 3px;
   margin-bottom: 5px;
}
.gov-co-advance .progress .progress-bar {
   background-color: #0943B5;
   width: 3%;
}
.gov-co-advance .navs-link-advance {
   list-style-type: none;
   margin: 0;
   padding: 5px 0;
   display: flex;
   flex-wrap: wrap;
}
.gov-co-advance .navs-link-advance .nav-link-advance {
   flex: 1;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 400;
   font-size: 0.938rem;
   color: #4b4b4b;
   display: flex;
   cursor: pointer;
}
.gov-co-advance .navs-link-advance .nav-link-advance:last-of-type {
   justify-content: flex-end;
}
.gov-co-advance .navs-link-advance .nav-link-advance span {
   font-family: 'Nunito Sans', sans-serif;
   margin-right: 5px;
   border-radius: 100%;
   border: 1px solid #4b4b4b;
   width: 25px;
   height: 25px;
   text-align: center;
   padding: 1px 0;
}
.gov-co-advance .navs-link-advance .nav-link-advance.active {
   color: #0943B5;
   font-weight: 700;
}
.gov-co-advance .navs-link-advance .nav-link-advance.active span {
   background-color: #0943B5;
   color: #ffffff;
   border: 1px solid #0943B5;
}
.gov-co-advance .navs-link-advance .nav-link-advance.advance {
   color: #bababa;
   font-weight: 700;
}
.gov-co-advance .navs-link-advance .nav-link-advance.advance span {
   background-color: #bababa;
   color: #ffffff;
   border: 1px solid #bababa;
}
.gov-co-servi {
   min-width: 165px;
   max-width: 289px;
}
.gov-co-servi .gov-co-servi-item {
   max-width: 287px;
   margin-bottom: 20px;
   background-color: #f6f8f9;
}
.gov-co-servi .gov-co-servi-item > .btn {
   text-align: left;
   width: 100%;
   padding: 19px 22px;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1rem;
   color: #4672C8;
   position: relative;
   text-transform: none;
   border: initial;
}
.gov-co-servi .gov-co-servi-item > .btn:focus {
   box-shadow: initial;
}
.gov-co-servi .gov-co-servi-item > .btn span {
   position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
   right: 0;
   width: 40px;
   height: 40px;
   border-radius: 100%;
   font-size: 1rem;
   font-weight: lighter;
   text-align: center;
   padding-top: 4px;
   transition: all 500ms ease-in-out;
   border: 3px solid transparent;
   transform: rotate(180deg);
}
.gov-co-servi .gov-co-servi-item > .btn.collapsed span {
   transform: rotate(0);
}
.gov-co-servi .gov-co-servi-item .servi-body {
   padding: 0 30px 25px;
   border: initial;
   background-color: #f6f8f9;
}
.gov-co-servi .gov-co-servi-item .servi-body .servi-body-item {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
}
.gov-co-servi .gov-co-servi-item .servi-body .servi-body-item span {
   float: left;
   width: 40px;
   color: #0943B5;
   font-size: 1.5rem;
}
.gov-co-servi .gov-co-servi-item .servi-body .servi-body-item p {
   flex: 1;
   width: 40px;
   white-space: normal;
   margin: 0;
   font-family: Verdana, Geneva, sans-serif;
   font-size: 0.938rem;
   font-weight: 400;
   color: #4b4b4b;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe {
   display: flex;
   flex-flow: row wrap;
   justify-content: space-evenly;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-body-item-expe {
   padding: 0 15px 20px;
   text-align: center;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-body-item-expe p {
   margin: 0;
   font-family: Verdana, Geneva, sans-serif;
   font-size: 1rem;
   font-weight: 600;
   color: #0943B5;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-body-item-expe span {
   color: #0943B5;
   font-size: 2.5rem;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-form-expe {
   width: 100%;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-body-action {
   width: 100%;
   display: flex;
   justify-content: center;
}
.gov-co-servi .gov-co-servi-item .servi-body.body-expe .servi-body-action .btn.btn-round {
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 500;
   font-size: 0.938rem;
   padding: 0.375rem 0.75rem;
}
.gov-co-servi-retro {
   width: 100%;
   background-color: #f6f8f9;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-items: flex-start;
   padding: 0 77px;
}
.gov-co-servi-retro .servi-retro-item {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   padding: 30px 0;
}
.gov-co-servi-retro .servi-retro-item .text-retro-item {
   margin: 0;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: 600;
   font-size: 1rem;
   color: #4672C8;
}
.gov-co-servi-retro .servi-retro-item .servi-body-item-expe {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   -moz-column-gap: 10px;
   column-gap: 10px;
}
.gov-co-servi-retro .servi-retro-item .servi-body-item-expe p {
   margin: 0;
   font-family: Verdana, Geneva, sans-serif;
   font-weight: 600;
   font-size: 1rem;
   color: #0943B5;
}
.gov-co-servi-retro .servi-retro-item .servi-body-item-expe span {
   color: #0943B5;
}
.gov-co-servi-retro .servi-retro-item.servi-retro-item-action {
   min-width: 300px;
   width: 400px;
   flex-flow: column wrap;
}
.gov-co-servi-retro .servi-retro-item.servi-retro-item-action .servi-form-expe {
   width: 100%;
}
.gov-co-servi-retro .servi-divider {
   display: none;
}
@media (min-width: 1440px) {
   .gov-co-servi-retro .servi-divider {
      display: block;
      align-self: stretch;
      border-left: 1px solid #4b4b4b;
   }
}
