/* You can add global styles to this file, and also import other style files */
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import "@fontsource/nunito-sans"; 
@import "@fontsource/nunito-sans/400.css"; // Specify weight
@import "@fontsource/nunito-sans/600.css"; // Specify weight

.swal2-content {
   padding: 0 !important;
}
.swal2-close {
   color: #0943B5 !important;
   font-size: 2.5rem !important;
   font-weight: bold !important;
   &:hover {
      color: #0943B5 !important;
   }
}
.title-error {
   font-weight: 600 !important;
   line-height: 40px !important;
   font-size: 32px !important;
}
.text-error {
   font-size: 16px !important;
}

.content-entity-modal {
   cursor: pointer;
   width: 380px;
   height: 80px;
   background: #ffffff 0% 0% no-repeat padding-box;
   border: 1px solid #e6effd;
   border-radius: 5px;
   opacity: 1;
   display: flex;
   flex-flow: column nowrap;
   overflow: hidden;
   & > span {
      text-decoration: underline;
      color: #0943B5;
      text-align: start;
   }
   & > .content-entity-img {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 15px;
      padding: 0 25px;
   }
   &:disabled {
      color: var(--content-text-white-color);
      pointer-events: none;
      background-color: var(--background-gray-color) !important;
      border-color: var(--selected-border-gray-color) !important;
      & > span {
         text-decoration: none;
         color: var(--content-text-white-color);
         &.error-entity {
            width: 100%;
            font-size: 0.8rem;
         }
      }
   }
}

textarea::placeholder {
   color: rgba(75, 75, 75, 0.5) !important;
}

